<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2 flex justify-between">
    <div>通知管理</div>
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>通知列表</div>
        <a-button type="link" @click="$router.push('/notifications/update')">
          + 新增通知
        </a-button>
    </div>
    <div class="h-line"></div>
     <div class="flex flex-row mb-2">
      <div class="flex flex-row justify-start mt-3 mb-3">
        <a-select placeholder="請選擇版位" class="mr-3 w-60" v-model="currentNotificationType">
          <a-select-option value="in_app" >
            App 內通知
          </a-select-option>
          <a-select-option value="push">
            手機推播
          </a-select-option>
        </a-select>
        <a-input placeholder="標題" class="mr-3" v-model="currentTitle" />
      </div>
      <div class="flex flex-row justify-start mb-3 mt-3">
        <a-button type="primary" class="mr-3 w-28" @click="getList()">
          搜尋
        </a-button>
        <a-button type="link" class="mr-3 w-28" @click="clearSearchCondition()">清空條件</a-button>
      </div>
    </div>
    <div class="app-tab-wrap">
      <div class="app-tab-item" @click="changeTab('1')" :class="activeKey == '1'?'app-tab-item-active':'app-tab-item-default'">已排程</div>
      <div class="app-tab-item" @click="changeTab('2')" :class="activeKey == '2'?'app-tab-item-active':'app-tab-item-default'">草稿</div>
      <div class="app-tab-item" @click="changeTab('3')" :class="activeKey == '3'?'app-tab-item-active':'app-tab-item-default'">已發佈</div>
    </div>
    <NotificationTable :records.sync="scheduled"  status='scheduled' v-show="activeKey=='1'"/>
    <NotificationTable :records.sync="draft"  status='draft' v-show="activeKey=='2'"/>
    <NotificationTable :records.sync="sent"  status='sent' v-show="activeKey=='3'"/>
    <div class="pagination-wrap">
      <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getNotifications } from "../../service/api.js";
import App from "../../App";
import NotificationTable from "../../components/notificationTable";

@Component({
  name: "Notifications",
  components: { NotificationTable }
})
export default class Notifications extends App {
  activeKey = "1";
  recordList = [];
  scheduled = [];
  draft = [];
  sent = [];
  currentTitle = "";
  currentNotificationType = null;
  currentCategory = "scheduled";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;

  async mounted() {
    this.currentNotificationType = undefined;
    this.getList();
  }

  changeTab(key = "1") {
    this.activeKey = key;
    if (key == "1") {
      this.currentCategory = "scheduled";
    } else if (key == "2") {
      this.currentCategory = "draft";
    } else {
      this.currentCategory = "sent";
    }
    this.getList(this.currentPage);
  }

  clearSearchCondition() {
    this.currentTitle = "";
    this.currentNotificationType = undefined;
    this.getList();
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.recordList = [];
      this.scheduled = [];
      this.draft = [];
      this.sent = [];
      const res = await getNotifications(
        this.currentNotificationType,
        this.currentTitle,
        this.currentCategory,
        currentPage
      );
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      if (this.currentCategory == "draft") {
        this.draft = res.data;
      } else if (this.currentCategory == "sent") {
        this.sent = res.data;
      } else if (this.currentCategory == "scheduled") {
        this.scheduled = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  gotoPage(record) {
    this.$router.push({
      name: "UpdateNotification",
      params: record
    });
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.min-70 {
  min-width: 70px;
}
</style>
