<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div class="flex flex-col">
      <div class="m-2">
        <div class="app-default-page-title mt-2 flex justify-between">
          簽到狀態
        </div>
        <div class="flex flex-row justify-start flex-nowrap overflow-x-auto">
          <div v-for="(day, index) in countsOfActivity" :key="index">
            <div
              class="
                flex flex-col
                p-15
                ml-2
                mr-2
                mb-2
                w-62
                rounded-md
                bg-white
                mw-245
              "
            >
              <div class="flex flex-row m-3 items-center">
                <span class="text-lg justify-start"
                  >第{{ index + 1 }}日 {{ day.date }}</span
                >
                <span
                  class="ml-5 text-blue-500 cursor-pointer"
                  @click="exportList(day)"
                  >匯出名單</span
                >
              </div>
              <div class="flex justify-end p-5 items-end">
                <div class="text-4xl mr-3 font-bold">
                  {{ day.checkins_count
                  }}<small class="text-sm">/{{ day.attendees_count }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-default-wrap mt-0">
      <div class="user-title-wrap">
        <div>{{ activity.name }}</div>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-col mb-2">
        <div class="flex flex-row justify-start mt-3 mb-3">
          <a-select
            placeholder="請選擇簽到狀態"
            class="mr-3 app-w-20"
            v-model="signUpStatus"
          >
            <a-select-option value="checkin"> 已簽到 </a-select-option>
            <a-select-option value="pending"> 尚未簽到 </a-select-option>
          </a-select>
          <a-input placeholder="會員 ID" class="mr-3" v-model="userID" />
          <a-input placeholder="姓名" class="mr-3" v-model="nickname" />
          <a-input placeholder="手機" class="mr-3" v-model="mobile" />
          <a-input placeholder="組別" class="mr-3" v-model="group" />
        </div>
        <div class="flex flex-row justify-start mb-3 mt-3">
          <a-button type="primary" class="mr-3 w-28" @click="getList()">
            搜尋
          </a-button>
          <a-button
            type="link"
            class="mr-3 w-28"
            @click="clearSearchCondition()"
            >清空條件</a-button
          >
        </div>
      </div>
      <div
        class="app-tab-wrap flex-nowrap overflow-x-auto"
        type="ooo"
        id="myInput"
        v-if="countsOfActivity.length > 0"
      >
        <div
          class="flex justify-center items-center app-tab-item mw-175"
          v-for="(day, index) in countsOfActivity"
          :key="index"
          @click="changeTab(day.date)"
          :class="
            currentDate == day.date
              ? 'app-tab-item-active'
              : 'app-tab-item-default'
          "
        >
          第{{ index + 1 }}日 {{ day.date }}
        </div>
      </div>
      <ActivitySignUpTable
        :records.sync="records"
        :currentDate.sync="currentDate"
        :activityType.sync="activityType"
        v-on:updateCount="updateCount"
      />
      <div class="pagination-wrap">
        <a-pagination
          v-model="currentPage"
          :defaultCurrent="1"
          :pageSize="perPage"
          :total="totalPage"
          @change="changePage"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../../store/index.js";
import {
  getUsers,
  getCountryCode,
  usersConvert,
  getCountsOfActivityAttendees,
  createOne,
  downloadActivityCheckins,
  getActivitiesAttendees,
} from "../../../service/api.js";
import ActivitySignUpTable from "../../../components/activitySignUpTable";
import App from "../../../App";

@Component({
  name: "ActivitySignUp",
  components: {
    ActivitySignUpTable,
  },
})
export default class ActivitySignUp extends App {
  activity = {};
  countsOfActivity = [];
  records = [];
  condition = null;
  signUpStatus = "";
  userID = "";
  nickname = "";
  group = "";
  orderNum = "";
  mobile = "";
  currentDate = "";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  activityType = "";
  async mounted() {
    await CommonModule.setLoading(true);
    this.signUpStatus = undefined;
    try {
      const queryObj = this.$route.params;
      const vuxActivityParams = await CommonModule.getActivityParams();
      if (Object.keys(queryObj).length != 0 || vuxActivityParams != undefined) {
        const params =
          Object.keys(queryObj).length != 0
            ? queryObj.activityType != null
              ? queryObj
              : vuxActivityParams
            : vuxActivityParams;
        this.activityType = params.activityType;
        this.activity = params;
        const countsRes = await getCountsOfActivityAttendees(params.id);
        this.countsOfActivity = countsRes.days;
        this.currentDate = this.countsOfActivity[0].date;
        await this.getList();
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async updateCount() {
    await CommonModule.setLoading(true);
    try {
      const countsRes = await getCountsOfActivityAttendees(this.activity.id);
      this.countsOfActivity = countsRes.days;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.records = [];
      const res = await getActivitiesAttendees(
        this.activity.id,
        this.currentDate,
        this.signUpStatus,
        this.userID,
        this.nickname,
        this.mobile,
        this.group,
        currentPage
      );
      this.records = res.data;
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  chooseFile() {
    document.getElementById("getFile").click();
  }

  async exportList(date) {
    await CommonModule.setLoading(true);
    try {
      let res = await downloadActivityCheckins(this.activity.id, date.date);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", date.date + ".xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changeTab(date) {
    this.currentDate = date;
    this.getList(this.currentPage);
  }

  clearSearchCondition() {
    this.mobile = "";
    this.nickname = "";
    this.userID = "";
    this.group = "";
    this.orderNum = "";
    this.signUpStatus = undefined;
    this.getList();
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mw-245 {
  min-width: 245px;
}
.mw-175 {
  min-width: 175px;
}
</style>
