<template>
  <div class="app-table ">
    <div class="app-tr rule-tr app-bb app-table-header">
      <div class="app-th app-w-10">版位</div>
      <div class="app-th app-w-20">標題</div>
      <div class="app-th app-w-10">手機語系</div>
      <div class="app-th app-w-20">指定會員</div>
      <div class="app-th app-w-10">編輯者</div>
      <div class="app-th app-w-20" v-if="status!='draft'">發送時間</div>
      <div class="app-th app-w-20" v-if="status=='draft'">更新時間</div>
      <div class="app-th justify-center" style="width:50px">查看</div>
    </div>
    <div v-if="recordList.length != 0">
      <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
        <div class="app-td app-w-10">{{record.notification_type =='in_app'?"App 內通知":"推播"}} </div>
        <div class="app-td app-w-20">{{record.title}}</div>
        <div class="app-td app-w-10">{{getLang(record.target_language)}}</div>
        <div class="app-td app-w-20">{{getClient(record)}}</div>
        <div class="app-td app-w-10">{{record.last_updated_by.username}}</div>
        <div class="app-td app-w-20" v-if="status=='sent'">{{record.sent_at | moment("YYYY-MM-DD HH:mm:ss")}}</div>
        <div class="app-td app-w-20" v-if="status=='scheduled'">{{record.scheduled_at | moment("YYYY-MM-DD HH:mm:ss")}}</div>
        <div class="app-td app-w-20" v-if="status=='draft'">{{record.updated_at | moment("YYYY-MM-DD HH:mm:ss")}}</div>
        <div class="app-td justify-center" style="width:50px">
          <a-button type="link" @click="gotoPage(record)">
          查看
          </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";

@Component
export default class NotificationTable extends Vue {
  @Prop() records;
  @Prop() status;
  recordList = [];

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  getLang(val) {
    let lang = "-";
    if (val == "zh_tw") {
      lang = "繁體中文";
    } else if (val == "zh_cn") {
      lang = "簡體中文";
    } else if (val == "en") {
      lang = "英文";
    } else if (val == "jp") {
      lang = "日文";
    }
    return lang;
  }

  getClient(record) {
    if (record.target_type == "one_user_id") {
      return "單一使用者";
    } else if (record.target_type == "all_users") {
      return "所有會員";
    } else if (record.target_type == "reseller_levels") {
      let level = "會員等級:";
      for (let i = 0; i < record.target_value.length; i++) {
        const val = record.target_value[i];
        if (val == 0) {
          level += "一般會員,";
        } else if (val == 1) {
          level += "初級,";
        } else if (val == 2) {
          level += "中級,";
        } else if (val == 3) {
          level += "高級,";
        } else if (val == 4) {
          level += "經銷商,";
        }
      }
      return level;
    } else if (record.target_type == "year_bonus_levels") {
      let level = "年分紅級別:";
      for (let i = 0; i < record.target_value.length; i++) {
        const val = record.target_value[i];
        if (val == 0) {
          level += " Lv.1,";
        } else if (val == 1) {
          level += "Lv.2,";
        } else if (val == 2) {
          level += "Lv.3,";
        } else if (val == 3) {
          level += "VP,";
        } else if (val == 4) {
          level += "P,";
        }
      }
      return level;
    }
  }

  gotoPage(record) {
    this.$router.push({
      name: "UpdateNotification",
      params: record
    });
  }
}
</script>

<style>
</style>
