<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title ">
    {{!isCreated?"新增管理員":"管理員資料"}}
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap" style="height:21px">
      <div>
        管理員資料
      </div>
      <a-button type="link" @click="editing=true" v-if="(isCreated&&!editing)">
        編輯
      </a-button>
    </div>
    <div class="h-line"></div>
      <a-form  class="pt-2 pl-2 pr-2" :label-col="{ span: 2 }" :wrapper-col="{ span: 25 }">
        <a-form-item label="權限">
          <a-checkbox class="justify-start  mt-2 ml-2" v-model="checkAll" :disabled="!editing" @change="selectAllCheckBox()">
            全選
          </a-checkbox>
        </a-form-item>
        <a-form-item label="  ">
            <a-checkbox-group :disabled="!editing" class="ml-2 mt-2 flex flex-row justify-start" v-model="permissions">
              <a-checkbox value="/ADMIN/DASHBOARD/AUTH"  :checked="selectAll">
                帳號管理
              </a-checkbox>
              <a-checkbox value="/ADMIN/DASHBOARD/FEEDBACK"  :checked="selectAll">
                意見回饋
              </a-checkbox>
              <a-checkbox value="/ADMIN/DASHBOARD/ACTIVITY"  :checked="selectAll">
                活動管理
              </a-checkbox>
              <a-checkbox value="/ADMIN/DASHBOARD/ARTICLE"  :checked="selectAll">
                文章管理
              </a-checkbox>
              <a-checkbox value="/ADMIN/DASHBOARD/NOTIFICATION"  :checked="selectAll">
                通知管理
              </a-checkbox>
              <a-checkbox value="/ADMIN/DASHBOARD/SETTING"  :checked="selectAll">
                系統設定
              </a-checkbox>
            </a-checkbox-group>
        </a-form-item>
      </a-form>
    <div class="h-line"></div>
    <div class="flex flex-row justify-between mt-10">
      <div class="w-1/2 mr-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="帳號">
            <a-input v-model="username" placeholder="請輸入帳號" :disabled="!editing" />
          </a-form-item>
          <a-form-item label="暱稱">
            <a-input v-model="nickname" placeholder="請輸入暱稱" :disabled="!editing" />
          </a-form-item>
          <a-form-item label="手機">
            <a-input type="text" v-model="phone" placeholder="請輸入手機" :disabled="!editing" />
          </a-form-item>
        </a-form>
      </div>
      <div class="w-1/2 mr-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="Email">
            <a-input v-model="email" placeholder="請輸入Email" :disabled="!editing" />
          </a-form-item>
          <a-form-item label="密碼" v-if="isCreated && editing">
            <a-button type="primary" @click="displayPasswordDialog = true">
              修改密碼
            </a-button>
          </a-form-item>
          <a-form-item label="密碼" v-if="isCreated && !editing">
            <span>{{username}} / {{updatedAt | moment("YYYY-MM-DD HH:mm:ss")}} 最後更新</span>
          </a-form-item>
          <a-form-item label="密碼" v-if="!isCreated">
            <a-input  type="password" v-model="password" placeholder="請輸入密碼" :disabled="!editing" />
          </a-form-item>
          <a-form-item label="  " v-if="!isCreated">
            <a-input type="password" placeholder="請再次確認密碼" v-model="confirmPassword"  :disabled="!editing" />
            <div v-if="errorMsg.passwordNotMatch" class="text-red-500">密碼不一致</div>
          </a-form-item>
        </a-form>
      </div>
    </div>

  </div>
  <div class="flex flex-row justify-between">
    <div class="text-center" :style="!isCreated||(isCreated&&editing)?'visibility:visible':'visibility:hidden'" >
      <a-button class="w-40 ml-4 h-10" @click="isCreated?editing=false:$router.go(-1);">取消</a-button>
      <a-button class="w-40 ml-4 h-10" @click="submit()" type="primary" :disabled="username==''||phone==''||nickname==''||email==''">
        儲存
      </a-button>
    </div>
    <a-button class="mr-3 w-40 h-10 " @click="deleteUserDialog=true"  :style="isCreated&&!editing?'visibility:visible':'visibility:hidden'" >
      刪除帳號
    </a-button>
  </div>
  <a-modal v-model="displayPasswordDialog" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
    <h3>修改密碼</h3>
    <div class="horizontal-line"></div>
    <small>請設置至少6位英文+數字組合的密碼</small>
    <a-input class="mt-2" type="password" v-model="password" placeholder="請輸入密碼" />
    <a-input class="mt-2" type="password" v-model="confirmPassword" placeholder="請再次輸入密碼" />
    <div class="text-center mt-6">
      <a-button class="w-40 ml-4" @click="displayPasswordDialog=false;password='';confirmPassword=''">取消</a-button>
      <a-button class="w-40 ml-4" @click="updatePassword();" type="primary" :disabled="this.password.length<6 || this.confirmPassword.length<6">
        儲存
      </a-button>
    </div>
  </a-modal>
  <a-modal v-model="deleteUserDialog" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
    <h4>確定要刪除此帳號？</h4>
    <div class="mt-5">
      停用後用戶將無法使用此帳號登入
    </div>
    <div class="text-center mt-6">
      <a-button class="w-40 ml-4" @click="deleteUserDialog=false">取消</a-button>
      <a-button class="w-40 ml-4" @click="deleteUser();" type="primary">
        刪除
      </a-button>
    </div>
  </a-modal>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getOne, updateOne, createOne, deleteOne } from "../../service/api.js";
import App from "../../App";
import moment from "moment";

@Component({
  name: "UpdateAdministrator",
  components: {}
})
export default class UpdateAdministrator extends App {
  toggleDisable = true;
  displayModel = false;
  displayPasswordDialog = false;
  deleteUserDialog = false;
  passwordChange = false;
  checkAll = false;
  adminID = "";
  updatedAt = "";
  username = "";
  originalUsername = "";
  email = "";
  phone = "";
  nickname = "";
  confirmPassword = "";
  password = "";
  isCreated = false;
  editing = false;
  selectAll = false;
  errorMsg = {
    passwordNotMatch: false
  };
  permissions = [];
  async mounted() {
    await CommonModule.setLoading(true);
    try {
      const queryObj = this.$route.params;
      if (Object.keys(queryObj).length != 0) {
        this.isCreated = true;
        this.editing = false;
        const res = await getOne("admins", queryObj.id);
        this.adminID = res.id;
        this.phone = res.profile.mobile;
        this.nickname = res.profile.nickname;
        this.email = res.profile.email;
        this.username = res.username;
        this.originalUsername = res.username;
        this.role = res.role;
        this.updatedAt = res.updated_at;
        for (let i = 0; i < res.user_tags.length; i++) {
          const tag = res.user_tags[i];
          if (tag.ns.indexOf("AUTH") != -1) {
            this.permissions.push("/ADMIN/DASHBOARD/AUTH");
          } else if (tag.ns.indexOf("FEEDBACK") != -1) {
            this.permissions.push("/ADMIN/DASHBOARD/FEEDBACK");
          } else if (tag.ns.indexOf("ACTIVITY") != -1) {
            this.permissions.push("/ADMIN/DASHBOARD/ACTIVITY");
          } else if (tag.ns.indexOf("ARTICLE") != -1) {
            this.permissions.push("/ADMIN/DASHBOARD/ARTICLE");
          } else if (tag.ns.indexOf("NOTIFICATION") != -1) {
            this.permissions.push("/ADMIN/DASHBOARD/NOTIFICATION");
          } else if (tag.ns.indexOf("SETTING") != -1) {
            this.permissions.push("/ADMIN/DASHBOARD/SETTING");
          }
        }
        if (res.user_tags.length == 6) {
          this.checkAll = true;
        }
      } else {
        this.editing = true;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async deleteUser() {
    await CommonModule.setLoading(true);
    try {
      await deleteOne("admins", this.adminID);
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  async submit() {
    const reg = /^[0-9]{10}$/g;
    if (this.email.indexOf("@") == -1) {
      this.$message.info("Email格式錯誤請重新輸入");
      return;
    }
    if (!reg.test(this.phone)) {
      this.$message.info("電話格式錯誤請重新輸入");
      return;
    }
    this.editing = false;
    await CommonModule.setLoading(true);
    const params = {
      username: this.username,
      password: this.password,
      nickname: this.nickname,
      mobile: this.phone,
      email: this.email,
      dashboard_tags: this.permissions
    };
    let sideBar = {};
    for (let i = 0; i < this.permissions.length; i++) {
      const tag = this.permissions[i];
      sideBar[tag] = true;
    }
    await AuthenticationModule.setSideBar(sideBar);
    if (this.isCreated && !this.passwordChange) {
      delete params.password;
    }
    try {
      if (this.isCreated) {
        if (this.originalUsername == this.username) {
          delete params.username;
        }
        await updateOne("admins", this.adminID, params);
      } else {
        await createOne("admins", params);
      }
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  selectAllCheckBox() {
    this.permissions = [];
    if (this.checkAll) {
      this.permissions.push("/ADMIN/DASHBOARD/AUTH");
      this.permissions.push("/ADMIN/DASHBOARD/FEEDBACK");
      this.permissions.push("/ADMIN/DASHBOARD/ACTIVITY");
      this.permissions.push("/ADMIN/DASHBOARD/ARTICLE");
      this.permissions.push("/ADMIN/DASHBOARD/NOTIFICATION");
      this.permissions.push("/ADMIN/DASHBOARD/SETTING");
    }
    this.selectAll = !this.selectAll;
  }

  updatePassword() {
    const engReg = /[a-z]/i;
    if (this.password.length < 6 || this.confirmPassword.length < 6) {
      this.$message.info("密碼小於六位數字");
      return;
    }
    if (this.password != this.confirmPassword) {
      this.$message.info("密碼不一致");
      return;
    }
    if (!engReg.test(this.password)) {
      this.$message.info("密碼未包含英文字");
      return;
    }
    try {
      this.displayPasswordDialog = false;
      this.passwordChange = true;
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss">
</style>
