<template>
  <div>
    <div class="flex flex-row justify-between mt-10">
      <div class="w-1/2 mr-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="目標體重">
            <a-input
              type="number"
              v-model="params.target_weight"
              placeholder="請輸入目標體重"
              :disabled="!editable"
              @change="paramsChanges()"
            >
              <div slot="addonAfter" style="width: 70px">公斤</div>
            </a-input>
          </a-form-item>
          <a-form-item label="家族病史">
            <div class="flex flex-row items-center">
              <a-radio-group
                v-decorator="['radio-group']"
                v-model="familyMedicalHistory"
                :disabled="!editable"
                @change="paramsChanges()"
              >
                <a-radio :value="false"> 無 </a-radio>
                <a-radio :value="true"> 有 </a-radio>
              </a-radio-group>
              <div>&nbsp;</div>
              <div v-if="familyMedicalHistory">
                <a-input
                  type="text"
                  v-model="nsMap['FAMILY_MEDICAL_HISTORY']"
                  placeholder="請輸入家族病史"
                  :disabled="!editable"
                  @change="paramsChanges()"
                />
              </div>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div class="w-1/2 ml-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="疾病史">
            <div class="flex flex-row items-center">
              <a-radio-group
                v-decorator="['radio-group']"
                v-model="personalMedicalHistory"
                :disabled="!editable"
                @change="paramsChanges()"
              >
                <a-radio :value="false"> 無 </a-radio>
                <a-radio :value="true"> 有 </a-radio>
              </a-radio-group>
              <div>&nbsp;</div>
              <div v-if="personalMedicalHistory">
                <a-input
                  type="text"
                  v-model="nsMap['PERSONAL_MEDICAL_HISTORY']"
                  placeholder="請輸入疾病史"
                  :disabled="!editable"
                  @change="paramsChanges()"
                />
              </div>
            </div>
          </a-form-item>
          <a-form-item label="用藥狀況">
            <div class="flex flex-row items-center">
              <a-radio-group
                v-decorator="['radio-group']"
                v-model="prescriptionMedicine"
                :disabled="!editable"
                @change="paramsChanges()"
              >
                <a-radio :value="false"> 無 </a-radio>
                <a-radio :value="true"> 有 </a-radio>
              </a-radio-group>
              <div>&nbsp;</div>
              <div v-if="prescriptionMedicine">
                <a-input
                  type="text"
                  v-model="nsMap['PRESCRIPTION_MEDICINE']"
                  placeholder="請輸入用藥狀況"
                  :disabled="!editable"
                  @change="paramsChanges()"
                />
              </div>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row justify-start m-6">
      <div class="app-w-10">相對禁忌症:</div>
      <a-checkbox-group
        :disabled="!editable"
        class="ml-2 flex flex-row justify-start flex-wrap"
        v-decorator="['checkbox-group']"
        ref="relative"
        v-model="relativeChecked"
      >
        <a-checkbox
          :ref="'rel-' + item.id"
          :value="item.id"
          v-for="(item, index) in relativeContraindications"
          v-bind:key="index"
        >
          {{ item.name }}
        </a-checkbox>
      </a-checkbox-group>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row justify-start m-6">
      <div class="app-w-10">絕對禁忌症:</div>
      <a-checkbox-group
        :disabled="!editable"
        class="ml-2 flex flex-row justify-start flex-wrap"
        v-decorator="['checkbox-group']"
        ref="absolute"
        v-model="absoluteChecked"
      >
        <a-checkbox
          :ref="'abs-' + item.id"
          :value="item.id"
          v-for="(item, index) in absoluteContraindications"
          v-bind:key="index"
        >
          {{ item.name }}
        </a-checkbox>
      </a-checkbox-group>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row justify-between mt-10">
      <div class="w-full">
        <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
          <a-form-item label="減脂計畫">
            <a-radio-group
              v-decorator="['radio-group']"
              v-model="status"
              :disabled="!editable"
              @change="paramsChanges()"
            >
              <a-radio value="pending" :disabled="!isApplyFatLossPlan()">
                未進行減脂計劃
              </a-radio>
              <a-radio value="progress" :disabled="isApplyFatLossPlan()">
                執行期
              </a-radio>
              <a-radio value="pause" :disabled="isApplyFatLossPlan()">
                暫停期
              </a-radio>
              <a-radio value="graduated" :disabled="isApplyFatLossPlan()">
                已畢業
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="減脂建議">
            <a-radio-group
              v-decorator="['radio-group']"
              v-model="plan"
              :disabled="!editable"
              @change="paramsChanges()"
            >
              <a-radio value="pending" :disabled="!isApplyFatLossPlan()">
                未進行減脂計劃
              </a-radio>
              <a-radio
                value="desugar"
                :disabled="isApplyFatLossPlan() || checkProgress('desugar')"
              >
                脱糖期
              </a-radio>
              <a-radio
                value="healthy"
                :disabled="isApplyFatLossPlan() || checkProgress('healthy')"
              >
                健康減程
              </a-radio>
              <a-radio
                value="sub-healthy"
                :disabled="isApplyFatLossPlan() || checkProgress('sub')"
              >
                亞健康減程
              </a-radio>
              <a-radio
                value="relevant"
                :disabled="isApplyFatLossPlan() || checkProgress('relevant')"
              >
                相對減程
              </a-radio>
              <a-radio
                value="transition-high"
                :disabled="isApplyFatLossPlan() || checkProgress('high')"
              >
                大過渡期
              </a-radio>
              <a-radio
                value="transition-low"
                :disabled="isApplyFatLossPlan() || checkProgress('low')"
              >
                小過渡期
              </a-radio>
              <a-radio
                value="pause"
                :disabled="isApplyFatLossPlan() || checkProgress('pause')"
              >
                暫停期
              </a-radio>
              <a-radio
                value="graduated"
                :disabled="isApplyFatLossPlan() || checkProgress('graduated')"
              >
                已畢業
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import { getSurvey } from "../../service/api.js";
import App from "../../App";
import moment from "moment";
import { HealthParams } from "../../models/healthParams";

@Component({
  name: "Questionnaire",
  components: {},
})
export default class Questionnaire extends App {
  @Prop() editable;
  @Prop() healthParams;

  @Watch("editable")
  onReceiveMsg(editable) {
    this.editable = editable;
  }

  @Watch("healthParams")
  watchHealthParams(healthParams) {
    this.params = healthParams;
    this.plan = !this.params.plan ? "pending" : this.params.plan;
    this.status = !this.params.status ? "pending" : this.params.status;
    this.prescriptionMedicine = this.params.prescription_medicine != "";
    this.personalMedicalHistory = this.params.personal_medical_history != "";
    this.familyMedicalHistory = this.params.family_medical_history != "";
    this.nsMap = this.params.ns_map;
    for (
      let i = 0;
      i < this.params.user_selected_contraindications.length;
      i++
    ) {
      const id = this.params.user_selected_contraindications[i];
      if (this.absMap[id] != undefined) {
        this.absoluteChecked.push(id);
      }
      if (this.relMap[id] != undefined) {
        this.relativeChecked.push(id);
      }
    }
  }

  @Watch("absoluteChecked")
  watchAbsoluteChecked(absoluteChecked) {
    this.paramsChanges();
  }
  @Watch("relativeChecked")
  watchRelativeChecked(relativeChecked) {
    this.paramsChanges();
  }

  params = new HealthParams();
  toggleDisable = true;
  displayModel = false;
  survey = [];
  relMap = {};
  absMap = {};
  relativeChecked = [];
  absoluteChecked = [];
  absoluteContraindications = [];
  relativeContraindications = [];
  prescriptionMedicine = false;
  personalMedicalHistory = false;
  familyMedicalHistory = false;
  nsMap = {};
  plan = "";
  status = "";

  async mounted() {
    await CommonModule.setLoading(true);
    try {
      const res = await getSurvey();
      this.absoluteContraindications = res.absolute_contraindications;
      this.relativeContraindications = res.relative_contraindications;
      for (let i = 0; i < this.absoluteContraindications.length; i++) {
        const element = this.absoluteContraindications[i];
        this.absMap[element.id] = element.id;
      }
      for (let i = 0; i < this.relativeContraindications.length; i++) {
        const element = this.relativeContraindications[i];
        this.relMap[element.id] = element.id;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  isApplyFatLossPlan() {
    if (this.params.plan == "") return;
    if (this.params.plan == null || this.params.status == "pending")
      return true;
    return false;
  }

  checkProgress(type) {
    if (this.params.plan == "") return;
    if (this.params.plan == null || this.params.status == "pending") {
      return true;
    }

    // 畢業只能選畢業
    if (this.params.status == "graduated" && type != "graduated") {
      return true;
    }
    // pause只能選pause
    if (this.params.status == "pause" && type != "pause") {
      return true;
    }
    // 進行中不可選暫停跟畢業
    if (
      this.params.status == "progress" &&
      (type == "pause" || type == "graduated")
    ) {
      return true;
    }

    return false;
  }

  paramsChanges() {
    this.params.plan = this.plan;
    this.params.status = this.status;
    this.params.personal_medical_history = this.personalMedicalHistory;
    this.params.prescription_medicine = this.prescriptionMedicine;
    this.params.family_medical_history = this.familyMedicalHistory;
    this.params.absolute_contraindications = this.absoluteChecked;
    this.params.relative_contraindications = this.relativeChecked;
    this.params.ns_map = this.nsMap;
    this.$emit("changeHealthParams", this.params);
  }

  chooseFiles() {
    document.getElementById("fileUpload").click();
  }
}
</script>
<style lang="scss">
.ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 30px;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}
</style>

