import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';
import { appRouter } from './router/index'
import './assets/css/tailwind.min.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import VueQuillEditor from 'vue-quill-editor';
import VueQRCodeComponent from 'vue-qrcode-component'

Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueQuillEditor)
Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(Antd);

new Vue({
  render: h => h(App),
  router: appRouter
}).$mount('#app');
