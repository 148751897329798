<template>
  <div class="app-table">
    <div class="app-tr rule-tr app-bb app-table-header">
      <div class="app-th" v-if="articleType != 'draft'">文章 ID</div>
      <div class="app-th">分類</div>
      <div class="app-th">標題</div>
      <div class="app-th justify-center">編輯者</div>
      <div
        class="app-th justify-center"
        v-if="articleType != 'draft' && articleType != 'unpublished'"
      >
        發布時間
      </div>
      <div class="app-th justify-center" v-if="articleType == 'draft'">
        更新時間
      </div>
      <div class="app-th justify-center" v-if="articleType == 'unpublished'">
        下架時間
      </div>
      <!-- <div class="app-th justify-center" v-if="articleType!='draft'&&articleType!='scheduled'">已讀紀錄</div> -->
      <div class="app-th justify-center">查看</div>
    </div>
    <div v-if="recordList.length != 0">
      <div
        class="app-tr rule-tr app-bb"
        v-for="(record, index) in recordList"
        :key="index"
      >
        <div class="app-td" v-if="articleType != 'draft'">
          {{ record.id }}
        </div>
        <div class="app-td">
          {{ record.type_name }}
        </div>
        <div class="app-td truncate block" style="line-height: 40px">
          {{ record.title }}
        </div>
        <div class="app-td justify-center">
          {{ record.author == undefined ? "" : record.author.nickname }}
        </div>
        <div
          class="app-td justify-center"
          v-if="articleType != 'draft' && articleType != 'unpublished'"
        >
          {{ !record.online_date ? "-" : record.online_date }}
        </div>
        <div class="app-td justify-center" v-if="articleType == 'draft'">
          {{
            !record.updated_at
              ? "-"
              : record.updated_at | moment("YYYY-MM-DD HH:mm:ss")
          }}
        </div>
        <div class="app-td justify-center" v-if="articleType == 'unpublished'">
          {{
            !record.updated_at
              ? "-"
              : record.updated_at | moment("YYYY-MM-DD HH:mm:ss")
          }}
        </div>
        <!-- <div class="app-th justify-center" v-if="articleType!='draft'&&articleType!='scheduled'">
          <a-button type="link"  @click="gotoHistoryPage(record)">
          {{record.read}}
          </a-button>
        </div> -->
        <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(record)"> 查看 </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length == 0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";
import { searchArticles } from "../service/api.js";
import moment from "moment";

@Component
export default class ArticleTable extends Vue {
  @Prop() records;
  @Prop() articleType;
  recordList = [];

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  gotoHistoryPage(record) {
    this.$router.push({
      name: "ArticleHistory",
      params: record,
    });
  }

  gotoPage(record) {
    record["articleType"] = this.articleType;
    this.$router.push({
      name: "UpdateArticle",
      params: record,
    });
  }
}
</script>

