<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div class="app-default-page-title mt-2">
      {{
        !userParams.nickname
          ? "會員編號:" + userParams.id
          : userParams.nickname
      }}的活動紀錄
    </div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>訂單記錄</div>
      </div>
      <div class="h-line"></div>
      <UserActivityTable :records.sync="activities" />
      <div class="pagination-wrap">
        <a-pagination
          v-model="currentPage"
          :defaultCurrent="1"
          :pageSize="perPage"
          :total="totalPage"
          @change="changePage"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../../store/index.js";
import { getAll } from "../../../service/api.js";
import UserActivityTable from "../../../components/userActivityTable";
import App from "../../../App";

@Component({
  name: "UserActivities",
  components: {
    UserActivityTable,
  },
})
export default class UserActivities extends App {
  activities = [];
  userParams = {};
  currentPage = 1;
  perPage = 15;
  totalPage = 0;

  async mounted() {
    try {
      const queryObj = this.$route.params;
      const vuxUserParams = await CommonModule.getRecordUserParams();
      if (Object.keys(queryObj).length != 0 || vuxUserParams != undefined) {
        const params =
          Object.keys(queryObj).length != 0 ? queryObj : vuxUserParams;
        await CommonModule.setRecordUserParams(params);
        this.userParams = params.userParams;
      }
      await this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.activities = [];
      const res = await getAll(
        `users/${this.userParams.id}/activity-orders`,
        currentPage
      );
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      this.activities = res.data;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  getUserStatus(status) {
    if (status == "active") {
      return "正常狀態";
    } else if (status == "observed") {
      return "觀察中";
    } else {
      return "暫停使用";
    }
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

