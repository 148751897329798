export class Ticket {

  activity_id = "";
  name = "";
  start_at = "";
  end_at = "";
  threshold = "";
  price = "";
  id = "";
  has_food = false;
  has_bed = false;
  tickets_available_count = 1;
  checkin_threshold = 1;
  level = "-1";
  service = [];
  amountType = "unlimit";
  ticketLimit = false;
  priceType = "free";

  init(res) {
    this.activity_id = res.activity_id;
    this.id = res.id;
    this.name = res.name;
    this.start_at = res.start_at;
    this.end_at = res.end_at;
    this.threshold = res.threshold;
    this.price = !res.price ? null : parseInt(res.price);
    this.has_food = res.has_food == 0 ? false : true;
    this.has_bed = res.has_bed == 0 ? false : true;
    this.tickets_available_count = res.tickets_available_count;
    this.checkin_threshold = res.checkin_threshold;
    this.level = res.level;
    this.amountType = !res.threshold ? "unlimit" : "limit";
    this.priceType = !res.price ? "free" : "price";
  }

  convert() {
    return JSON.parse(JSON.stringify(this));
  }
}