<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div class="app-default-page-title">
      {{ !isCreated ? "新增文章" : currentTitle }}
    </div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>{{ title }}內文</div>
        <a-button
          type="link"
          @click="editing = true"
          v-if="isCreated && !editing && articleType != 'unpublished'"
        >
          編輯
        </a-button>
      </div>
      <div class="h-line"></div>
      <div class="app-tab-wrap">
        <div
          class="app-tab-item"
          @click="changeTab('1')"
          :class="
            activeKey == '1' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          繁體中文
        </div>
        <div
          class="app-tab-item"
          @click="changeTab('2')"
          :class="
            activeKey == '2' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          簡體中文
        </div>
        <div
          class="app-tab-item"
          @click="changeTab('3')"
          :class="
            activeKey == '3' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          英文
        </div>
        <div
          class="app-tab-item"
          @click="changeTab('4')"
          :class="
            activeKey == '4' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          日文
        </div>
      </div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">文章標題</div>
        <a-input
          v-if="activeKey == '1'"
          :disabled="!editing"
          class="app-w-90"
          v-model="articleObjTw.title"
          placeholder="請輸入標題"
        />
        <a-input
          v-if="activeKey == '2'"
          :disabled="!editing"
          class="app-w-90"
          v-model="articleObjCn.title"
          placeholder="請輸入標題"
        />
        <a-input
          v-if="activeKey == '3'"
          :disabled="!editing"
          class="app-w-90"
          v-model="articleObjEn.title"
          placeholder="請輸入標題"
        />
        <a-input
          v-if="activeKey == '4'"
          :disabled="!editing"
          class="app-w-90"
          v-model="articleObjJp.title"
          placeholder="請輸入標題"
        />
      </div>
      <Editor
        v-if="activeKey == '1'"
        :editing.sync="editing"
        :content.sync="articleObjTw.content"
        actionType="contentTW"
        v-on:paramsChanges="changeContent"
      />
      <Editor
        v-if="activeKey == '2'"
        :editing.sync="editing"
        :content.sync="articleObjCn.content"
        actionType="contentCN"
        v-on:paramsChanges="changeContent"
      />
      <Editor
        v-if="activeKey == '3'"
        :editing.sync="editing"
        :content.sync="articleObjEn.content"
        actionType="contentEN"
        v-on:paramsChanges="changeContent"
      />
      <Editor
        v-if="activeKey == '4'"
        :editing.sync="editing"
        :content.sync="articleObjJp.content"
        actionType="contentJP"
        v-on:paramsChanges="changeContent"
      />
    </div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>發布設定</div>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">文章分類</div>
        <a-select
          placeholder="請選擇分類"
          class="app-w-30"
          v-model="currentCategory"
          :disabled="!editing"
        >
          <a-select-option
            :value="category.id"
            v-for="category in categories"
            :key="category.id"
          >
            {{ category.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">發布時間</div>
        <a-radio-group
          v-decorator="['radio-group']"
          v-model="publishType"
          :disabled="!editing"
        >
          <a-radio value="draft"> 暫存為草稿 </a-radio>
          <a-radio value="immediately"> 立即發布 </a-radio>
          <a-radio value="scheduled"> 排程 </a-radio>
        </a-radio-group>
        <a-date-picker
          class="app-w-20"
          format="YYYY/MM/DD HH:mm:ss"
          @change="onChangeOnlineDate"
          v-model="onlineDate"
          :disabled-date="disabledDate"
          :disabled="publishType != 'scheduled' || !editing"
          placeholder="請選擇日期時間"
        />
      </div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">下架時間</div>
        <a-radio-group
          v-decorator="['radio-group']"
          v-model="unpublishType"
          :disabled="!editing"
        >
          <a-radio value="infinity"> 不限時 </a-radio>
          <a-radio value="finite"> 限時 </a-radio>
        </a-radio-group>
        <a-date-picker
          class="app-w-20"
          format="YYYY/MM/DD HH:mm:ss"
          :disabled-date="disabledOfflineDate"
          @change="onChangeOfflineDate"
          v-model="offlineDate"
          :disabled="unpublishType != 'finite' || !editing"
          placeholder="請選擇日期時間"
        />
      </div>
    </div>
    <div
      class="app-default-wrap"
      v-if="articleType == 'published' || articleType == 'unpublished'"
    >
      <div class="user-title-wrap">
        <div>會員留言({{ currentComments.length }})</div>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-col justify-start items-center mt-5">
        <div
          class="flex-row p-5 m-2 w-full rounded-md bg-white"
          v-for="(comment, index) in currentComments"
          :key="index"
        >
          <div class="flex flex-row justify-between items-center mt-2">
            <div class="flex flex-row">
              <div class="mr-10">
                <span class="m-2">留言時間</span>
                <span class="m-2">{{
                  comment.created_at | moment("YYYY-MM-DD HH:mm:ss")
                }}</span>
              </div>
              <div class="mr-10">
                <span class="m-2">發言人</span>
                <span class="m-2">{{
                  !comment.by_user_profile
                    ? "-"
                    : comment.by_user_profile.nickname
                }}</span>
              </div>
            </div>
            <a-button
              type="link"
              class="self-center mr-3"
              @click="
                commentModel = true;
                currentComment = comment;
              "
            >
              刪除
            </a-button>
          </div>
          <div class="horizontal-line"></div>
          <div class="m-2 break-all max-h-20 overflow-hidden">
            {{ comment.comment }}
          </div>
        </div>
        <a-pagination
          class="flex justify-center m-3"
          v-model="currentPage"
          :defaultCurrent="1"
          :pageSize="perPage"
          :total="totalPage"
          @change="changePage"
          show-less-items
        />
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <div class="flex flex-row" v-if="editing">
        <a-button class="m-3 w-40 h-10" @click="cancel()">取消</a-button>
        <a-button class="m-3 w-40 h-10" @click="submit()" type="primary">
          儲存
        </a-button>
      </div>
      <a-button
        class="m-3 w-40 h-10"
        v-if="articleType == 'scheduled'"
        @click="recallModel = true"
      >
        撤回排程
      </a-button>
      <a-button
        class="m-3 w-40 h-10"
        v-if="articleType == 'published'"
        @click="offlineModel = true"
      >
        下架文章
      </a-button>
    </div>
    <a-modal
      v-model="offlineModel"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要下架此文章？</h4>
      <div class="mt-5">下架後會員將看不到這篇文章</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="offlineModel = false"
          >取消</a-button
        >
        <a-button class="w-40 ml-4" @click="offline()" type="primary">
          下架
        </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model="recallModel"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要撤回排程？</h4>
      <div class="mt-5">撤回後將轉存為草稿</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="recallModel = false">取消</a-button>
        <a-button class="w-40 ml-4" @click="recall()" type="primary">
          撤回
        </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model="commentModel"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要刪除此留言？</h4>
      <div class="mt-5">刪除後此留言將從文章中的討論串移除</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="recallModel = false">取消</a-button>
        <a-button class="w-40 ml-4" @click="deleteComment()" type="primary">
          撤回
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import {
  getOne,
  updateOne,
  deleteOne,
  getAll,
  updateAll,
  createOne,
} from "../../service/api.js";
import App from "../../App";
import moment from "moment";
import Editor from "../../components/editor.vue";

@Component({
  name: "UpdateArticle",
  components: {
    Editor,
  },
})
export default class UpdateArticle extends App {
  displayModel = false;
  recallModel = false;
  offlineModel = false;
  commentModel = false;
  editing = false;
  categories = [];
  currentComments = [];
  currentComment = {};
  currentCategory = null;
  currentPage = 1;
  perPage = 10;
  totalPage = 0;
  activeKey = "1";
  articleObjTw = {
    language: "zh_tw",
    title: "",
    content: "",
    comments: [],
  };
  articleObjEn = {
    language: "en",
    title: "",
    content: "",
    comments: [],
  };
  articleObjCn = {
    language: "zh_cn",
    title: "",
    content: "",
    comments: [],
  };
  articleObjJp = {
    language: "jp",
    title: "",
    content: "",
    comments: [],
  };
  title = "";
  currentTitle = "";
  action = "";
  isCreated = false;
  offlineDate = "";
  onlineDate = "";
  publishType = "draft";
  unpublishType = "infinity";
  articleType = "";
  originalObj = {};
  articleGroupId = "";
  articles = [];

  @Watch("$route")
  onRouterChanged(val, old) {
    this.initAction();
  }

  async mounted() {
    try {
      this.initAction();
    } catch (error) {
      console.log(error);
    }
  }

  async initAction() {
    await CommonModule.setLoading(true);
    try {
      const queryObj = this.$route.params;
      const articleTypes = await getAll("article-types");
      this.categories = articleTypes.data;
      if (Object.keys(queryObj).length != 0) {
        this.isCreated = true;
        this.editing = false;
        this.articleType = queryObj.articleType;
        this.articleGroupId = queryObj.group_id;
        const res = await getOne("articles/group_id", queryObj.group_id);

        // 上架
        if (res.status == "draft") {
          this.publishType = "draft";
        } else if (res.status == "publishing") {
          this.publishType = "immediately";
        } else {
          this.scheduled = "scheduled";
          this.onlineDate = res.online_date;
        }
        // 下架
        if (res.offline_date.indexOf("9999") == -1) {
          this.unpublishType = "finite";
          this.offlineDate = res.offline_date;
        }
        this.articles = res.articles;
        for (let i = 0; i < this.articles.length; i++) {
          const article = this.articles[i];
          if (article.language == "zh_tw") {
            this.articleObjTw = JSON.parse(JSON.stringify(article));
          } else if (article.language == "zh_cn") {
            this.articleObjCn = JSON.parse(JSON.stringify(article));
          } else if (article.language == "en") {
            this.articleObjEn = JSON.parse(JSON.stringify(article));
          } else if (article.language == "jp") {
            this.articleObjJp = JSON.parse(JSON.stringify(article));
          }
        }
        const self = this;
        const category = this.categories.find((el) => {
          return el.id == self.articleObjTw.type_id;
        });
        this.currentCategory = category.id;
        this.changeCurrentTitle();
      } else {
        this.currentCategory = undefined;
        this.editing = true;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  cancel() {
    if (!this.isCreated) {
      this.$router.go(-1);
    }
    this.editing = false;
    //originalObj
  }

  changeContent(params) {
    if (params.actionType == "contentTW") {
      this.articleObjTw.content = params.editorContent;
    } else if (params.actionType == "contentCN") {
      this.articleObjCn.content = params.editorContent;
    } else if (params.actionType == "contentEN") {
      this.articleObjEn.content = params.editorContent;
    } else if (params.actionType == "contentJP") {
      this.articleObjJp.content = params.editorContent;
    }
  }

  changeTab(key = "1") {
    this.activeKey = key;
    this.changeCurrentTitle(key);
  }

  changeCurrentTitle(key = 1) {
    if (this.isCreated) {
      if (key == 1) {
        this.currentTitle = this.articleObjTw.title;
        this.currentComments = this.articleObjTw.comments;
      } else if (key == 2) {
        this.currentTitle = this.articleObjCn.title;
        this.currentComments = this.articleObjCn.comments;
      } else if (key == 3) {
        this.currentTitle = this.articleObjEn.title;
        this.currentComments = this.articleObjEn.comments;
      } else {
        this.currentTitle = this.articleObjJp.title;
        this.currentComments = this.articleObjJp.comments;
      }
    }
  }

  disabledDate(current) {
    return moment().isAfter(current, 'date');
  }

  disabledOfflineDate(current) {
    return (
      moment().isAfter(current, 'date') ||
      (this.publishType == "scheduled" &&
        moment(current).isBefore(this.onlineDate))
    );
  }

  async submit() {
    if (this.currentCategory == undefined) {
      this.$message.info("請選擇文章分類");
      return;
    }
    if (this.titleTW == "" || this.contentTW == "") {
      this.$message.info("請選擇輸入中文文章資訊");
      return;
    }
    await CommonModule.setLoading(true);
    try {
      const category = this.categories.find((el) => {
        return el.id == this.currentCategory;
      });

      let params = {
        type_group_id: category.group_id,
        online_date: this.onlineDate,
        offline_date: this.offlineDate,
        articles: [],
      };
      if (this.articleObjTw.title != "" && this.articleObjTw.content != "") {
        params.articles.push(this.articleObjTw);
      }
      if (this.articleObjCn.title != "" && this.articleObjCn.content != "") {
        params.articles.push(this.articleObjCn);
      }
      if (this.articleObjEn.title != "" && this.articleObjEn.content != "") {
        params.articles.push(this.articleObjEn);
      }
      if (this.articleObjJp.title != "" && this.articleObjJp.content != "") {
        params.articles.push(this.articleObjJp);
      }
      // - online_date - optional
      // - 沒有則代表 status 為 「草稿」
      // - 如果是今天的日期則代表 status 為「立即發布」
      if (this.publishType == "draft") {
        delete params.online_date;
      } else if (this.publishType == "immediately") {
        params.online_date = moment().format("YYYY-MM-DD HH:mm:ss");
      }

      // - offline_date - optional
      // - 沒有則代表 「不限時」
      //     - `9999-12-31T23:59:59.000000Z` - 之後 Backend 會回這個代表不限時（為了要能夠 filter date range )
      if (this.unpublishType == "infinity") {
        delete params.offline_date;
      }

      if (!this.isCreated) {
        await createOne("articles", params);
      } else {
        await updateAll("articles", params);
      }
      this.$router.go(-1);
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async recall() {
    if (this.currentCategory == undefined) {
      this.$message.info("請選擇文章分類");
      return;
    }
    if (this.titleTW == "" || this.contentTW == "") {
      this.$message.info("請選擇輸入中文文章資訊");
      return;
    }
    this.recallModel = false;
    this.editing = false;
    this.isCreated = true;
    this.publishType == "draft";
    this.unpublishType == "infinity";
    this.submit();
  }

  async offline() {
    await CommonModule.setLoading(true);
    try {
      this.offlineModel = false;
      await updateOne("articles/group_id", this.articleGroupId);
      this.$router.go(-1);
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async deleteComment() {
    await CommonModule.setLoading(true);
    try {
      this.commentModel = false;
      await deleteOne("article-comments", this.currentComment.id);
      await this.initAction();
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      // this.getList(this.currentPage);
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
  }

  onChangeOnlineDate(date, dateString) {
    this.onlineDate = dateString;
  }

  onChangeOfflineDate(date, dateString) {
    this.offlineDate = dateString;
  }
}
</script>

<style lang="scss">
</style>
