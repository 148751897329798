<template>
  <div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>活動列表</div>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-row mb-2">
        <div class="flex flex-row justify-start mt-3 mb-3">
          <a-input placeholder="會員ID" class="mr-3" v-model="userID" />
        </div>
        <div class="flex flex-row justify-start mb-3 mt-3">
          <a-button type="primary" class="mr-3 w-28" @click="getList()">
            搜尋
          </a-button>
          <a-button
            type="link"
            class="mr-3 w-28"
            @click="clearSearchCondition()"
            >清空條件</a-button
          >
        </div>
      </div>
      <div class="app-table">
        <div class="app-tr rule-tr app-bb app-table-header">
          <div class="app-th">訂單編號</div>
          <div class="app-th">會員ID</div>
          <div class="app-th">暱稱</div>
          <div class="app-th justify-center">查看報名資料</div>
        </div>
        <div v-if="records.length != 0">
          <div
            class="app-tr rule-tr app-bb"
            v-for="(record, index) in records"
            :key="index"
          >
            <div class="app-td">{{ record.id }}</div>
            <div class="app-td">{{ record.user_id }}</div>
            <div class="app-td">{{ !record.name ? "-" : record.name }}</div>
            <div class="app-td justify-center">
              <a-button type="link" @click="gotoPage(record)">
                查看報名資料
              </a-button>
            </div>
          </div>
        </div>
        <div>
          <div class="none-data" v-if="records.length == 0">目前無任何資訊</div>
        </div>
      </div>
      <div class="pagination-wrap">
        <a-pagination
          v-model="currentPage"
          :defaultCurrent="1"
          :pageSize="perPage"
          :total="totalPage"
          @change="changePage"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../../store/index.js";
import {
  getNotifications,
  getActivities,
  getOne,
  getActivityOrder,
} from "../../../service/api.js";
import App from "../../../App";

@Component({
  name: "UnofficialOrders",
  components: {},
})
export default class UnofficialOrders extends App {
  @Prop() orderType;

  ticketMap = {};
  records = [];
  userID = "";
  activity = {};
  currentPage = 1;
  perPage = 15;
  totalPage = 0;

  async mounted() {
    try {
      const queryObj = this.$route.params;
      const vuxActivityParams = await CommonModule.getActivityParams();
      if (Object.keys(queryObj).length != 0 || vuxActivityParams != undefined) {
        const params =
          Object.keys(queryObj).length != 0 ? queryObj : vuxActivityParams;
        await CommonModule.setLoading(true);
        const res = await getOne("activities", params.id);
        this.activity = res;
        for (let i = 0; i < this.activity.tickets.length; i++) {
          const ticket = this.activity.tickets[i];
          this.ticketMap[ticket.id] = ticket.name;
        }
        await CommonModule.setActivityParams(res);
        this.getList();
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.records = [];
      const res = await getActivityOrder(
        "",
        this.activity.id,
        "",
        this.userID,
        "",
        "",
        "",
        "",
        currentPage
      );
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      this.records = res.data;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  clearSearchCondition() {
    this.userID = "";
    this.getList();
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  gotoPage(record) {
    record["visitor"] = false;
    record["editable"] = false;
    record["ticketMap"] = this.ticketMap;
    this.$router.push({
      name: "ActivitySignUpDetail",
      params: record,
    });
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.min-70 {
  min-width: 70px;
}
</style>
