<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2">
    服務條款
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>服務條款內文</div>
        <a-button type="link" @click="editing=true" v-if="!editing">
          編輯
        </a-button>
    </div>
    <div class="h-line"></div>
    <div class="app-tab-wrap">
      <div class="app-tab-item" @click="changeTab('zh_tw')" :class="activeKey == 'zh_tw'?'app-tab-item-active':'app-tab-item-default'" >繁體中文</div>
      <div class="app-tab-item" @click="changeTab('zh_cn')" :class="activeKey == 'zh_cn'?'app-tab-item-active':'app-tab-item-default'">簡體中文</div>
      <div class="app-tab-item" @click="changeTab('en')" :class="activeKey == 'en'?'app-tab-item-active':'app-tab-item-default'">英文</div>
      <div class="app-tab-item" @click="changeTab('jp')" :class="activeKey == 'jp'?'app-tab-item-active':'app-tab-item-default'">日文</div>
    </div>
    <div>
      <Editor v-if="activeKey == 'zh_tw'" :editing.sync="editing" :content.sync="pageParamsTW.page" actionType="zh_tw" v-on:paramsChanges="changeContent" />
      <Editor v-if="activeKey == 'zh_cn'" :editing.sync="editing" :content.sync="pageParamsCN.page" actionType="zh_cn" v-on:paramsChanges="changeContent" />
      <Editor v-if="activeKey == 'en'" :editing.sync="editing" :content.sync="pageParamsEN.page" actionType="en" v-on:paramsChanges="changeContent" />
      <Editor v-if="activeKey == 'jp'" :editing.sync="editing" :content.sync="pageParamsJP.page" actionType="jp" v-on:paramsChanges="changeContent" />
    </div>
    <div v-if="editing" class="text-center">
      <div class="h-line"></div>
      <a-button class="w-40 ml-4" @click="editing=false">取消</a-button>
      <a-button class="w-40 ml-4" @click="editing=false;submit()" type="primary">
        儲存
      </a-button>
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../../store/index.js";
import {
  getUsers,
  getOne,
  getAll,
  getSinglePage,
  updateOne
} from "../../../service/api.js";
import App from "../../../App";
import Editor from "../../../components/editor.vue";
import { PageParams } from "../../../models/pageParams";

@Component({
  name: "Terms",
  components: {
    Editor
  }
})
export default class Terms extends App {
  displayModel = false;
  activeKey = "zh_tw";
  currentTpye = "signup-term";
  pageParamsTW = new PageParams("zh_tw", this.currentTpye);
  pageParamsCN = new PageParams("zh_cn", this.currentTpye);
  pageParamsEN = new PageParams("en", this.currentTpye);
  pageParamsJP = new PageParams("jp", this.currentTpye);
  editing = false;

  async mounted() {
    this.changeTab();
    await CommonModule.setLoading(true);
    try {
      let res = await getSinglePage(this.currentTpye);
      for (let i = 0; i < res.pages.length; i++) {
        const item = res.pages[i];
        if (item.language == "zh_tw") {
          this.pageParamsTW.page = item.page;
        } else if (item.language == "zh_cn") {
          this.pageParamsCN.page = item.page;
        } else if (item.language == "jp") {
          this.pageParamsJP.page = item.page;
        } else {
          this.pageParamsEN.page = item.page;
        }
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changeContent(params) {
    if (params.actionType == "zh_tw") {
      this.pageParamsTW.page = params.editorContent;
    } else if (params.actionType == "zh_cn") {
      this.pageParamsCN.page = params.editorContent;
    } else if (params.actionType == "en") {
      this.pageParamsEN.page = params.editorContent;
    } else {
      this.pageParamsJP.page = params.editorContent;
    }
  }

  changeTab(key = "zh_tw") {
    this.activeKey = key;
  }

  async submit() {
    await CommonModule.setLoading(true);
    try {
      const params = {};
      if (this.pageParamsTW.page != "") {
        await updateOne("single-page", "", this.pageParamsTW.convert());
      }
      if (this.pageParamsEN.page != "") {
        await updateOne("single-page", "", this.pageParamsEN.convert());
      }
      if (this.pageParamsCN.page != "") {
        await updateOne("single-page", "", this.pageParamsCN.convert());
      }
      if (this.pageParamsJP.page != "") {
        await updateOne("single-page", "", this.pageParamsJP.convert());
      }
      this.$message.info("更新成功");
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }
}
</script>