<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2 flex flex-row justify-between items-center">
    <div>會員管理</div>
    <a-button @click="chooseFile()">
      匯入教練名單
    </a-button>
    <input ref="referenceUpload" type="file" style="display: none;" id="getFile" @change="uploadCsv" accept="text/csv" />
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>會員名單</div>
        <a-button type="link" @click="$router.push('/user/create')">
          + 新增會員
        </a-button>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-col mb-2">
      <div class="flex flex-row justify-start mb-3 mt-3">
        <a-select placeholder="請選擇國家" class="mr-3 w-60" v-model="currentCountry" :key="countryName"  @change="changeCountry">
          <a-select-option :value="country.name" v-for="country in countries" :key="country.id">
            {{country.name}}
          </a-select-option>
        </a-select>
        <a-select placeholder="請選擇會員狀態" class="mr-3 w-60" v-model="searchType" @change="currentType = searchType">
          <a-select-option value="active">
            使用中會員
          </a-select-option>
          <a-select-option value="inactive">
            已停用會員
          </a-select-option>
          <a-select-option value="visitor">
            訪客
          </a-select-option>
        </a-select>
      </div>
      <div class="flex flex-row justify-start mt-3 mb-3">
          <a-input placeholder="會員ID" class="mr-3" v-model="userID"/>
          <a-input placeholder="暱稱" class="mr-3"  v-model="nickname"/>
          <a-input placeholder="手機" class="mr-3"  v-model="mobile"/>
          <a-input placeholder="所屬教練" class="mr-3"  v-model="coachUserID" v-show="activeKey!='3'"/>
      </div>
      <div class="flex flex-row justify-start mb-3 mt-3">
          <a-button type="primary" class="mr-3 w-28" @click="getList()" >
            搜尋
          </a-button>
          <a-button type="link" class="mr-3 w-28" @click="clearSearchCondition()">清空條件</a-button>
      </div>
    </div>
    <div class="app-tab-wrap" type="ooo" id="myInput">
      <div class="app-tab-item" @click="changeTab('1')" :class="activeKey == '1'?'app-tab-item-active':'app-tab-item-default'">使用中會員</div>
      <div class="app-tab-item" @click="changeTab('2')" :class="activeKey == '2'?'app-tab-item-active':'app-tab-item-default'">已停用會員</div>
      <div class="app-tab-item" @click="changeTab('3')" :class="activeKey == '3'?'app-tab-item-active':'app-tab-item-default'">訪客</div>
    </div>
    <UserTable :records.sync="activeUsers" userType='enable' v-show="activeKey=='1'"/>
    <UserTable :records.sync="inactiveUsers"  userType='disable' v-show="activeKey=='2'"/>
    <VisitorTable :records.sync="visitors" v-show="activeKey=='3'"/>
    <div class="pagination-wrap">
      <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
    <a-modal v-model="displayModel" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
      <div class="text-center" style="padding:20px" v-if="fileUploadResult == null">
        <div class="text-lg"><b>資料更新中...</b></div>
      </div>
      <div class="" style="padding:20px" v-else>
        <div class="text-lg"><b>更新完成</b></div>
        <div class="mt-2 mb-2 p-2 bg-gray-100">
          <div class="mb-2">共 <b>{{fileUploadResult.total_processed_count}}</b> 會員進行資料更改</div>
          <div class="mb-2"><b>{{fileUploadResult.converted_count}}</b> 位會員已改成教練身份</div>
          <div class="mb-2"><b>{{fileUploadResult.already_is_coach_count}}</b> 位會員已是教練身份</div>
          <div><b>{{fileUploadResult.user_not_found_count}}</b> 位會員找不到資訊</div>
        </div>
        <!-- <div class="h-line"></div>
        <div>
          請確認下列會員是否存在或帳號已停用
          <div class="bg-gray-100">
            <div></div>
          </div>
        </div> -->
      </div>
    </a-modal>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getUsers, getCountryCode, usersConvert } from "../../service/api.js";
import UserTable from "../../components/userTable";
import VisitorTable from "../../components/visitorTable";
import App from "../../App";

@Component({
  name: "users",
  components: {
    UserTable,
    VisitorTable
  }
})
export default class Users extends App {
  activeUsers = [];
  inactiveUsers = [];
  visitors = [];
  condition = null;
  displayModel = false;
  countries = [];
  currentCountry = null;
  currentCountryCode = null;
  role = null;
  userID = "";
  nickname = "";
  coachUserID = "";
  mobile = "";
  activeKey = "1";
  searchType = null;
  currentType = "active";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  fileUploadResult = null;
  countryName = "";
  countriesMap = {};

  async mounted() {
    this.currentCountry = undefined;
    this.searchType = undefined;
    try {
      const queryObj = this.$route.params;
      const res = await getCountryCode();
      this.countries = res.codes;
      for (let i = 0; i < this.countries.length; i++) {
        const country = this.countries[i];
        this.countriesMap[country.name] = country.code;
      }
      await this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  changeCountry(name) {
    this.currentCountryCode = this.countriesMap[name];
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.activeUsers = [];
      this.inactiveUsers = [];
      this.visitors = [];
      const res = await getUsers(
        this.currentType,
        this.userID,
        this.currentCountryCode,
        this.mobile,
        this.nickname,
        this.coachUserID,
        currentPage
      );
      if (this.currentType == "active") {
        this.activeUsers = res.data;
      } else if (this.currentType == "inactive") {
        this.inactiveUsers = res.data;
      } else {
        this.visitors = res.data;
      }
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      if (this.currentStatus == "draft") {
        this.draft = res.data;
      } else if (this.currentStatus == "publishing") {
        this.published = res.data;
      } else if (this.currentStatus == "scheduled") {
        this.scheduled = res.data;
      } else if (this.currentStatus == "offline") {
        this.unpublished = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  getUserStatus(status) {
    if (status == "active") {
      return "正常狀態";
    } else if (status == "observed") {
      return "觀察中";
    } else {
      return "暫停使用";
    }
  }

  async searchUser() {
    await CommonModule.setLoading(true);
    try {
      this.role;
      this.currentCountryCode;
      this.mobile;
      this.nickname;
      this.userID;
      this.coachUserID;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  chooseFile() {
    document.getElementById("getFile").click();
  }

  async uploadCsv(event) {
    this.displayModel = true;
    this.fileUploadResult = null;
    try {
      let res = await usersConvert(event.target.files);
      this.fileUploadResult = res;
    } catch (error) {
      console.log(error);
    }
  }

  changeTab(key = "1") {
    this.activeKey = key;
    if (key == "1") {
      this.currentType = "active";
    } else if (key == "2") {
      this.currentType = "inactive";
    } else {
      this.currentType = "visitor";
    }
    this.getList(this.currentPage);
  }

  clearSearchCondition() {
    this.role = undefined;
    this.currentCountry = undefined;
    this.mobile = "";
    this.nickname = "";
    this.userID = "";
    this.coachUserID = "";
    this.searchType = undefined;
    this.getList();
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
