<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div v-if="userParams != null">
      <div class="app-default-page-title">{{ nickname }} 的生理狀態紀錄</div>
      <div class="app-default-wrap">
        <div>
          <span
            >完成時間
            {{ userParams.created_at | moment("YYYY-MM-DD HH:mm:ss") }}</span
          >
        </div>
        <div class="h-line"></div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-between mt-10">
            <div class="w-1/2 mr-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="排便次數">
                  <a-input
                    :value="
                      !userParams.defecation_count
                        ? '-'
                        : userParams.defecation_count
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="尿酮測試時間">
                  <a-input
                    :value="getDate(userParams.ketone_test_at)"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="睡眠時間">
                  <a-input
                    :value="getTime(userParams)"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="生理期">
                  <a-input
                    :value="getCount(userParams)"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
            <div class="w-1/2 ml-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="排便情況">
                  <a-input
                    :value="
                      !userParams.defecation_description
                        ? '-'
                        : userParams.defecation_description
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="尿酮測試結果">
                  <a-input
                    :value="!userParams.ketone ? '-' : userParams.ketone"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import { getOne } from "../../service/api.js";
import App from "../../App";
import moment from "moment";

@Component({
  name: "LifeRecord",
  components: {},
})
export default class LifeRecord extends App {
  userParams = null;
  nickname = "";
  async mounted() {
    try {
      const queryObj = this.$route.params;
      if (Object.keys(queryObj).length != 0) {
        this.userParams = queryObj;
        const params = await CommonModule.getRecordUserParams();
        this.nickname = params.profile.nickname;
      }
    } catch (error) {
      console.log(error);
    }
  }

  getDate(date) {
    if (!date) return "-";
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  getTime(record) {
    let wakeUpAt = !record.wake_up_at ? "-" : record.wake_up_at;
    let sleepAt = !record.sleep_at ? "-" : record.sleep_at;
    if (wakeUpAt == "-" && sleepAt == "-") return "-";
    return sleepAt + " ~ " + wakeUpAt;
  }

  getCount(record) {
    try {
      let a = moment(record.menstrual_cycle_record.start_date);
      let b = moment(record.user_date);
      let days = b.diff(a, "days") + 1;
      if (isNaN(days)) {
        days = 0;
      }
      return "第 " + days + " 天";
    } catch (error) {
      console.log(error);
      return "-";
    }
  }
}
</script>
