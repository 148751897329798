<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2">
    上秤指標管理
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>上秤指標列表</div>
    </div>
    <div class="h-line"></div>
    <div class="app-table">
      <div class="app-tr rule-tr app-bb app-table-header">
        <div class="app-th app-w-15" >指標名稱</div>
        <div class="app-th justify-center app-w-20">指標名稱說明</div>
        <div class="app-th justify-start ">指標等級說明</div>
      </div>
      <div v-if="recordList.length != 0">
        <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
          <div class="app-td app-w-15">
            {{record.key_title}}
          </div>
          <a-button type="link" @click="gotoPage(record, 'indexName')" class="app-td justify-center app-w-20" style="overflow:hidden">
            編輯指標說明
          </a-button>
          <div class="app-td justify-start ">
            <a-button type="link" @click="gotoPage(record, 'indexLevel', degree)" v-for="(degree,idx) in record.levels" :key="idx">
             等級 {{degree}}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../../store/index.js";
import { getAll } from "../../../service/api.js";
import App from "../../../App";

@Component({
  name: "Scales",
  components: {}
})
export default class Scales extends App {
  recordList = [];
  category = "";

  async mounted() {
    await CommonModule.setLoading(true);
    try {
      let res = await getAll("body-indices");
      this.recordList = res.body_indices;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  gotoPage(record, actionType, level = 1) {
    record["actionType"] = actionType;
    record["level"] = level;
    this.$router.push({
      name: "ScalesUpdate",
      params: record
    });
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.min-70 {
  min-width: 70px;
}
</style>
