<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div>
      <div class="app-default-page-title">
        {{ order.id }}
      </div>
      <div class="app-default-wrap">
        <div>
          <span>報名資料</span>
        </div>
        <div class="h-line"></div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-between mt-10">
            <div class="w-1/2 mr-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="會員ID">
                  <a-input
                    :value="order.user_id ? order.user_id : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="姓名">
                  <a-input
                    :value="order.name ? order.name : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="月分紅級別">
                  <a-input
                    :value="
                      cloudMallRecord && cloudMallRecord.month_bonus_level
                        ? cloudMallRecord.month_bonus_level
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="季分紅級別">
                  <a-input
                    :value="
                      cloudMallRecord && cloudMallRecord.quarter_bonus_level
                        ? cloudMallRecord.quarter_bonus_level
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="年分紅級別">
                  <a-input
                    :value="
                      cloudMallRecord && cloudMallRecord.year_bonus_level
                        ? cloudMallRecord.year_bonus_level
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="手機">
                  <a-input
                    :value="order.phone ? order.phone : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="上階經理">
                  <a-input
                    :value="
                      cloudMallRecord && cloudMallRecord.director_customer_name
                        ? cloudMallRecord.director_customer_name
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="餐點">
                  <a-input
                    :value="order.meal ? order.meal : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="組別">
                  <a-input
                    :value="activityUserGroup ? activityUserGroup.group : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="備註">
                  <a-input
                    :value="activityUserGroup ? activityUserGroup.remark : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
            <div class="w-1/2 ml-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="經銷商編號">
                  <a-input
                    :value="
                      cloudMallRecord && cloudMallRecord.merchant_id
                        ? cloudMallRecord.merchant_id
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="會員級別">
                  <a-input
                    :value="getUserLevel(order.reseller_level)"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="Email">
                  <a-input
                    :value="order.email ? order.email : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="地區">
                  <a-input
                    :value="getLocation(order.region)"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="地址">
                  <a-input
                    :value="order.address ? order.address : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="上階邀請人">
                  <a-input
                    :value="
                      cloudMallRecord && cloudMallRecord.parent_customer_name
                        ? cloudMallRecord.parent_customer_name
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="票種">
                  <a-input
                    :value="
                      ticketMap[order.ticket_id]
                        ? ticketMap[order.ticket_id]
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="同房人">
                  <a-input
                    :value="order.roommate ? order.roommate : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="備註">
                  <a-input
                    :value="order.remark ? order.remark : '-'"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
      <div class="app-default-wrap">
        <div>
          <span>訂單資訊</span>
        </div>
        <div class="h-line"></div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-between mt-10">
            <div class="w-1/2 mr-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="訂單編號">
                  <a-input :value="order.id" placeholder="" :disabled="true" />
                </a-form-item>
                <a-form-item label="金流單號">
                  <a-input
                    :value="
                      payment && payment.payment_number
                        ? payment.payment_number
                        : '-'
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
            <div class="w-1/2 ml-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="訂單狀態">
                  <a-input
                    :value="getStatus(order.status)"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
      <a-button class="w-40 ml-4" @click="deleteModel = true"> 退票 </a-button>
    </div>
    <a-modal
      v-model="deleteModel"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要退票？</h4>
      <div class="mt-5">請確認是否和會員達成協議</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="deleteModel = false">取消</a-button>
        <a-button class="w-40 ml-4" @click="refund()" type="primary">
          刪除
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../../store/index.js";
import { getOne, createOne } from "../../../service/api.js";
import App from "../../../App";
import moment from "moment";

@Component({
  name: "ActivitySignUpDetail",
  components: {},
})
export default class ActivitySignUpDetail extends App {
  order = {};
  cloudMallRecord = {};
  activityUserGroup = {};
  payment = {};
  deleteModel = false;
  ticketMap = {};
  async mounted() {
    try {
      const queryObj = this.$route.params;
      this.record = queryObj;
      const vuxActivityParams = await CommonModule.getActivityParams();
      if (Object.keys(queryObj).length != 0 || vuxActivityParams != undefined) {
        const params =
          Object.keys(queryObj).length != 0 ? queryObj : vuxActivityParams;
        let res = await getOne("activity-orders", params.id);
        this.order = res.order;
        this.cloudMallRecord = res.cloud_mall_record;
        this.activityUserGroup = res.activity_user_group;
        this.payment = res.payment;
        this.ticketMap = params.ticketMap;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async refund(params) {
    await CommonModule.setLoading(true);
    this.deleteModel = false;
    try {
      await createOne(`activity-orders/${this.order.id}/refund`);
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  getUserLevel(val) {
    if (val == 0) {
      return "一般會員";
    } else if (val == 1) {
      return "初級";
    } else if (val == 2) {
      return "中級";
    } else if (val == 3) {
      return "高級";
    } else if (val == 4) {
      return "經銷商";
    } else {
      return "-";
    }
  }

  getLocation(location) {
    if (location == "north") {
      return "北";
    } else if (location == "center") {
      return "中";
    } else if (location == "east") {
      return "東";
    } else if (location == "south") {
      return "南";
    } else if (location == "oversea") {
      return "海外";
    } else {
      return "-";
    }
  }

  getStatus(status) {
    if (status == "registered") {
      return "已付款";
    } else if (status == "pending") {
      return "未付款";
    } else if (status == "refund") {
      return "已退票";
    } else if (status == "registered-offline") {
      return "現場報名";
    } else if (status == "expired") {
      return "已過期";
    } else {
      return "-";
    }
  }
}
</script>
