<template>
  <div class="footer" ></div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { CommonModule, Store } from "../store/index.js";

@Component
export default class Footer extends Vue {
  async mounted() {}
}
</script>

<style>
.footer {
  height: 50px;
  width: 100%;
  line-height: 50px;
  margin: 0 auto;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.version {
  text-align: right;
}
</style>
