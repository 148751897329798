<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title mt-2">
    {{userParams == null?"":userParams.nickname}} 的訪客
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>訪客名單</div>
    </div>
    <div class="h-line"></div>
    <VisitorTable :records.sync="visitors" />
    <div class="pagination-wrap">
      <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../../store/index.js";
import { getAll } from "../../../service/api.js";
import VisitorTable from "../../../components/visitorTable";
import App from "../../../App";

@Component({
  name: "UserVisitors",
  components: {
    VisitorTable
  }
})
export default class UserVisitors extends App {
  visitors = [];
  userParams = null;
  currentPage = 1;
  perPage = 15;
  totalPage = 0;

  async mounted() {
    try {
      const queryObj = this.$route.params;
      const vuxVisitorParams = await CommonModule.getRecordVisitorParams();
      if (Object.keys(queryObj).length != 0) {
        await CommonModule.setRecordVisitorParams(queryObj);
        this.userParams = queryObj.userParams;
      } else if (vuxVisitorParams != undefined) {
        this.userParams = vuxVisitorParams.userParams;
      }
      this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.visitors = [];
      const res = await getAll(
        `users/${this.userParams.id}/guests`,
        currentPage
      );
      this.visitors = res.data;
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

