import Vue from "vue";
import Router from "vue-router";
import login from "../views/auth/login";
import Reset from "../views/auth/reset";
import dashboard from "../views/dashboard/index";
import CreateUser from "../views/users/create";
import UpdateUser from "../views/users/update";
import UserReport from "../views/users/report";
import UserRawData from "../views/users/rawData";
import UserVisitors from "../views/users/visitors/index";
import UserStudents from "../views/users/students/index";
import UserActivities from "../views/users/activities/index";
import LifeRecord from "../views/users/lifeRecord";
import DietRecord from "../views/users/dietRecord";
import Users from "../views/users/index";
import Administrators from "../views/administrators/index";
import UpdateAdministrator from "../views/administrators/update";
import Coaches from "../views/coaches/index";
import Notifications from "../views/notifications/index";
import UpdateNotification from "../views/notifications/update";
import Articles from "../views/articles/index";
import ArticleHistory from "../views/articles/history";
import UpdateArticle from "../views/articles/update";
import Categories from "../views/articles/categories/index";
import Feedback from "../views/feedback/index";
import UpdateFeedback from "../views/feedback/update";
import Activities from "../views/activities/index";
import UpdateActivity from "../views/activities/update";
import ActivitySignUp from "../views/activities/signup/index";
import ActivitySignUpDetail from "../views/activities/signup/detail";
import Contraindications from "../views/settings/contraindications/index";
import QA from "../views/settings/qa/index";
import Terms from "../views/settings/terms/index";
import Scales from "../views/settings/scales/index";
import ScalesUpdate from "../views/settings/scales/update";
import pageNotFound from "../views/exception/pageNotFound";

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Router.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject)
  window.open(href, '_blank')
}

Vue.use(Router);

export const appRouter = new Router({
  routes: [
    { path: "/", component: login, meta: { ns: '' } },
    { path: "/reset", component: Reset, meta: { ns: '' } },
    { path: "/user/create", component: CreateUser, name: "CreateUser", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/update", component: UpdateUser, name: "UpdateUser", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/report", component: UserReport, name: "UserReport", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/raw", component: UserRawData, name: "UserRawData", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/visitors", component: UserVisitors, name: "UserVisitors", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/students", component: UserStudents, name: "UserStudents", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/activities", component: UserActivities, name: "UserActivities", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/life", component: LifeRecord, name: "LifeRecord", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/user/diet", component: DietRecord, name: "DietRecord", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/users", component: Users, name: "Users", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/contraindications", component: Contraindications, name: "Contraindications", meta: { ns: '/ADMIN/DASHBOARD/SETTING' } },
    { path: "/qa", component: QA, name: "QA", meta: { ns: '/ADMIN/DASHBOARD/SETTING' } },
    { path: "/terms", component: Terms, name: "Terms", meta: { ns: '/ADMIN/DASHBOARD/SETTING' } },
    { path: "/articles", component: Articles, name: "Articles", meta: { ns: '/ADMIN/DASHBOARD/ARTICLE' } },
    { path: "/articles/history", component: ArticleHistory, name: "ArticleHistory", meta: { ns: '/ADMIN/DASHBOARD/ARTICLE' } },
    { path: "/articles/update", component: UpdateArticle, name: "UpdateArticle", meta: { ns: '/ADMIN/DASHBOARD/ARTICLE' } },
    { path: "/notifications", component: Notifications, name: "Notifications", meta: { ns: '/ADMIN/DASHBOARD/NOTIFICATION' } },
    { path: "/notifications/update", component: UpdateNotification, name: "UpdateNotification", meta: { ns: '/ADMIN/DASHBOARD/NOTIFICATION' } },
    { path: "/categories", component: Categories, name: "Categories", meta: { ns: '/ADMIN/DASHBOARD/ARTICLE' } },
    { path: "/feedback", component: Feedback, name: "Feedback", meta: { ns: '/ADMIN/DASHBOARD/FEEDBACK' } },
    { path: "/feedback/update", component: UpdateFeedback, name: "UpdateFeedback", meta: { ns: '/ADMIN/DASHBOARD/FEEDBACK' } },
    { path: "/scales", component: Scales, name: "Scales", meta: { ns: '/ADMIN/DASHBOARD/SETTING' } },
    { path: "/scales/update", component: ScalesUpdate, name: "ScalesUpdate", meta: { ns: '/ADMIN/DASHBOARD/SETTING' } },
    { path: "/coaches", component: Coaches, name: "Coaches", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/administrators/update", component: UpdateAdministrator, name: "UpdateAdministrator", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/administrators", component: Administrators, name: "administrators", meta: { ns: '/ADMIN/DASHBOARD/AUTH' } },
    { path: "/activities", component: Activities, name: "Activities", meta: { ns: '' } },
    { path: "/activities/update", component: UpdateActivity, name: "UpdateActivity", meta: { ns: '' } },
    { path: "/activities/signup", component: ActivitySignUp, name: "ActivitySignUp", meta: { ns: '' } },
    { path: "/activities/signup/detail", component: ActivitySignUpDetail, name: "ActivitySignUpDetail", meta: { ns: '' } },
    { path: "/dashboard", component: dashboard, meta: { ns: '' } },
    { path: "*", redirect: "/404", meta: { ns: '' } },
    { path: "/404", component: pageNotFound, meta: { ns: '' } },
  ],
});


appRouter.beforeEach((to, from, next) => {
  if (localStorage.getItem("a") == null || localStorage.getItem("s") == null) {
    next();
  } else {
    // decode sidebar from localsotrage
    let requiresAuth = JSON.parse(atob(localStorage.getItem("s")));
    if (to.meta.ns == '' || requiresAuth[to.meta.ns]) {
      next();
    } else {
      window.location.href = "/#/";
    }
  }
});