<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2">
    意見回饋
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>意見回饋列表</div>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row mb-2">
      <div class="flex flex-row justify-start mt-3 mb-3">
        <a-input placeholder="會員ID" class="mr-3" v-model="userID" />
        <a-input placeholder="暱稱" class="mr-3" v-model="nickname" />
      </div>
      <div class="flex flex-row justify-start mb-3 mt-3">
        <a-button type="primary" class="mr-3 w-28" @click="getList()">
          搜尋
        </a-button>
        <a-button type="link" class="mr-3 w-28" @click="clearSearchCondition()">清空條件</a-button>
      </div>
    </div>
    <div class="app-tab-wrap">
      <div class="app-tab-item" @click="changeTab('1')" :class="activeKey == '1'?'app-tab-item-active':'app-tab-item-default'">未解決</div>
      <div class="app-tab-item" @click="changeTab('2')" :class="activeKey == '2'?'app-tab-item-active':'app-tab-item-default'">已解決</div>
    </div>
    <FeedbackTable :records.sync="feedback"  feedbackType='new' v-show="activeKey=='1'"/>
    <FeedbackTable :records.sync="solvedFeedback"  feedbackType='closed' v-show="activeKey=='2'"/>
    <div class="pagination-wrap">
      <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getFeedback } from "../../service/api.js";
import FeedbackTable from "../../components/feedbackTable";
import App from "../../App";

@Component({
  name: "Feedback",
  components: {
    FeedbackTable
  }
})
export default class Feedback extends App {
  feedback = [];
  solvedFeedback = [];
  condition = null;
  userID = "";
  nickname = "";
  activeKey = "1";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  currentType = "new";

  async mounted() {
    try {
      const queryObj = this.$route.params;
      this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      let res = await getFeedback(
        this.currentType,
        this.userID,
        this.nickname,
        currentPage
      );
      this.solvedFeedback = [];
      this.feedback = [];
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      for (let i = 0; i < res.data.length; i++) {
        const record = res.data[i];
        if (record.status == "new") {
          this.feedback.push(record);
        } else {
          this.solvedFeedback.push(record);
        }
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changeTab(key = "1") {
    this.activeKey = key;
    if (this.activeKey == "1") {
      this.currentType = "new";
    } else {
      this.currentType = "closed";
    }
    this.getList(this.currentPage);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  clearSearchCondition() {
    this.nickname = "";
    this.userID = "";
    this.getList();
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
