<template>
  <div class="app-table ">
    <div class="app-tr rule-tr app-bb app-table-header">
      <div class="app-th app-w-20">會員ID</div>
      <div class="app-th app-w-20">姓名</div>
      <div class="app-th justify-center">票種</div>
      <div class="app-th  app-w-40 justify-center" >狀態</div>
    </div>
    <div v-if="recordList.length != 0">
      <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
        <div class="app-td app-w-20">{{record.id}}</div>
        <div class="app-td app-w-20">{{!record.related_profile ?"-" :record.related_profile.nickname}}</div>
        <div class="app-td justify-center">{{record.ticket.name}}</div>
        <div class="app-td app-w-40 justify-center">
          <a-button @click="checkIn(record, index)" v-if="record.checkin == null" :disabled="currentType=='finished'">
            進行簽到
          </a-button>
          <span v-else>{{record.checkin.created_at | moment("YYYY/MM/DD HH:mm:ss")}} 簽到成功</span>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";
import { createOne } from "../service/api.js";
@Component
export default class ActivitySignUpTable extends Vue {
  @Prop() records;
  @Prop() currentDate;
  @Prop() activityType;
  recordList = [];
  currentType = "";
  checkInDate = "";
  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }
  @Watch("currentDate")
  updateCurrentDate() {
    this.checkInDate = this.currentDate;
  }

  @Watch("activityType")
  updateActivityType() {
    this.currentType = this.activityType;
  }

  async checkIn(record, index) {
    await CommonModule.setLoading(true);
    try {
      const params = {
        date: this.checkInDate,
        user_id: record.id
      };
      const res = await createOne(
        `activities/${record.activity_order.activity_id}/checkins`,
        params
      );
      this.$set(record, "checkin", res);
      this.$emit("updateCount", true);
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  gotoPage(record) {
    record["visitor"] = false;
    record["editable"] = false;
    this.$router.push({
      name: "UpdateUser",
      params: record
    });
  }
}
</script>

<style>
</style>
