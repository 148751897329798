export class PageParams {
  constructor(language, type) {
    this.language = language;
    this.type = type;
  }
  language = "";
  type = "";
  page = "";
  convert() {
    return JSON.parse(JSON.stringify(this));
  }
}