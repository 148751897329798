<template>
  <div class="app-table">
    <div class="app-tr rule-tr app-bb app-table-header">
      <div class="app-th">上秤時間</div>
      <div class="app-th">體重</div>
      <div class="app-th">體脂率</div>
      <div class="app-th justify-center">查看上秤報告</div>
      <!-- <div class="app-th justify-center">查看原始數據</div> -->
    </div>
    <div v-if="recordList.length != 0">
      <div
        class="app-tr rule-tr app-bb"
        v-for="(record, index) in recordList"
        :key="index"
      >
        <div class="app-td">
          {{ record.user_datetime | moment("YYYY-MM-DD HH:mm:ss") }}
        </div>
        <div class="app-td">{{ record.weight }}</div>
        <div class="app-td">{{ record.fat_rate }}</div>
        <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(record)">
            查看上秤報告
          </a-button>
        </div>
        <!-- <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(user)">
            查看原始數據
          </a-button>
        </div> -->
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length == 0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch, C } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";
import moment from "moment";

@Component
export default class BodyRecordsTable extends Vue {
  @Prop() records;
  recordList = [];

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  gotoPage(record) {
    record["from"] = "user";
    this.$router.push({
      name: "UserReport",
      params: record,
    });
  }
}
</script>

<style>
</style>
