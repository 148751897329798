<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2 flex justify-between">
    <div>文章管理</div>
      <a-button @click="$router.push('/categories')">
        分類管理
      </a-button>
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>文章列表</div>
        <a-button type="link" @click="gotoPage()">
          + 新增文章
        </a-button>
    </div>
    <div class="h-line"></div>
     <div class="flex flex-row mb-2">
      <div class="flex flex-row justify-start mt-3 mb-3">
        <a-select placeholder="請選擇分類"  class="mr-3 w-60" v-model="currentCategory" >
          <a-select-option :value="category.id" v-for="category in categories" :key="category.id">
            {{category.name}}
          </a-select-option>
        </a-select>
        <a-input placeholder="標題" class="mr-3" v-model="currentTitle" />
      </div>
      <div class="flex flex-row justify-start mb-3 mt-3">
        <a-button type="primary" class="mr-3 w-28" @click="getList()">
          搜尋
        </a-button>
        <a-button type="link" class="mr-3 w-28" @click="clearSearchCondition()">清空條件</a-button>
      </div>
    </div>
    <div class="app-tab-wrap">
      <div class="app-tab-item" @click="changeTab('1')" :class="activeKey == '1'?'app-tab-item-active':'app-tab-item-default'">已排程</div>
      <div class="app-tab-item" @click="changeTab('2')" :class="activeKey == '2'?'app-tab-item-active':'app-tab-item-default'">草稿</div>
      <div class="app-tab-item" @click="changeTab('3')" :class="activeKey == '3'?'app-tab-item-active':'app-tab-item-default'">已發佈</div>
      <div class="app-tab-item" @click="changeTab('4')" :class="activeKey == '4'?'app-tab-item-active':'app-tab-item-default'">已下架</div>
    </div>
    <ArticleTable :records.sync="scheduled" articleType='scheduled' v-show="activeKey=='1'"/>
    <ArticleTable :records.sync="draft" articleType='draft' v-show="activeKey=='2'"/>
    <ArticleTable :records.sync="published" articleType='published' v-show="activeKey=='3'"/>
    <ArticleTable :records.sync="unpublished" articleType='unpublished' v-show="activeKey=='4'"/>
   <div class="pagination-wrap">
      <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getArticles, getAll } from "../../service/api.js";
import App from "../../App";
import ArticleTable from "../../components/articleTable";

@Component({
  name: "Articles",
  components: { ArticleTable }
})
export default class Articles extends App {
  category = "";
  title = "";
  activeKey = "1";
  recordList = [];
  scheduled = [];
  draft = [];
  published = [];
  unpublished = [];
  categories = [];
  currentTitle = "";
  currentCategory = null;
  currentStatus = "scheduled";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  async mounted() {
    try {
      const queryObj = this.$route.params;
      const articleTypes = await getAll("article-types");
      this.categories = articleTypes.data;
      this.currentCategory = undefined;
      this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.recordList = [];
      this.scheduled = [];
      this.draft = [];
      this.published = [];
      this.unpublished = [];
      const res = await getArticles(
        this.currentStatus,
        this.currentCategory,
        this.currentTitle,
        currentPage
      );
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      if (this.currentStatus == "draft") {
        this.draft = res.data;
      } else if (this.currentStatus == "publishing") {
        this.published = res.data;
      } else if (this.currentStatus == "scheduled") {
        this.scheduled = res.data;
      } else if (this.currentStatus == "offline") {
        this.unpublished = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changeTab(key = "1") {
    this.activeKey = key;
    if (key == "1") {
      this.currentStatus = "scheduled";
    } else if (key == "2") {
      this.currentStatus = "draft";
    } else if (key == "3") {
      this.currentStatus = "publishing";
    } else if (key == "4") {
      this.currentStatus = "offline";
    }
    this.getList();
  }

  clearSearchCondition() {
    this.currentTitle = "";
    this.currentCategory = undefined;
    this.getList();
  }

  gotoPage(record) {
    this.$router.push({
      name: "UpdateArticle",
      params: record
    });
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.min-70 {
  min-width: 70px;
}
</style>
