<template>
<div class="uni-wrap">
  <div class="spin-content" v-if="isLoading">
    <a-spin class="spinner" />
  </div>
  <div class="login-panel">
    <div class="login-wrap flex flex-col items-center">
      <div class="login-title" @click="username='admin1';password='2021'">WOW 後台管理系統</div>
      <div class="login-card" >
        <div class="text-center font-bold text-2xl">登入</div>
        <div>
          <div class="flex flex-row">
            <a-icon type="user" style=" font-size: 30px;margin: 0 10px;" />
            <a-input v-model="username" placeholder="請輸入使用者名稱" />
          </div>
          <div class="flex flex-row">
            <a-icon type="lock" style=" font-size: 30px;margin: 0 10px;" />
            <a-input-password v-model="password" placeholder="請輸入密碼" />
          </div>
        </div>
        <a-button  @click="login()" class="app-default-button login-button" type="primary">
          登入
        </a-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { login, getOne } from "../../service/api.js";

@Component({
  name: "Login",
  components: {}
})
export default class Login extends Vue {
  toggleDisable = true;
  username = "";
  password = "";
  isLoading = false;
  mounted() {
    const store = Store();
    store.watch(
      state => state.common.loading,
      val => {
        this.isLoading = val;
      }
    );
  }

  async login() {
    await CommonModule.setLoading(true);
    try {
      const res = await login(this.username, this.password);
      await AuthenticationModule.setAuth(res);
      const admin = await getOne(`admins`, res.id);
      let params = {};
      for (let i = 0; i < admin.user_tags.length; i++) {
        const tag = admin.user_tags[i];
        params[tag.ns] = true;
      }
      await AuthenticationModule.setSideBar(params);
      this.$router.push({
        path: "/dashboard"
      });
    } catch (error) {
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }
}
</script>

<style>
body {
  background: white;
}

.text-center {
  text-align: center;
}

.login-button {
  width: 100%;
  margin: 30px 0;
}

.login-panel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.login-wrap {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #f0f2f5 no-repeat 50%;
  background-size: 100%;
}

.login-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 450px;
  height: 400px;
  box-shadow: 3px 3px 30px #ddd;
}

.login-wrap div {
  margin: 20px 0px 10px 0;
}

.login-title {
  color: #000;
  font-size: 35px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.login-input-title {
  font-size: 12px;
}

.login-input {
  width: 100% !important;
  padding: 0px !important;
}

.login-error-icon {
  width: 15px;
  margin-top: -5px;
}

.res-error-msg {
  color: #687c97;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.res-error-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
