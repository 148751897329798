export class UserBasicParams {
  constructor(res) {
    if (res == undefined) return;
    if (res.user == undefined) return;
    this.role = res.user.role;
    this.country_code = res.user.country_code;
    this.username = res.user.username;
    this.nickname = res.user_profile.nickname;
    this.gender = res.user_profile.gender;
    this.birthday = res.user_profile.birthday;
    this.height_type = res.user_profile.height_type;
    this.height = res.user_profile.height;
    this.avatar_name = res.user_profile.avatar_name;
    this.avatar_url = res.user_profile.avatar_url;
    this.coach_user_id = res.user.coach_user_id;
    this.updated_at = res.user.updated_at;
    this.fat_loss_plan = res.fat_loss_plan;
    this.password_change = false
  }
  id = "";
  role = "user";
  country_code = "";
  username = "";
  nickname = "";
  password = "";
  confirm_password = "";
  gender = "male";
  birthday = "";
  height_type = "cm";
  height = "";
  avatar_name = "";
  avatar_url = "";
  coach_user_id = "";
  updated_at = "";
  fat_loss_plan = null;
  password_change = false
  username_change = false

  setUserId(id) {
    this.id = id;
  }

  convert() {
    return JSON.parse(JSON.stringify(this));
  }
}