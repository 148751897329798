<template>
  <div class="app-table ">
    <div class="app-tr rule-tr app-bb app-table-header">
      <div class="app-th app-w-10" v-if="category != 'registered-offline'">訂單編號</div>
      <div class="app-th app-w-10">會員ID</div>
      <div class="app-th app-w-10">姓名</div>
      <div class="app-th app-w-10 justify-center">手機</div>
      <div class="app-th app-w-30 justify-center">票種</div>
      <div class="app-th justify-center" v-if="category == 'registered'||category == 'registered-offline'">組別</div>
      <!-- <div class="app-th justify-center" v-if="orderType == 'pending'">狀態</div> -->
      <div class="app-th app-w-10 justify-center">查看報名資料</div>
    </div>
    <div v-if="recordList.length != 0">
      <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
        <div class="app-td app-w-10" v-if="category != 'registered-offline'">{{record.id}}</div>
        <div class="app-td app-w-10">{{record.user_id}}</div>
        <div class="app-td app-w-10">{{!record.name ? '-' : record.name}}</div>
        <div class="app-td app-w-10 justify-center">{{!record.phone ? '-' : record.phone}}</div>
        <div class="app-td app-w-30 justify-center">{{getTicketName(record.ticket_id)}}</div>
        <div class="app-td justify-center" v-if="category == 'registered'||category == 'registered-offline'">{{getGroup(record)}}</div>
        <!-- <div class="app-td justify-center" @click="gotoPage(record)" v-if="orderType == 'pending'">狀態</div> -->
        <div class="app-td app-w-10 justify-center">
          <a-button type="link" @click="gotoPage(record)">
            查看報名資料
          </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";

@Component
export default class OfficialOrderTable extends Vue {
  @Prop() records;
  @Prop() orderType;
  recordList = [];
  ticketMap = {};
  category = "registered";
  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }
  @Watch("orderType")
  updateOrderType() {
    this.category = this.orderType;
  }

  async mounted() {
    try {
      const activity = await CommonModule.getActivityParams();
      for (let i = 0; i < activity.tickets.length; i++) {
        const ticket = activity.tickets[i];
        this.ticketMap[ticket.id] = ticket.name;
      }
    } catch (error) {
      console.log(error);
    }
  }

  getTicketName(id) {
    return this.ticketMap[id];
  }

  getGroup(record) {
    if (record == undefined || record.activity_user_group == undefined) {
      return "-";
    }
    let remark = "";
    if (record.activity_user_group.remark != undefined) {
      remark = `(${record.activity_user_group.remark})`;
    }
    return record.activity_user_group.group + remark;
  }

  gotoPage(record) {
    record["visitor"] = false;
    record["editable"] = false;
    record["ticketMap"] = this.ticketMap;
    this.$router.push({
      name: "ActivitySignUpDetail",
      params: record
    });
  }
}
</script>

<style>
</style>
