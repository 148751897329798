var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"app-default-page-back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('a-icon',{staticClass:"mr-3",attrs:{"type":"arrow-left"}}),_vm._v("返回 ")],1),_c('div',[_c('div',{staticClass:"app-default-page-title"},[_vm._v(" "+_vm._s(_vm.order.id)+" ")]),_c('div',{staticClass:"app-default-wrap"},[_vm._m(0),_c('div',{staticClass:"h-line"}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row justify-between mt-10"},[_c('div',{staticClass:"w-1/2 mr-5"},[_c('a-form',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 15 }}},[_c('a-form-item',{attrs:{"label":"會員ID"}},[_c('a-input',{attrs:{"value":_vm.order.user_id ? _vm.order.user_id : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{attrs:{"value":_vm.order.name ? _vm.order.name : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"月分紅級別"}},[_c('a-input',{attrs:{"value":_vm.cloudMallRecord && _vm.cloudMallRecord.month_bonus_level
                      ? _vm.cloudMallRecord.month_bonus_level
                      : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"季分紅級別"}},[_c('a-input',{attrs:{"value":_vm.cloudMallRecord && _vm.cloudMallRecord.quarter_bonus_level
                      ? _vm.cloudMallRecord.quarter_bonus_level
                      : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"年分紅級別"}},[_c('a-input',{attrs:{"value":_vm.cloudMallRecord && _vm.cloudMallRecord.year_bonus_level
                      ? _vm.cloudMallRecord.year_bonus_level
                      : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"手機"}},[_c('a-input',{attrs:{"value":_vm.order.phone ? _vm.order.phone : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"上階經理"}},[_c('a-input',{attrs:{"value":_vm.cloudMallRecord && _vm.cloudMallRecord.director_customer_name
                      ? _vm.cloudMallRecord.director_customer_name
                      : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"餐點"}},[_c('a-input',{attrs:{"value":_vm.order.meal ? _vm.order.meal : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"組別"}},[_c('a-input',{attrs:{"value":_vm.activityUserGroup ? _vm.activityUserGroup.group : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"備註"}},[_c('a-input',{attrs:{"value":_vm.activityUserGroup ? _vm.activityUserGroup.remark : '-',"placeholder":"","disabled":true}})],1)],1)],1),_c('div',{staticClass:"w-1/2 ml-5"},[_c('a-form',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 15 }}},[_c('a-form-item',{attrs:{"label":"經銷商編號"}},[_c('a-input',{attrs:{"value":_vm.cloudMallRecord && _vm.cloudMallRecord.merchant_id
                      ? _vm.cloudMallRecord.merchant_id
                      : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"會員級別"}},[_c('a-input',{attrs:{"value":_vm.getUserLevel(_vm.order.reseller_level),"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{attrs:{"value":_vm.order.email ? _vm.order.email : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"地區"}},[_c('a-input',{attrs:{"value":_vm.getLocation(_vm.order.region),"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"地址"}},[_c('a-input',{attrs:{"value":_vm.order.address ? _vm.order.address : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"上階邀請人"}},[_c('a-input',{attrs:{"value":_vm.cloudMallRecord && _vm.cloudMallRecord.parent_customer_name
                      ? _vm.cloudMallRecord.parent_customer_name
                      : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"票種"}},[_c('a-input',{attrs:{"value":_vm.ticketMap[_vm.order.ticket_id]
                      ? _vm.ticketMap[_vm.order.ticket_id]
                      : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"同房人"}},[_c('a-input',{attrs:{"value":_vm.order.roommate ? _vm.order.roommate : '-',"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"備註"}},[_c('a-input',{attrs:{"value":_vm.order.remark ? _vm.order.remark : '-',"placeholder":"","disabled":true}})],1)],1)],1)])])]),_c('div',{staticClass:"app-default-wrap"},[_vm._m(1),_c('div',{staticClass:"h-line"}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row justify-between mt-10"},[_c('div',{staticClass:"w-1/2 mr-5"},[_c('a-form',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 15 }}},[_c('a-form-item',{attrs:{"label":"訂單編號"}},[_c('a-input',{attrs:{"value":_vm.order.id,"placeholder":"","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"金流單號"}},[_c('a-input',{attrs:{"value":_vm.payment && _vm.payment.payment_number
                      ? _vm.payment.payment_number
                      : '-',"placeholder":"","disabled":true}})],1)],1)],1),_c('div',{staticClass:"w-1/2 ml-5"},[_c('a-form',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 15 }}},[_c('a-form-item',{attrs:{"label":"訂單狀態"}},[_c('a-input',{attrs:{"value":_vm.getStatus(_vm.order.status),"placeholder":"","disabled":true}})],1)],1)],1)])])]),_c('a-button',{staticClass:"w-40 ml-4",on:{"click":function($event){_vm.deleteModel = true}}},[_vm._v(" 退票 ")])],1),_c('a-modal',{staticClass:"costom-model",attrs:{"centered":true,"cancelButtonProps":{ style: { display: 'none' } },"footer":null,"destroyOnClose":true,"maskClosable":false},model:{value:(_vm.deleteModel),callback:function ($$v) {_vm.deleteModel=$$v},expression:"deleteModel"}},[_c('h4',[_vm._v("確定要退票？")]),_c('div',{staticClass:"mt-5"},[_vm._v("請確認是否和會員達成協議")]),_c('div',{staticClass:"text-center mt-6"},[_c('a-button',{staticClass:"w-40 ml-4",on:{"click":function($event){_vm.deleteModel = false}}},[_vm._v("取消")]),_c('a-button',{staticClass:"w-40 ml-4",attrs:{"type":"primary"},on:{"click":function($event){return _vm.refund()}}},[_vm._v(" 刪除 ")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("報名資料")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("訂單資訊")])])}]

export { render, staticRenderFns }