<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div v-if="userParams != null">
      <div class="app-default-page-title">{{ nickname }} 的飲食紀錄</div>
      <div class="app-default-wrap">
        <div>
          <span
            >上秤時間
            {{ userParams.created_at | moment("YYYY-MM-DD HH:mm:ss") }}</span
          >
        </div>
        <div class="h-line"></div>
        <div class="flex flex-col bg-gray-100">
          <div class="flex flex-col pl-10 pt-10">
            <div class="flex flex-row">
              <div class="p-2">飲水量</div>
              <div class="p-2 ml-5">{{ userParams.water }} ml</div>
            </div>
          </div>
          <div class="flex flex-col p-10">
            <div class="p-2">早餐</div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5">{{ userParams.breakfast_at }}</div>
            </div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5 flex flex-row">
                <img
                  style="width: 300px"
                  :src="userParams.breakfast_image_url"
                  v-if="userParams.breakfast_image_url"
                />
                <div class="ml-5">{{ userParams.breakfast_description }}</div>
              </div>
            </div>
          </div>
          <div class="flex flex-col p-10">
            <div class="p-2">午餐</div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5">{{ userParams.lunch_at }}</div>
            </div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5 flex flex-row">
                <img
                  style="width: 300px"
                  :src="userParams.lunch_image_url"
                  v-if="userParams.lunch_image_url"
                />
                <div class="ml-5">{{ userParams.lunch_description }}</div>
              </div>
            </div>
          </div>
          <div class="flex flex-col p-10">
            <div class="p-2">下午</div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5">{{ userParams.afternoon_tea_at }}</div>
            </div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5 flex flex-row">
                <img
                  style="width: 300px"
                  :src="userParams.afternoon_tea_image_url"
                  v-if="userParams.afternoon_tea_image_url"
                />
                <div class="ml-5">
                  {{ userParams.afternoon_tea_description }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col p-10">
            <div class="p-2">晚餐</div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5">{{ userParams.dinner_at }}</div>
            </div>
            <div class="flex flex-row">
              <div class="p-2">用餐時間</div>
              <div class="p-2 ml-5 flex flex-row">
                <img
                  style="width: 300px"
                  :src="userParams.dinner_image_url"
                  v-if="userParams.dinner_image_url"
                />
                <div class="ml-5">{{ userParams.dinner_description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import { getOne } from "../../service/api.js";
import App from "../../App";
import moment from "moment";

@Component({
  name: "DietRecord",
  components: {},
})
export default class DietRecord extends App {
  userParams = null;
  nickname = "";
  async mounted() {
    try {
      const queryObj = this.$route.params;
      if (Object.keys(queryObj).length != 0) {
        this.userParams = queryObj;
        const params = await CommonModule.getRecordUserParams();
        this.nickname = params.profile.nickname;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
</script>
