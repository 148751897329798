<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title ">
    {{userID}}的上秤報告
  </div>
  <div class="app-default-wrap">
    <div>
      <span>上秤時間</span>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-col ">
      <div class="flex flex-row justify-between mt-10">
        <div class="w-1/2 mr-5">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="WEIGHT">
              <a-input :value="weight" placeholder="" :disabled="true" />
            </a-form-item>
            <a-form-item label="BMI">
              <a-input :value="bmi" placeholder="" :disabled="true" />
            </a-form-item>
            <a-form-item label="BONE">
              <a-input :value="bone" placeholder="" :disabled="true" />
            </a-form-item>
            <a-form-item label="BASALMETABOLISM">
              <a-input :value="bmr" placeholder="" :disabled="true" />
            </a-form-item>
            <a-form-item label="PROTEIN">
              <a-input :value="protein" placeholder="" :disabled="true" />
            </a-form-item>
          </a-form>
        </div>
        <div class="w-1/2 ml-5">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="PBF">
              <a-input :value="pbf" placeholder="" :disabled="true" />
            </a-form-item>
            <a-form-item label="BADYWATER">
              <a-input :value="water" placeholder="" :disabled="true" />
            </a-form-item>
            <a-form-item label="VF">
              <a-input :value="vf" placeholder="" :disabled="true" />
            </a-form-item>
            <a-form-item label="Measurement module">
              <a-input :value="measurementModule" placeholder="" :disabled="true" />
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>

  </div>

</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getOne } from "../../service/api.js";
import App from "../../App";
import moment from "moment";

@Component({
  name: "UserRawData",
  components: {}
})
export default class UserRawData extends App {
  userID = "";
  user = {};
  weight = "";
  protein = "";
  bone = "";
  vf = "";
  water = "";
  bmi = "";
  bmr = "";
  pbf = "";
  measurementModule = "";
}
</script>
