<template>
<div class="">
  <div class="mt-10 ml-10 flex flex-col justify-start">
    <div class="text-2xl mb-5 ml-2">代辦事項</div>
    <div class="flex flex-row justify-start" >
      <div class="" >
        <div class="flex flex-col p-15 m-2 w-56 rounded-md	bg-white	" >
          <h2 class="text-lg justify-start mt-5 ml-5">更換教練</h2>
          <div class="flex justify-end p-5 items-end">
            <div class="text-4xl mr-3 text-blue-500 font-bold	" :class="changeCoachNumber==0?'':'cursor-pointer'" @click="changeCoachNumber==0?'':gotoPage('coaches')">{{changeCoachNumber}}</div>
            <div>人待審核</div>
          </div>
        </div>
      </div>
      <div class="ml-10" >
        <div class="flex flex-col p-15 m-2 w-56 rounded-md bg-white" >
          <h2 class="text-lg justify-start mt-5 ml-5">意見回饋</h2>
          <div class="flex justify-end p-5 items-end		">
            <div class="text-4xl mr-3 text-blue-500 font-bold		" :class="feedbackNumber==0?'':'cursor-pointer'" @click="feedbackNumber == 0 ? '':gotoPage('feedback')">{{feedbackNumber}}</div>
            <div>則未解決</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-10 flex flex-col justify-start">
    <div class="text-2xl mb-5 ml-2">文章動態</div>
    <div class="flex flex-col justify-start" >
      <div class="flex-col p-5 m-2 w-full rounded-md bg-white"  v-for="(article, index) in articles" :key="index" >
        <div class="flex flex-row justify-between items-center mt-2" >
          <div class="flex flex-row">
            <div class="mr-10">
              <span class="m-2">留言時間</span>
              <span class="m-2">{{article.created_at | moment('YYYY-MM-DD HH:mm:ss')}}</span>
            </div>
            <div class="mr-10">
              <span class="m-2">發言人</span>
              <span class="m-2">{{article.author == undefined ? "": article.author.nickname}}</span>
            </div>
            <div class="mr-10">
              <span class="m-2">所屬文章</span>
              <span class="m-2 text-blue-500 font-bold cursor-pointer" @click="gotoPage('article', article)">{{article.title}}</span>
            </div>
          </div>
          <a-button type="link" class="self-center mr-3" @click="openOfflineDialog(article)">
            刪除
          </a-button>
        </div>
        <div class="horizontal-line"></div>
        <div class="m-2 break-all max-h-20 overflow-hidden" v-html="article.content">
        </div>
      </div>
      <a-pagination class="flex justify-center m-3" v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
    <a-modal v-model="offlineModel" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
      <h4>確定要下架此文章？</h4>
      <div class="mt-5">
        下架後會員將看不到這篇文章
      </div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="offlineModel=false">取消</a-button>
        <a-button class="w-40 ml-4" @click="offline();" type="primary">
          下架
        </a-button>
      </div>
    </a-modal>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import {
  getArticles,
  getChangeCoachApplications,
  getFeedback,
  getAll,
  updateOne
} from "../../service/api.js";
import moment from "moment";
import App from "../../App";

@Component({
  name: "dashboard",
  components: {}
})
export default class Dashboard extends App {
  displaySearchModel = false;
  offlineModel = false;
  displaySmsModel = false;
  username = "";
  currentArticle = null;
  currentPage = 1;
  perPage = 10;
  totalPage = 0;
  changeCoachNumber = 0;
  feedbackNumber = 0;
  articles = [];
  async mounted() {
    await CommonModule.setLoading(true);
    try {
      const coachRes = await getChangeCoachApplications("pending");
      this.changeCoachNumber = coachRes.total;
      const feedbackRes = await getFeedback("new");
      this.feedbackNumber = feedbackRes.total;
      await this.getList();
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  openOfflineDialog(article) {
    this.offlineModel = true;
    this.currentArticle = article;
  }

  async offline() {
    await CommonModule.setLoading(true);
    try {
      this.offlineModel = false;
      await updateOne("articles/group_id", this.currentArticle.group_id);
      this.getList();
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  gotoPage(type, record = null) {
    if (record != null) {
      record["articleType"] = record.status;
      this.$router.push({
        name: "UpdateArticle",
        params: record
      });
    } else if (type == "feedback") {
      this.$router.push({
        name: "Feedback",
        params: { status: "new" }
      });
    } else {
      this.$router.push({
        name: "Coaches",
        params: { status: "pending" }
      });
    }
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.articles = [];
      const res = await getArticles("publishing", undefined, "", currentPage);
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      this.articles = res.data;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  getUserResponse(userResponse) {
    this.displaySearchModel = false;
    this.$router.push({
      name: "users",
      params: userResponse
    });
  }
}
</script>
