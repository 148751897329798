<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div class="app-default-page-title">
      {{ !isCreated ? "新增通知" : id }}
    </div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>通知內容</div>
        <a-button
          type="link"
          @click="editing = true"
          v-if="isCreated && !editing && status != 'sent'"
        >
          編輯
        </a-button>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">文章標題</div>
        <a-input
          :disabled="!editing"
          class="app-w-90"
          v-model="title"
          placeholder="請輸入標題"
        />
      </div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">內文</div>
        <a-textarea
          :disabled="!editing"
          :rows="6"
          class="app-w-90"
          v-model="message"
          placeholder="請輸入內容"
        />
      </div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">版位</div>
        <div class="flex flex-row items-center w-1/2">
          <a-radio
            value="in_app"
            @change="toggleNotificationType('in_app')"
            :checked="notificationType == 'in_app'"
            :disabled="!editing"
          >
            App內通知
          </a-radio>
          <a-select
            placeholder="請選擇通知分類"
            :disabled="notificationType != 'in_app' || !editing"
            class="mr-3 w-60"
            v-model="category"
          >
            <a-select-option value="system"> 系統 </a-select-option>
            <a-select-option value="coach"> 教練 </a-select-option>
            <a-select-option value="student"> 學員 </a-select-option>
            <a-select-option value="article"> 文章 </a-select-option>
            <a-select-option value="announcement"> 公告 </a-select-option>
          </a-select>
        </div>
        <div class="flex flex-row items-center w-1/2">
          <a-radio
            value="push"
            @change="toggleNotificationType('push')"
            :checked="notificationType == 'push'"
            :disabled="!editing"
          >
            推播
          </a-radio>
          <a-select
            placeholder="請選擇連結類別"
            class="mr-3 w-1/2"
            v-model="linkType"
            :disabled="notificationType != 'push' || !editing"
          >
            <a-select-option value="article"> 文章 </a-select-option>
            <a-select-option value="activity"> 活動 </a-select-option>
          </a-select>
          <a-input
            :disabled="notificationType != 'push' || !editing"
            class="w-1/2"
            v-model="linkValue"
            placeholder="請輸入指定頁面 ID"
          />
        </div>
      </div>
    </div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>發送設定</div>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">手機語系</div>
        <a-select
          placeholder="請選擇手機語系"
          class="app-w-30"
          :disabled="!editing"
          mode="multiple"
          :default-value="targetLanguages"
          @change="handleTargetLanguagesChange"
        >
          <a-select-option value="zh_tw"> 繁體中文 </a-select-option>
          <a-select-option value="zh_cn"> 簡體中文 </a-select-option>
          <a-select-option value="en"> 英文 </a-select-option>
          <a-select-option value="jp"> 日文 </a-select-option>
        </a-select>
      </div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">指定會員</div>
        <a-radio
          value="all_users"
          @change="targetType = 'all_users'"
          :checked="targetType == 'all_users'"
          :disabled="!editing"
        >
          所有會員
        </a-radio>

        <a-radio
          value="coach"
          @change="targetType = 'coach'"
          :checked="targetType == 'coach'"
          :disabled="ture"
        >
          顧問
        </a-radio>

        <a-radio
          value="student"
          @change="targetType = 'student'"
          :checked="targetType == 'student'"
          :disabled="ture"
        >
          顧客
        </a-radio>

        <a-radio
          value="reseller_levels"
          @change="targetType = 'reseller_levels'"
          :checked="targetType == 'reseller_levels'"
          :disabled="!editing"
        >
          會員級別
        </a-radio>

        <a-select
          placeholder="請選擇會員級別"
          :default-value="resellerLevels"
          :disabled="targetType != 'reseller_levels' || !editing"
          class="mr-3 w-1/2"
          mode="multiple"
          @change="handleResellerLevelsChange"
        >
          <a-select-option value="0"> 一般會員 </a-select-option>
          <a-select-option value="1"> 初級 </a-select-option>
          <a-select-option value="2"> 中級 </a-select-option>
          <a-select-option value="3"> 高級 </a-select-option>
          <a-select-option value="4"> 經銷商 </a-select-option>
        </a-select>
        <a-radio
          value="year_bonus_levels"
          @change="targetType = 'year_bonus_levels'"
          :checked="targetType == 'year_bonus_levels'"
          :disabled="!editing"
        >
          年分紅級別
        </a-radio>
        <a-select
          placeholder="請選擇年分紅級別"
          :default-value="yearBonusLevels"
          :disabled="targetType != 'year_bonus_levels' || !editing"
          class="mr-3 w-1/2"
          mode="multiple"
          @change="handleYearBonusLevelsChange"
        >
          <a-select-option value="0"> Lv.1 </a-select-option>
          <a-select-option value="1"> Lv.2 </a-select-option>
          <a-select-option value="2"> Lv.3 </a-select-option>
          <a-select-option value="3"> VP </a-select-option>
          <a-select-option value="4"> P </a-select-option>
        </a-select>
        <!-- <a-radio value="D" @change="targetType = 'D'" :checked="targetType=='D'">
          活動名單
      </a-radio>
      <a-input :disabled="!editing" class=" w-1/2" v-model="title" placeholder="請輸入指定頁面 ID"/> -->
      </div>
      <div class="flex flex-row justify-start items-center mt-5">
        <div class="m-3 app-w-10">發送時間</div>
        <a-radio-group
          v-decorator="['radio-group']"
          v-model="publishType"
          :disabled="!editing"
        >
          <a-radio value="draft"> 暫存為草稿 </a-radio>
          <a-radio value="immediately"> 立即發布 </a-radio>
          <a-radio value="scheduled"> 排程 </a-radio>
        </a-radio-group>
        <a-date-picker
          :disabled-date="disabledDate"
          format="YYYY/MM/DD HH:mm:ss"
          class="app-w-20"
          :disabled="publishType != 'scheduled' || !editing"
          placeholder="請選擇日期時間"
          v-model="scheduledAt"
        />
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <div class="flex flex-row" v-if="editing">
        <a-button class="m-3 w-40 h-10" @click="cancel()">取消</a-button>
        <a-button
          class="m-3 w-40 h-10"
          @click="submit()"
          type="primary"
          :disabled="title == '' && message == ''"
        >
          儲存
        </a-button>
      </div>
      <a-button
        class="m-3 w-40 h-10"
        v-if="status == 'scheduled'"
        @click="recallModel = true"
      >
        撤回排程
      </a-button>
    </div>
    <a-modal
      v-model="recallModel"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要撤回排程？</h4>
      <div class="mt-5">撤回後將轉存為草稿</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="recallModel = false">取消</a-button>
        <a-button class="w-40 ml-4" @click="recall()" type="primary">
          撤回
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import { getOne, updateOne, createOne } from "../../service/api.js";
import App from "../../App";
import moment from "moment";
import Editor from "../../components/editor.vue";

@Component({
  name: "UpdateNotification",
  components: {
    Editor,
  },
})
export default class UpdateNotification extends App {
  displayModel = false;
  recallModel = false;
  editing = false;
  currentRecord = {};
  title = "";
  message = "";
  id = "";
  category = "system";
  linkType = "article";
  linkValue = "";
  targetLanguages = [];
  scheduledAt = "";
  sentAt = "";
  status = "draft";
  lastUpdatedBy = "";
  action = "";
  isCreated = false;
  publishType = "draft";
  unpublishType = "";
  notificationType = "in_app";
  targetType = "all_users";
  targetValue = "";
  resellerLevels = [];
  yearBonusLevels = [];

  @Watch("$route")
  onRouterChanged(val, old) {
    this.initAction();
  }

  async mounted() {
    try {
      this.initAction();
    } catch (error) {
      console.log(error);
    }
  }

  async initAction() {
    const queryObj = this.$route.params;
    if (Object.keys(queryObj).length != 0) {
      this.isCreated = true;
      this.editing = false;
      this.status = queryObj.status;
      const res = await getOne("notification-schedules", queryObj.id);
      this.currentRecord = res;
      this.id = res.id;
      this.title = res.title;
      this.message = res.message;
      this.notificationType = res.notification_type;
      if (this.notificationType == "in_app") {
        this.category = res.category;
      } else {
        this.linkType = res.link_type;
        this.linkValue = res.link_value;
      }
      res.target_languages.forEach((val) => {
        this.targetLanguages.push(`${val}`);
      });
      this.targetType = res.target_type;
      this.targetValue = res.target_value;
      if (this.targetType == "reseller_levels") {
        this.targetValue.forEach((val) => {
          this.resellerLevels.push(`${val}`);
        });
      } else if (this.targetType == "year_bonus_levels") {
        this.targetValue.forEach((val) => {
          this.yearBonusLevels.push(`${val}`);
        });
      }
      this.publishType = res.status;
      this.scheduledAt = res.scheduled_at;
      this.sentAt = res.sent_at;
      this.lastUpdatedBy = res.last_updated_by;
    } else {
      this.editing = true;
    }
  }

  toggleNotificationType(type) {
    this.notificationType = type;
  }

  cancel() {
    if (!this.isCreated) {
      this.$router.go(-1);
    }
    this.editing = false;
    //originalObj
  }

  async submit() {
    try {
      let params = {
        title: this.title,
        message: this.message,
        notification_type: this.notificationType,
        target_type: this.targetType,
        target_languages: this.targetLanguages,
      };

      if (this.notificationType == "in_app") {
        params["category"] = this.category;
      } else {
        params["link_type"] = this.linkType;
        params["link_value"] = this.linkValue;
        if (this.linkValue == "") {
          this.$message.info("請輸入指定頁面 ID");
          return;
        }
      }

      if (this.targetType == "reseller_levels") {
        if (this.resellerLevels.length == 0) {
          this.$message.info("請選擇會員級別");
          return;
        }
        params["target_value"] = this.resellerLevels;
      } else if (this.targetType == "year_bonus_levels") {
        if (this.yearBonusLevels.length == 0) {
          this.$message.info("請選擇年分紅級別");
          return;
        }
        params["target_value"] = this.yearBonusLevels;
      }

      if (this.publishType == "draft") {
        params["to_send_immediately"] = false;
      } else if (this.publishType == "immediately") {
        params["to_send_immediately"] = true;
      } else {
        if (this.scheduledAt == "" || this.scheduledAt == null) {
          this.$message.info("請選擇日期時間");
          return;
        }
        params["scheduled_at"] = this.scheduledAt;
      }

      await CommonModule.setLoading(true);

      if (this.isCreated) {
        await updateOne(
          "notification-schedules",
          this.currentRecord.id,
          params
        );
      } else {
        await createOne("notification-schedules", params);
      }
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  async recall() {
    await CommonModule.setLoading(true);
    try {
      this.recallModel = false;
      await createOne(
        `notification-schedules/${this.currentRecord.id}/cancel-schedule-time`,
        {}
      );
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  getDefaultName(type) {
    let defaultArr = [];
    if (type == "reseller") {
      this.resellerLevels.forEach((val) => {
        if (val == 0) {
          defaultArr.push("一般會員");
        } else if (val == 1) {
          defaultArr.push("初級");
        } else if (val == 2) {
          defaultArr.push("中級");
        } else if (val == 3) {
          defaultArr.push("高級");
        } else if (val == 4) {
          defaultArr.push("經銷商");
        }
      });
    } else {
      this.yearBonusLevels.forEach((val) => {
        if (val == 0) {
          defaultArr.push(" Lv.1");
        } else if (val == 1) {
          defaultArr.push("Lv.2");
        } else if (val == 2) {
          defaultArr.push("Lv.3");
        } else if (val == 3) {
          defaultArr.push("VP");
        } else if (val == 4) {
          defaultArr.push("P");
        }
      });
    }
    return defaultArr;
  }

  handleResellerLevelsChange(value) {
    this.resellerLevels = value;
  }

  handleYearBonusLevelsChange(value) {
    this.yearBonusLevels = value;
  }

  handleTargetLanguagesChange(value) {
    this.targetLanguages = value;
  }

  disabledDate(current) {
    return moment().isAfter(current, "date");
  }
}
</script>

<style lang="scss">
</style>
