import Vue from 'vue';
import Vuex from 'vuex';
import Authentication from './authentication';
import Common from './common';
import VuexPersistence from 'vuex-persist';
import { getModule } from 'vuex-module-decorators';
Vue.use(Vuex);
export const Store = () => {
  let plugins = []
  let store = new Vuex.Store({
    modules: {
      authentication: Authentication,
      common: Common,
    },
  });
  if (process.browser) {
    const vuexLocal = new VuexPersistence({
      storage: window.localStorage
    })
    plugins.push(vuexLocal.plugin);
  }
  return store;
};

export default Store;
export const AuthenticationModule = getModule(Authentication, Store());
export const CommonModule = getModule(Common, Store());
