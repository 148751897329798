<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div class="app-default-page-title">
      {{ userID }}
    </div>
    <div
      class="flex flex-row justify-between"
      style="margin: 0 10px"
      v-if="!visitor"
    >
      <div class="" :class="visitorNumber == 0 ? '' : 'pointer'">
        <div
          class="flex flex-col p-15 m-2 w-72 rounded-md bg-white"
          @click="visitorNumber == 0 ? null : gotoPage('visitors')"
        >
          <h2 class="text-lg justify-start mt-5 ml-5">訪客</h2>
          <div class="flex justify-end p-5 items-end">
            <div class="text-4xl mr-3 text-blue-500 font-bold">
              {{ visitorNumber }}
            </div>
            <div>人</div>
          </div>
        </div>
      </div>
      <div class="ml-10" :class="clientNumber == 0 ? '' : 'pointer'">
        <div
          class="flex flex-col p-15 m-2 w-72 rounded-md bg-white"
          @click="clientNumber == 0 ? null : gotoPage('students')"
        >
          <h2 class="text-lg justify-start mt-5 ml-5">指導學員</h2>
          <div class="flex justify-end p-5 items-end">
            <div class="text-4xl mr-3 text-blue-500 font-bold">
              {{ clientNumber }}
            </div>
            <div>人</div>
          </div>
        </div>
      </div>
      <div class="ml-10" :class="activityNumber == 0 ? '' : 'pointer'">
        <div
          class="flex flex-col p-15 m-2 w-72 rounded-md bg-white"
          @click="activityNumber == 0 ? null : gotoPage('activities')"
        >
          <h2 class="text-lg justify-start mt-5 ml-5">報名活動</h2>
          <div class="flex justify-end p-5 items-end">
            <div class="text-4xl mr-3 text-blue-500 font-bold">
              {{ activityNumber }}
            </div>
            <div>場</div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-default-wrap" v-if="!visitor">
      <div class="user-title-wrap" style="height: 21px">
        <div>
          <span>會員資料</span>
        </div>
        <a-button
          type="link"
          @click="
            editable = true;
            editing = true;
          "
          v-if="!editable && !editing && currentUserInfoType != 'shop'"
        >
          編輯
        </a-button>
      </div>
      <div class="h-line"></div>
      <div>
        <div class="app-tab-wrap">
          <div
            class="app-tab-item"
            @click="changeUserInfoTab('1')"
            :class="
              activeMemberPanelKey == '1'
                ? 'app-tab-item-active'
                : 'app-tab-item-default'
            "
          >
            基本資料
          </div>
          <div
            class="app-tab-item"
            @click="changeUserInfoTab('2')"
            :class="
              activeMemberPanelKey == '2'
                ? 'app-tab-item-active'
                : 'app-tab-item-default'
            "
          >
            健康問卷
          </div>
          <div
            class="app-tab-item"
            @click="changeUserInfoTab('3')"
            :class="
              activeMemberPanelKey == '3'
                ? 'app-tab-item-active'
                : 'app-tab-item-default'
            "
          >
            雲商城資料
          </div>
        </div>
        <User
          v-show="activeMemberPanelKey == '1'"
          :userParams.sync="userParams"
          :editable="editable"
          :isCreated="true"
          :editing.sync="editing"
          :visitor.sync="visitor"
          @changeUserParams="getUserParams"
        />
        <Questionnaire
          v-show="activeMemberPanelKey == '2'"
          :healthParams.sync="healthParams"
          :editable.sync="editable"
          @changeHealthParams="getHealthParams"
        />
        <CloudShop
          v-show="activeMemberPanelKey == '3'"
          :shopParams.sync="shopParams"
          :editable.sync="editable"
        />
      </div>
      <div
        v-if="editable && editing && currentUserInfoType != 'shop'"
        class="text-center"
      >
        <div class="h-line"></div>
        <a-button
          class="w-40 ml-4"
          @click="
            editable = false;
            editing = false;
          "
          >取消</a-button
        >
        <a-button class="w-40 ml-4" @click="sumbit()" type="primary">
          儲存
        </a-button>
      </div>
    </div>
    <div class="app-default-wrap" v-if="!visitor">
      <div>
        <span>健康數據</span>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-rowmt-5 mb-5">
        <a-range-picker
          @change="onChangeDate"
          :placeholder="['請選擇日期', '請選擇日期']"
          :default-value="[startAt, endAt]"
          :value="[startAt, endAt]"
          v-decorator="['range-time-picker', rangeConfig]"
          format="YYYY-MM-DD"
        />
        <a-button class="w-40 ml-4" @click="getList()" type="primary">
          搜尋
        </a-button>
        <a-button class="w-40 ml-4" @click="clearSearch()">清除條件</a-button>
      </div>
      <div>
        <div class="app-tab-wrap" v-if="!visitor">
          <div
            class="app-tab-item"
            @click="changeHealthTab('1')"
            :class="
              activeKey == '1' ? 'app-tab-item-active' : 'app-tab-item-default'
            "
          >
            上秤紀錄
          </div>
          <div
            class="app-tab-item"
            @click="changeHealthTab('2')"
            :class="
              activeKey == '2' ? 'app-tab-item-active' : 'app-tab-item-default'
            "
          >
            飲食紀錄
          </div>
          <div
            class="app-tab-item"
            @click="changeHealthTab('3')"
            :class="
              activeKey == '3' ? 'app-tab-item-active' : 'app-tab-item-default'
            "
          >
            生理狀態紀錄
          </div>
        </div>
        <BodyRecordsTable
          :records.sync="bodyRecords"
          v-show="activeKey == '1'"
        />
        <DietTable :records.sync="dietRecords" v-show="activeKey == '2'" />
        <LifeRecordTable
          :records.sync="lifeRecords"
          v-show="activeKey == '3'"
        />
        <div class="pagination-wrap">
          <a-pagination
            v-model="currentPage"
            :defaultCurrent="1"
            :pageSize="perPage"
            :total="totalPage"
            @change="changePage"
            show-less-items
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between" v-if="!visitor">
      <a-button
        class="m-3 w-40 h-10"
        @click="disableUserDialog = true"
        :disabled="visitor"
        :style="visitor ? 'visibility:hidden' : 'visibility:visible'"
        v-if="editable && userType == 'disable'"
      >
        啟用帳號
      </a-button>
      <a-button class="m-3 w-40 h-10" @click="deleteUserDialog = true" v-if="editable  && userType == 'enable'">
        停用帳號
      </a-button>
    </div>
    <a-modal
      v-model="disableUserDialog"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要啟用此帳號？</h4>
      <div class="mt-5">啟用後此用戶可再次使用此帳號登入</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="disableUserDialog = false"
          >取消</a-button
        >
        <a-button class="w-40 ml-4" @click="enableUser()" type="primary">
          啟用
        </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model="deleteUserDialog"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要停用此帳號？</h4>
      <div class="mt-5">停用後用戶將無法使用此帳號登入</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="deleteUserDialog = false"
          >取消</a-button
        >
        <a-button class="w-40 ml-4" @click="deleteUser()" type="primary">
          停用
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import {
  updateOne,
  getOne,
  getAllByDate,
  deleteOne,
  recoverUser,
} from "../../service/api.js";
import App from "../../App";
import BodyRecordsTable from "../../components/bodyRecordsTable";
import DietTable from "../../components/dietTable";
import LifeRecordTable from "../../components/lifeRecordTable";
import User from "./user";
import Questionnaire from "./questionnaire";
import CloudShop from "./cloudShop";
import moment from "moment";
import { UserBasicParams } from "../../models/userBasicParams";
import { UserCloudShopParams } from "../../models/userCloudShopParams";
import { HealthParams } from "../../models/healthParams";

@Component({
  name: "UpdateUser",
  components: {
    User,
    DietTable,
    BodyRecordsTable,
    CloudShop,
    Questionnaire,
    LifeRecordTable,
  },
})
export default class UpdateUser extends App {
  toggleDisable = true;
  displayModel = false;
  disableUserDialog = false;
  deleteUserDialog = false;
  userID = "";
  visitorNumber = 0;
  clientNumber = 0;
  activityNumber = 0;
  rangeConfig = {
    rules: [
      {
        type: "array",
        required: true,
        message: "請選擇優惠期限區間",
      },
    ],
  };
  activeKey = "1";
  activeMemberPanelKey = "1";
  startAt = null;
  endAt = null;
  lifeRecords = [];
  dietRecords = [];
  bodyRecords = [];
  user = {};
  userParams = null;
  shopParams = null;
  healthParams = null;
  currentUserInfoType = "basic";
  currentHealthType = "BodyRecords";
  editable = false;
  visitor = false;
  editing = false;
  errorMsg = null;
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  userType = "enable";

  async mounted() {
    await CommonModule.setLoading(true);
    try {
      const queryObj = this.$route.params;
      const vuxUserParams = await CommonModule.getRecordUserParams();
      this.visitor = queryObj.visitor;
      this.userType = queryObj.userType;
      this.changeHealthTab();
      if (Object.keys(queryObj).length != 0 || vuxUserParams != undefined) {
        const params =
          Object.keys(queryObj).length != 0 ? queryObj : vuxUserParams;
        await CommonModule.setRecordUserParams(params);
        this.userID = params.id ? params.id : localStorage.getItem("userId");
        await this.getInfo();
        await this.getList();
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async getInfo() {
    try {
      // 基本資料
      const res = await getOne("users", this.userID);
      const healthRes = await getOne(`users/${this.userID}/health`);
      const basicParams = new UserBasicParams(res);
      const cloudParams = new UserCloudShopParams(res);
      const planParams = new HealthParams(healthRes);
      this.userParams = basicParams.convert();
      this.shopParams = cloudParams.convert();
      this.healthParams = planParams.convert();
      this.userParams.id = this.userID;
      this.visitorNumber = res.guests_count;
      this.clientNumber = res.students_count;
      this.activityNumber = res.activities_count;
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
  }

  async getList(currentPage = 1) {
    if (this.userID == "") return;
    await CommonModule.setLoading(true);
    try {
      let res = null;
      this.lifeRecords = [];
      this.bodyRecords = [];
      this.dietRecords = [];
      if (this.currentHealthType == "LifeRecords") {
        res = await getAllByDate(
          `users/${this.userID}/life-records`,
          this.startAt,
          this.endAt,
          currentPage
        );
        this.lifeRecords = res.data;
      } else if (this.currentHealthType == "BodyRecords") {
        res = await getAllByDate(
          `users/${this.userID}/body-records`,
          this.startAt,
          this.endAt,
          currentPage
        );
        this.bodyRecords = res.data;
      } else {
        res = await getAllByDate(
          `users/${this.userID}/diet-records`,
          this.startAt,
          this.endAt,
          currentPage
        );
        this.dietRecords = res.data;
      }
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async enableUser() {
    await CommonModule.setLoading(true);
    try {
      this.disableUserDialog = false;
      await recoverUser(this.userID);
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async deleteUser() {
    await CommonModule.setLoading(true);
    try {
      this.deleteUserDialog = false;
      await deleteOne("users", this.userID);
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  sumbit() {
    // 密碼未被修正過則不送password
    if (!this.userParams.password_change) {
      delete this.userParams["password"];
    }

    if (this.userParams.country_code == "") {
      this.$message.info("請選擇國家");
      return;
    }
    if (this.userParams.birthday == "") {
      this.$message.info("請輸入生日");
      return;
    }
    if (this.userParams.height == "") {
      this.$message.info("請輸入身高");
      return;
    }

    if (
      this.healthParams.personal_medical_history &&
      this.healthParams.ns_map["PERSONAL_MEDICAL_HISTORY"] == ""
    ) {
      this.$message.info("請輸入疾病史");
      return;
    }
    if (
      this.healthParams.family_medical_history &&
      this.healthParams.ns_map["FAMILY_MEDICAL_HISTORY"] == ""
    ) {
      this.$message.info("請輸入家族病史");
      return;
    }
    if (
      this.healthParams.prescription_medicine &&
      this.healthParams.ns_map["PRESCRIPTION_MEDICINE"] == ""
    ) {
      this.$message.info("請輸入用藥狀況");
      return;
    }

    if (
      (this.healthParams.status == "progress" &&
        (this.healthParams.plan == "pending" ||
          this.healthParams.plan == "pause" ||
          this.healthParams.plan == "graduated")) ||
      (this.healthParams.status == "pause" &&
        this.healthParams.plan != "pause") ||
      (this.healthParams.status == "graduated" &&
        this.healthParams.plan != "graduated")
    ) {
      this.$message.info("減脂狀態錯誤，請重新選擇");
      return;
    }

    // if (this.currentUserInfoType == "basic") {
    this.updateUserInfo();
    // 若fat_loss_plan為null，不用打health  ref: 減脂計畫的 target_weight 欄位應改為非必填、或有預設值
    if (this.userParams.fat_loss_plan != null) {
      this.updateHealth();
    }
  }

  async updateHealth() {
    const params = {
      target_weight: this.healthParams.target_weight,
      personal_medical_history: !this.healthParams.personal_medical_history
        ? ""
        : this.healthParams.ns_map["PERSONAL_MEDICAL_HISTORY"],
      family_medical_history: !this.healthParams.family_medical_history
        ? ""
        : this.healthParams.ns_map["FAMILY_MEDICAL_HISTORY"],
      prescription_medicine: !this.healthParams.prescription_medicine
        ? ""
        : this.healthParams.ns_map["PRESCRIPTION_MEDICINE"],
      absolute_contraindications: this.healthParams.absolute_contraindications,
      relative_contraindications: this.healthParams.relative_contraindications,
      fat_loss_plan: {
        status: this.healthParams.status,
        plan: this.healthParams.plan,
      },
    };
    if (
      (this.healthParams.status == "pending" &&
        this.healthParams.plan == "pending") ||
      (this.healthParams.status == "" && this.healthParams.plan == "")
    ) {
      delete params.fat_loss_plan;
    }
    await CommonModule.setLoading(true);
    try {
      await updateOne(`users/${this.userID}/health`, "", params);
      this.editable = false;
      this.editing = false;
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async updateUserInfo() {
    const params = JSON.parse(JSON.stringify(this.userParams));
    if (!params.password_change) {
      delete params.password;
    }
    if (!params.username_change) {
      delete params.username;
    }
    await CommonModule.setLoading(true);
    try {
      await updateOne("users", this.userID, params);
      this.userParams.updated_at = moment();
      this.userParams.password_change = false;
      this.userParams.username_change = false;
      this.editable = false;
      this.editing = false;
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  clearSearch() {
    this.startAt = null;
    this.endAt = null;
    this.getList();
  }

  changeHealthTab(key = "1") {
    this.activeKey = key;
    if (key == "1") {
      this.currentHealthType = "BodyRecords";
    } else if (key == "2") {
      this.currentHealthType = "DietRecords";
    } else {
      this.currentHealthType = "LifeRecords";
    }
    this.getList();
  }

  changeUserInfoTab(key = "1") {
    this.activeMemberPanelKey = key;
    // this.editing = false;
    // this.editable = false;
    if (key == "1") {
      this.currentUserInfoType = "basic";
    } else if (key == "2") {
      this.currentUserInfoType = "question";
    } else {
      this.currentUserInfoType = "shop";
    }
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  onChangeDate(value, dateString) {
    this.startAt = dateString[0];
    this.endAt = dateString[1];
  }

  getUserParams(params) {
    this.userParams = params;
  }

  getHealthParams(params) {
    this.healthParams = params;
  }

  gotoPage(type) {
    let target = "";
    if (type == "visitors") {
      target = "UserVisitors";
    } else if (type == "students") {
      target = "UserStudents";
    } else {
      target = "UserActivities";
    }
    this.$router.push({
      name: target,
      params: { userParams: this.userParams },
    });
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss">
</style>
