import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({
  name: 'authentication',
})
export default class Authentication extends VuexModule {
  authRes = {};
  sideBarRes = {};
  sideBarKey = "s";
  storeKey = "a"
  @Action()
  decodeAuth() {
    let r = null;
    if (process.browser) {
      r = atob(localStorage.getItem(this.storeKey));
    } else {
      r = atob(this.authRes)
    }
    return JSON.parse(r)
  }

  @Mutation
  updateAuth(val) {
    val = btoa(JSON.stringify(val));
    if (process.browser) {
      localStorage.setItem(this.storeKey, val);
    }
    this.authRes = val;
  }

  @Action()
  async getToken() {
    const obj = await this.decodeAuth();
    return obj['api_token'];
  }

  @Action
  async getName() {
    const obj = await this.decodeAuth();
    return obj['username'];
  }

  @Action
  async getCountryCode() {
    const obj = await this.decodeAuth();
    return obj['country_code'];
  }

  @Action
  async getUsername() {
    const obj = await this.decodeAuth();
    return obj['username'];
  }

  @Action
  async getGender() {
    const obj = await this.decodeAuth();
    return obj['gender'];
  }

  @Action
  async getID() {
    const obj = await this.decodeAuth();
    return obj['id'];
  }

  @Action
  async getRole() {
    const obj = await this.decodeAuth();
    return obj['role'];
  }

  @Action
  setAuth(res) {
    try {
      this.updateAuth(res);
    } catch (error) {
      console.log("error res=" + error)
    }
  }

  @Action
  removeAuth() {
    localStorage.removeItem(this.storeKey)
    localStorage.removeItem(this.sideBarKey)
  }


  @Action()
  decodeSideBar() {
    let r = null;
    if (process.browser) {
      r = atob(localStorage.getItem(this.sideBarKey));
    } else {
      r = atob(this.sideBarRes)
    }
    return JSON.parse(r)
  }

  @Mutation
  updateSideBar(val) {
    val = btoa(JSON.stringify(val));
    if (process.browser) {
      localStorage.setItem(this.sideBarKey, val);
    }
    this.sideBarRes = val;
  }

  @Action
  setSideBar(sideBar) {
    this.updateSideBar(sideBar);
  }

  @Action
  async getSideBar() {
    return await this.decodeSideBar();
  }
}