<template>
  <div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>訂單紀錄 ({{ orderNum }})</div>
        <div>
          <a-button type="link" @click="exportData()">
            匯出所有名單
          </a-button>
          <a-button type="link" @click="chooseFile()"> 上傳組別資料 </a-button>
          <input
            ref="referenceGroupUpload"
            type="file"
            style="display: none"
            id="getGroupFile"
            @change="uploadCsv"
            accept=".csv"
          />
          <a-button type="link" @click="registeredOfflineTicketModel = true">
            現場報名
          </a-button>
        </div>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-col mb-2">
        <div class="flex flex-row justify-start mt-3 mb-3">
          <a-input placeholder="會員ID" class="mr-3" v-model="userID" />
          <a-input placeholder="訂單" class="mr-3" v-model="orderId" />
          <a-input placeholder="姓名" class="mr-3" v-model="nickname" />
          <a-input placeholder="手機" class="mr-3" v-model="phone" />
          <a-input placeholder="組別" class="mr-3" v-model="group" />
        </div>
        <div class="flex flex-row justify-start mb-3 mt-3">
          <a-button type="primary" class="mr-3 w-28" @click="getList()">
            搜尋
          </a-button>
          <a-button
            type="link"
            class="mr-3 w-28"
            @click="clearSearchCondition()"
            >清空條件</a-button
          >
        </div>
      </div>
      <div class="app-tab-wrap" type="ooo" id="myInput">
        <div
          class="app-tab-item"
          @click="changeTab('1')"
          :class="
            activeKey == '1' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          已付款 ({{ registeredCount }})
        </div>
        <div
          class="app-tab-item"
          @click="changeTab('2')"
          :class="
            activeKey == '2' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          未付款 ({{ pendingCount }})
        </div>
        <div
          class="app-tab-item"
          @click="changeTab('3')"
          :class="
            activeKey == '3' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          已退票 ({{ refundCount }})
        </div>
        <div
          class="app-tab-item"
          @click="changeTab('4')"
          :class="
            activeKey == '4' ? 'app-tab-item-active' : 'app-tab-item-default'
          "
        >
          現場報名 ({{ registeredOfflineCount }})
        </div>
      </div>
      <OfficialOrderTable
        :records.sync="registered"
        :orderType.sync="currentType"
        v-show="activeKey == '1'"
      />
      <OfficialOrderTable
        :records.sync="pending"
        :orderType.sync="currentType"
        v-show="activeKey == '2'"
      />
      <OfficialOrderTable
        :records.sync="refund"
        :orderType.sync="currentType"
        v-show="activeKey == '3'"
      />
      <OfficialOrderTable
        :records.sync="registeredOffline"
        :orderType.sync="currentType"
        v-show="activeKey == '4'"
      />
      <div class="pagination-wrap">
        <a-pagination
          v-model="currentPage"
          :defaultCurrent="1"
          :pageSize="perPage"
          :total="totalPage"
          @change="changePage"
          show-less-items
        />
      </div>
      <a-modal
        v-model="displayModel"
        class="costom-model"
        :centered="true"
        :cancelButtonProps="{ style: { display: 'none' } }"
        :footer="null"
        :destroyOnClose="true"
        :maskClosable="false"
      >
        <div
          class="text-center"
          style="padding: 20px"
          v-if="fileUploadResult == null"
        >
          <div class="text-lg"><b>資料更新中...</b></div>
        </div>
        <div class="" style="padding: 20px" v-else>
          <div class="text-lg text-center"><b>更新完成</b></div>
        </div>
      </a-modal>
      <a-modal
        v-model="registeredOfflineTicketModel"
        class="costom-model"
        :centered="true"
        :cancelButtonProps="{ style: { display: 'none' } }"
        :footer="null"
        :destroyOnClose="true"
        :maskClosable="false"
      >
        <h4>現場報名</h4>
        <div class="h-line"></div>
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-form-item label="會員ID">
            <a-input
              v-model="registeredOfflineTicket.user_id"
              placeholder="請輸入會員ID"
            />
          </a-form-item>
          <a-form-item label="姓名">
            <a-input
              v-model="registeredOfflineTicket.name"
              placeholder="請輸入姓名"
            />
          </a-form-item>
          <a-form-item label="Email">
            <a-input
              v-model="registeredOfflineTicket.email"
              placeholder="請輸入Email"
            />
          </a-form-item>
          <a-form-item label="手機">
            <a-input
              v-model="registeredOfflineTicket.phone"
              placeholder="請輸入手機"
              type="number"
            />
          </a-form-item>
          <a-form-item label="地址">
            <a-input
              v-model="registeredOfflineTicket.address"
              placeholder="請輸入地址"
            />
          </a-form-item>
          <a-form-item label="地區">
            <a-select
              class="w-full"
              v-model="registeredOfflineTicket.region"
              placeholder="請選擇地區"
            >
              <a-select-option value="north"> 北 </a-select-option>
              <a-select-option value="center"> 中 </a-select-option>
              <a-select-option value="south"> 南 </a-select-option>
              <a-select-option value="east"> 東 </a-select-option>
              <a-select-option value="oversea"> 海外 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="票種">
            <a-select
              class="w-full"
              v-model="registeredOfflineTicket.ticket_id"
              placeholder="請選擇票種"
            >
              <a-select-option
                :value="ticket.id"
                v-for="(ticket, index) in tickets"
                :key="index"
              >
                {{ ticket.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="餐點" v-if="checkTicketContent('food')">
            <a-radio-group
              v-decorator="['radio-group']"
              v-model="registeredOfflineTicket.meal"
            >
              <a-radio value="vegetable"> 素食 </a-radio>
              <a-radio value="carnivore"> 葷食 </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="同房者姓名" v-if="checkTicketContent('bed')">
            <a-input
              v-model="registeredOfflineTicket.roommate"
              placeholder="請輸入同房者姓名"
            />
          </a-form-item>
          <a-form-item label="組別">
            <a-input
              v-model="registeredOfflineTicket.group"
              placeholder="請輸入組別"
            />
          </a-form-item>
          <a-form-item label="備註">
            <a-input
              v-model="registeredOfflineTicket.remark"
              placeholder="請輸入備註"
            />
          </a-form-item>
        </a-form>
        <div class="text-center mt-6">
          <a-button
            class="w-40 ml-4"
            @click="registeredOfflineTicketModel = false"
            >取消</a-button
          >
          <a-button
            class="w-40 ml-4"
            @click="submit()"
            :disabled="
              registeredOfflineTicket.group == '' ||
              (registeredOfflineTicket.meal == '' &&
                checkTicketContent('food')) ||
              (registeredOfflineTicket.roommate == '' &&
                checkTicketContent('bed')) ||
              registeredOfflineTicket.phone == '' ||
              registeredOfflineTicket.ticket_id == undefined ||
              registeredOfflineTicket.region == undefined ||
              registeredOfflineTicket.address == '' ||
              registeredOfflineTicket.name == '' ||
              registeredOfflineTicket.userID == '' ||
              registeredOfflineTicket.email == ''
            "
            type="primary"
          >
            報名
          </a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../../store/index.js";
import {
  getUsers,
  getActivityOrder,
  changeGroups,
  createOne,
  getOne,
  getUserDetail,
  exportData,
} from "../../../service/api.js";
import OfficialOrderTable from "../../../components/officialOrderTable";
import App from "../../../App";

@Component({
  name: "OfficialOrders",
  components: {
    OfficialOrderTable,
  },
})
export default class OfficialOrders extends App {
  @Prop() tickets;
  @Prop() orderType;

  ticketsList = [];
  activity = {};
  pendingCount = 0;
  refundCount = 0;
  registeredCount = 0;
  registeredOfflineCount = 0;
  orderNum = 0;
  @Watch("tickets")
  updateTickets() {
    this.ticketList = this.tickets;
  }

  registered = [];
  pending = [];
  refund = [];
  registeredOffline = [];
  registeredOfflineTicketModel = false;
  condition = null;
  displayModel = false;
  role = null;
  userID = "";
  nickname = "";
  group = "";
  phone = "";
  activeKey = "1";
  searchType = null;
  currentType = "registered";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  fileUploadResult = null;
  registeredOfflineTicket = {
    activity_id: "",
    ticket_id: undefined,
    user_id: "",
    name: "",
    email: "",
    phone: "",
    meal: "",
    roommate: "",
    region: undefined,
    group: "",
    remark: "",
  };
  orderId = "";
  ticketId = "";

  async mounted() {
    this.searchType = undefined;

    try {
      const queryObj = this.$route.params;
      const vuxActivityParams = await CommonModule.getActivityParams();
      if (Object.keys(queryObj).length != 0 || vuxActivityParams != undefined) {
        const params =
          Object.keys(queryObj).length != 0 ? queryObj : vuxActivityParams;
        await CommonModule.setLoading(true);
        const res = await getOne("activities", params.id);
        this.activity = res;
        await CommonModule.setActivityParams(res);
        this.registeredOfflineTicket.activity_id = this.activity.id;
        this.getOrderSum();
        this.getList();
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.registered = [];
      this.pending = [];
      this.refund = [];
      this.registeredOffline = [];
      const res = await getActivityOrder(
        this.orderId,
        this.activity.id,
        this.ticketId,
        this.userID,
        this.nickname,
        this.phone,
        this.group,
        this.currentType,
        currentPage
      );
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;

      if (this.currentType == "registered") {
        this.registered = res.data;
      } else if (this.currentType == "pending") {
        this.pending = res.data;
      } else if (this.currentType == "refund") {
        this.refund = res.data;
      } else {
        this.registeredOffline = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async submit() {
    try {
      if (this.registeredOfflineTicket.email.indexOf("@") == -1) {
        this.$message.info("Email格式錯誤請重新輸入");
        return;
      }
      await CommonModule.setLoading(true);
      const isUserExist = await getUserDetail(
        this.registeredOfflineTicket.user_id
      );
      if (isUserExist.data.length == 0) {
        this.$message.info("用戶不存在，請重新輸入用戶ID");
        await CommonModule.setLoading(false);
        return;
      }
      await createOne("activity-orders", this.registeredOfflineTicket);
      this.registeredOfflineTicket = {
        activity_id: this.activity.id,
        ticket_id: undefined,
        user_id: "",
        name: "",
        email: "",
        phone: "",
        meal: "",
        roommate: "",
        region: undefined,
        group: "",
        remark: "",
      };
      this.registeredOfflineTicketModel = false;
      const res = await getOne("activities", this.activity.id);
      this.activity = res;
      await CommonModule.setActivityParams(res);
      this.registeredOfflineTicket.activity_id = this.activity.id;
      this.changeTab(4);
      this.getOrderSum();
    } catch (error) {
      console.log(error);
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  getOrderSum() {
    this.pendingCount = this.activity.order_pending_count;
    this.refundCount = this.activity.order_refund_count;
    this.registeredCount = this.activity.order_registered_count;
    this.registeredOfflineCount = this.activity.order_registered_offline_count;
    if (!this.pendingCount) {
      this.pendingCount = 0;
    }
    if (!this.refundCount) {
      this.refundCount = 0;
    }
    if (!this.registeredCount) {
      this.registeredCount = 0;
    }
    if (!this.registeredOfflineCount) {
      this.registeredOfflineCount = 0;
    }
    this.orderNum =
      this.pendingCount +
      this.refundCount +
      this.registeredCount +
      this.registeredOfflineCount;
  }

  chooseFile() {
    document.getElementById("getGroupFile").click();
  }

  async exportData() {
    try {
      await CommonModule.setLoading(true);
      const res = await exportData('activities', this.activity.id);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "活動ID_" + this.activity.id + "_名單.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
        console.log(error);
        this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  checkTicketContent(type) {
    const t = this.registeredOfflineTicket;
    const currentTicket = this.tickets.find((el) => {
      return el.id == t.ticket_id;
    });
    if (!currentTicket) return;
    if (type == "food") {
      if (currentTicket.has_food == 1) {
        return true;
      }
    } else {
      if (currentTicket.has_bed == 1) {
        return true;
      }
    }
    return false;
  }

  async uploadCsv(event) {
    this.displayModel = true;
    this.fileUploadResult = null;
    try {
      let res = await changeGroups(this.activity.id, event.target.files);
      this.fileUploadResult = res;
      this.changeTab(1);
      this.getOrderSum();
    } catch (error) {
      console.log(error);
      this.displayModel = false;
      this.$message.info(error.message);
    }
  }

  changeTab(key = "1") {
    this.activeKey = key;
    if (key == "1") {
      this.currentType = "registered";
    } else if (key == "2") {
      this.currentType = "pending";
    } else if (key == "3") {
      this.currentType = "refund";
    } else {
      this.currentType = "registered-offline";
    }
    this.getList(this.currentPage);
  }

  clearSearchCondition() {
    this.phone = "";
    this.nickname = "";
    this.userID = "";
    this.group = "";
    this.orderId = "";
    this.searchType = undefined;
    this.getList();
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
