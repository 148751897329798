import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

@Module({
  name: "common",
})
export default class Common extends VuexModule {
  language = "zh_TW";
  recordActivityParams = {};
  recordUserParams = {};
  recordStudentParams = {};
  recordVisitorParams = {};
  recordActivityParams = {};
  customer = "";
  collapsed = false;
  loading = false;
  errorLog = false;
  breadcrumb = [];

  @Mutation
  updateBreadcrumb(breadcrumb) {
    this.breadcrumb = breadcrumb;
  }

  @Action
  setBreadcrumb(breadcrumb) {
    this.updateBreadcrumb(breadcrumb);
  }

  @Action
  getBreadcrumb() {
    return this.breadcrumb;
  }

  @Mutation
  updateErrorlog(isError) {
    this.errorLog = isError;
  }

  @Action
  setErrorLog(isError) {
    this.updateErrorlog(isError);
  }

  @Action
  getErrorLog() {
    return this.errorLog;
  }

  @Mutation
  updateActivityParams(recordActivityParams) {
    this.recordActivityParams = recordActivityParams;
  }

  @Action
  setActivityParams(recordActivityParams) {
    this.updateActivityParams(recordActivityParams);
  }

  @Action
  getActivityParams() {
    return this.recordActivityParams;
  }

  @Mutation
  updateRecordUserParams(recordUserParams) {
    this.recordUserParams = recordUserParams;
  }

  @Action
  setRecordUserParams(recordUserParams) {
    this.updateRecordUserParams(recordUserParams);
  }

  @Action
  getRecordUserParams() {
    return this.recordUserParams;
  }

  @Action
  getRecordVisitorParams() {
    return this.recordVisitorParams;
  }

  @Mutation
  updateRecordVisitorParams(recordVisitorParams) {
    this.recordVisitorParams = recordVisitorParams;
  }

  @Action
  setRecordVisitorParams(recordVisitorParams) {
    this.updateRecordVisitorParams(recordVisitorParams);
  }

  @Action
  getRecordStudentParams() {
    return this.recordStudentParams;
  }

  @Mutation
  updateRecordStudentParams(recordStudentParams) {
    this.recordStudentParams = recordStudentParams;
  }

  @Action
  setRecordStudentParams(recordStudentParams) {
    this.updateRecordStudentParams(recordStudentParams);
  }

  @Action
  getRecordActivityParams() {
    return this.recordActivityParams;
  }

  @Mutation
  updateRecordActivityParams(recordActivityParams) {
    this.recordActivityParams = recordActivityParams;
  }

  @Action
  setRecordActivityParams(recordActivityParams) {
    this.updateRecordActivityParams(recordActivityParams);
  }

  @Mutation
  updateLoding(loading) {
    this.loading = loading;
  }

  @Action
  setLoading(loading) {
    this.updateLoding(loading);
  }

  @Action
  getLoading() {
    return this.loading;
  }

  @Mutation
  updateCollapsed(collapsed) {
    this.collapsed = collapsed;
  }

  @Action
  setCollapsed(collapsed) {
    this.updateCollapsed(collapsed);
  }

  @Action
  getCollapsed() {
    return this.collapsed;
  }

  @Mutation
  updateLanguage(language) {
    this.language = language;
  }

  @Action
  setLanguage(language) {
    if (process.browser) {
      localStorage.setItem("language", language);
    }
    this.updateLanguage(language);
  }

  @Action
  getLanguage() {
    return this.language;
  }

  @Mutation
  updateCustomer(name) {
    if (name) {
      this.customer = name;
    }
  }

  @Action
  setCustomer(name) {
    this.updateCustomer(name);
  }

  @Action
  getCustomer() {
    return this.customer;
  }

}
