<template>
<a-layout-sider v-model="collapsed" :trigger="null" >
  <div class="logo text-gray-500  font-bold" @click="$router.push('/dashboard')">
    <span v-if="!collapsed">
      {{dashboardTitle}}
    </span>
    <a-icon v-else type="home" />
  </div>
  <a-menu theme="light" mode="inline" :openKeys.sync="openKeys">
    <a-sub-menu key="account_management" v-if="sideBar['/ADMIN/DASHBOARD/AUTH']">
      <span slot="title">
        <a-icon type="team" /><span>帳號管理</span>
      </span>
      <a-menu-item key="users" @click="gotoPage('/users')">
        <router-link to="/users">
          <a-icon type="user" />
          會員管理</router-link>
      </a-menu-item>
      <a-menu-item key="coaches" @click="gotoPage('/coaches')">
        <router-link to="/coaches">
          <a-icon type="trophy" />
          更換教練審核</router-link>
      </a-menu-item>
      <a-menu-item key="administrators" @click="gotoPage('/administrators')">
        <router-link to="/administrators">
          <a-icon type="audit" />
          管理員管理</router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item key="feedback" @click="$router.push('/feedback')" v-if="sideBar['/ADMIN/DASHBOARD/FEEDBACK']">
      <a-icon type="mail" />
      <span>意見回饋</span>
    </a-menu-item>
    <a-menu-item key="activities" @click="$router.push('/activities')"  v-if="sideBar['/ADMIN/DASHBOARD/ACTIVITY']">
      <a-icon type="contacts" />
      <span>活動管理</span>
    </a-menu-item>
    <a-menu-item key="articles" @click="$router.push('/articles')" v-if="sideBar['/ADMIN/DASHBOARD/ARTICLE']">
      <a-icon type="read" />
      <span>文章管理</span>
    </a-menu-item>
    <a-menu-item key="notifications" @click="$router.push('/notifications')" v-if="sideBar['/ADMIN/DASHBOARD/NOTIFICATION']">
      <a-icon type="notification" />
      <span>通知管理</span>
    </a-menu-item>
    <a-sub-menu key="settings_management" v-if="sideBar['/ADMIN/DASHBOARD/SETTING']">
      <span slot="title">
        <a-icon type="setting" /><span>系統設定</span>
      </span>
      <!-- <a-menu-item key="ingredients" @click="gotoPage('/ingredients')">
        <router-link to="/ingredients">
          <a-icon type="database" />
          食物資料庫</router-link>
      </a-menu-item> -->
      <a-menu-item key="scales" @click="gotoPage('/scales')">
        <router-link to="/scales">
          <a-icon type="line-chart" />
          上秤指標管理</router-link>
      </a-menu-item>
      <a-menu-item key="qa" @click="gotoPage('/qa')">
        <router-link to="/qa">
          <a-icon type="solution" />
          常見問答設定</router-link>
      </a-menu-item>
      <a-menu-item key="terms" @click="gotoPage('/terms')">
        <router-link to="/terms">
          <a-icon type="file-protect" />
          服務條款設定</router-link>
      </a-menu-item>
      <a-menu-item key="contraindications" @click="gotoPage('/contraindications')">
        <router-link to="/contraindications">
          <a-icon type="unordered-list" />
          禁忌症設定</router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</a-layout-sider>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";

@Component
export default class Side extends Vue {
  collapsed = false;
  sideBar = [];
  dashboardTitle = "WOW Admin";
  openKeys = [];

  async mounted() {
    const store = Store();
    store.watch(
      state => state.common.collapsed,
      val => {
        this.collapsed = val;
      }
    );
    store.watch(
      state => state.authentication.sideBarRes,
      async val => {
        this.sideBar = await AuthenticationModule.getSideBar();
      }
    );
    if (
      localStorage.getItem("a") != null &&
      localStorage.getItem("s") != null
    ) {
      this.collapsed = await CommonModule.getCollapsed();
      this.sideBar = await AuthenticationModule.getSideBar();
    }
  }

  gotoPage(path) {
    this.$router.push(path).catch(err => {});
  }

  changeOpenKey(key) {
    this.openKey = [key];
  }
}
</script>

<style>
.ant-layout-sider {
  min-height: 100vh;
  background: #fff !important;
}

.menu-icons {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.logo {
  cursor: pointer;
  height: 32px;
  margin: 16px;
  color: #888;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 18px;
}

.side-datetime-wrap {
  display: flex;
  justify-content: space-between;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 3px solid #005eb8 !important;
  border-right: none;
  content: "";
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: #687c97 !important;
  font-weight: bold;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #005eb8 !important;
}

.horizontal-line {
  height: 1px;
  margin: 10px 0;
  width: 100%;
  display: inline-block;
  background-color: #ddd !important;
}

.horizontal-line > .ant-menu-submenu-title {
  display: none;
}
</style>
