export class BodyLevel {
  constructor(language) {
    this.language = language
  }
  language = "";
  level_title = "";
  level_description = "";
  key_title = "";
  key_description = "";

  convert() {
    return JSON.parse(JSON.stringify(this));
  }
}