import http from "http";
import https from "https";
import axios from "axios";
import { AuthenticationModule } from "../store/index.js";

export const Config = {
  baseURL: process.env.VUE_APP_URL_REST_API,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 1000000,
  withCredentials: true,
  responseType: "json",
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  httpAgent: new http.Agent({
    keepAlive: true,
  }),
  httpsAgent: new https.Agent({
    keepAlive: true,
  }),
};

const service = axios.create({
  baseURL: Config.baseURL,
  timeout: Config.timeout,
  headers: Config.headers,
  responseType: Config.responseType,
});

service.interceptors.request.use(
  (config) => {
    if (process.browser) {
      let token = null;
      const WOWAuthRes = localStorage.getItem('a');
      if (WOWAuthRes) {
        const decodeAuth = atob(WOWAuthRes)
        const object = JSON.parse(decodeAuth);
        token = object['api_token']
      }
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    if (res.status === 200 || res.status === 201) {
      // delete action
      if (res.data == null) {
        return true;
      }
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.message);
    }
  },
  async (error) => {
    if ((error.response.status == 401 && error.response.data.message.indexOf("Unauthenticated.") != -1)) {
      AuthenticationModule.removeAuth();
      window.location.href = "/";
    }
    return Promise.reject(error.response.data);
  }
);

export default service;
