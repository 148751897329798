<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2">
    管理員管理
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
      <div>管理員名單</div>
      <a-button type="link" @click="$router.push('/administrators/update')">
        + 新增管理員
      </a-button>
    </div>
    <div class="h-line"></div>
    <div class="app-table ">
      <div class="app-tr rule-tr app-bb app-table-header">
        <div class="app-th app-w-20">帳號</div>
        <div class="app-th app-w-20">暱稱</div>
        <div class="app-th app-w-50">權限</div>
        <div class="app-th justify-center app-w-10">查看</div>
      </div>
      <div v-if="recordList.length != 0">
        <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
          <div class="app-td app-w-20">{{record.username}}</div>
          <div class="app-td app-w-20">{{!record&&!record.profile ? record.profile.nickname:"-"}}
          </div>
          <div class="app-td app-w-50">{{getPermissions(record.user_tags)}}</div>
          <div class="app-td justify-center app-w-10">
            <a-button type="link" @click="gotoPage(record)">
              查看
            </a-button>
          </div>
        </div>
      </div>
      <div>
        <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
      </div>
      <div class="pagination-wrap">
        <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getAdministrators } from "../../service/api.js";
import App from "../../App";

@Component({
  name: "Administrators",
  components: {}
})
export default class Administrators extends App {
  recordList = [];
  disableUsers = [];
  displayModel = false;

  currentPage = 1;
  perPage = 15;
  totalPage = 0;

  async mounted() {
    try {
      const queryObj = this.$route.params;
      this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.recordList = [];
      const res = await getAdministrators(this.currentStoreId, currentPage);
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      this.recordList = res.data;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  gotoPage(record) {
    this.$router.push({
      name: "UpdateAdministrator",
      params: record
    });
  }

  getPermissions(tags) {
    let result = "";
    if (tags == undefined) {
      return;
    }
    // - 帳號管理 - /ADMIN/DASHBOARD/AUTH
    // - 意見回饋 - /ADMIN/DASHBOARD/FEEDBACK
    // - 活動 - /ADMIN/DASHBOARD/ACTIVITY
    // - 文章管理 - /ADMIN/DASHBOARD/ARTICLE
    // - 通知管理 - /ADMIN/DASHBOARD/NOTIFICATION
    // - 系統設定 - /ADMIN/DASHBOARD/SETTING
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      if (tag.ns.indexOf("AUTH") != -1) {
        result += " 帳號管理,";
      } else if (tag.ns.indexOf("FEEDBACK") != -1) {
        result += " 意見回饋,";
      } else if (tag.ns.indexOf("ACTIVITY") != -1) {
        result += " 活動管理,";
      } else if (tag.ns.indexOf("ARTICLE") != -1) {
        result += " 文章管理,";
      } else if (tag.ns.indexOf("NOTIFICATION") != -1) {
        result += " 通知管理,";
      } else if (tag.ns.indexOf("SETTING") != -1) {
        result += " 系統設定,";
      }
    }
    return result;
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
