<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2">
    禁忌症設定
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>禁忌症列表</div>
        <a-button type="link" @click="displayModel=true;currentRecord = null">
          + 新增禁忌症
        </a-button>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row mb-2">
       <a-select placeholder="請選擇分類" class="mr-3 w-60" v-model="contraindicationType" @change="getList()">
          <a-select-option value="" >
            全部禁忌症
          </a-select-option>
          <a-select-option value="relative">
            相對禁忌症
          </a-select-option>
          <a-select-option value="absolute">
            絕對禁忌症
          </a-select-option>
        </a-select>
    </div>
    <div class="app-table">
      <div class="app-tr rule-tr app-bb app-table-header">
        <div class="app-th">分類</div>
        <div class="app-th app-w-70">禁忌症名稱</div>
        <div class="app-th justify-center">刪除</div>
        <div class="app-th justify-center">編輯</div>
      </div>
      <div v-if="recordList.length != 0">
        <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
          <div class="app-td">
            {{record.type == "relative" ? "相對禁忌症" : "絕對禁忌症"}}
          </div>
          <div class="app-td app-w-70">
            {{record.zh_tw}}
          </div>
          <div class="app-td justify-center">
            <a-button type="link" @click="openDeleteModel(record)">
              刪除
            </a-button>
          </div>
          <div class="app-td justify-center">
            <a-button type="link" @click="openModal(record)">
              編輯
            </a-button>
          </div>
        </div>
        <div>
          <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
        </div>
        <div class="pagination-wrap">
          <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
        </div>
      </div>
    </div>
  </div>
  <a-modal v-model="displayModel" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
    <div style="padding:10px">
      <h4>新增禁忌症</h4>
      <div class="h-line"></div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">分類</div>
        <a-select placeholder="請選擇分類" class="m-3 app-w-70" v-model="updateContraindicationType">
          <a-select-option value="relative">
            相對禁忌症
          </a-select-option>
          <a-select-option value="absolute">
            絕對禁忌症
          </a-select-option>
        </a-select>
      </div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">繁體中文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langTW" />
      </div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">簡體中文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langCN" />
      </div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">英文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langEN" />
      </div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">日文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langJP" />
      </div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="displayModel=false">取消</a-button>
        <a-button class="w-40 ml-4" @click="save();" type="primary" :disabled="langTW==''||langCN==''||langJP==''||langEN==''">
           儲存
        </a-button>
      </div>
    </div>
  </a-modal>
  <a-modal v-if="currentRecord!=null" v-model="deleteContraindicationsDialog" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
    <h4>確定要刪除此項禁忌症？</h4>
    <div class="mt-5">
      刪除後此項禁忌症的資料將從系統中移除
    </div>
    <div class="text-center mt-6">
      <a-button class="w-40 ml-4" @click="deleteContraindicationsDialog=false">取消</a-button>
      <a-button class="w-40 ml-4" @click="deleteContraindications();" type="primary">
        刪除
      </a-button>
    </div>
  </a-modal>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../../store/index.js";
import {
  getAllContraindications,
  deleteOne,
  createOne,
  updateOne
} from "../../../service/api.js";
import App from "../../../App";

@Component({
  name: "Contraindications",
  components: {}
})
export default class Contraindications extends App {
  recordList = [];
  displayModel = false;
  deleteContraindicationsDialog = false;
  category = "";
  langTW = "";
  langCN = "";
  langEN = "";
  langJP = "";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  currentRecord = null;
  updateContraindicationType = "relative";
  contraindicationType = "";
  replyMsg = "";

  async mounted() {
    this.getList();
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      let res = await getAllContraindications(
        this.contraindicationType,
        currentPage
      );
      this.recordList = [];
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      this.recordList = res.data;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  openModal(record) {
    this.displayModel = true;
    this.currentRecord = record;
    this.langTW = this.currentRecord.zh_tw;
    this.langCN = this.currentRecord.zh_cn;
    this.langJP = this.currentRecord.jp;
    this.langEN = this.currentRecord.en;
    this.updateContraindicationType = this.currentRecord.type;
  }

  openDeleteModel(record) {
    this.deleteContraindicationsDialog = true;
    this.currentRecord = record;
  }

  async save() {
    const params = {
      type: this.updateContraindicationType,
      zh_tw: this.langTW,
      zh_cn: this.langCN,
      en: this.langEN,
      jp: this.langJP
    };
    await CommonModule.setLoading(true);
    try {
      if (this.currentRecord == null) {
        await createOne("contraindications", params);
      } else {
        await updateOne("contraindications", this.currentRecord.id, params);
      }
      this.displayModel = false;
      this.getList(this.currentPage);
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async deleteContraindications() {
    await CommonModule.setLoading(true);
    try {
      await deleteOne("contraindications", this.currentRecord.id);
      this.displayModel = false;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.min-70 {
  min-width: 70px;
}
</style>
