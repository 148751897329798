<template>
  <div class="app-table">
    <div class="app-tr rule-tr app-bb app-table-header">
      <div class="app-th app-w-10">會員編號</div>
      <div class="app-th app-w-20">暱稱</div>
      <div class="app-th app-w-10">手機</div>
      <div class="app-th app-w-10 justify-center">所屬教練</div>
      <div class="app-th justify-center">訪客數</div>
      <div class="app-th justify-center">學員數</div>
      <div class="app-th justify-center">報名活動</div>
      <div class="app-th app-w-10 justify-center">查看</div>
    </div>
    <div v-if="recordList.length != 0">
      <div
        class="app-tr rule-tr app-bb"
        v-for="(record, index) in recordList"
        :key="index"
      >
        <div class="app-td app-w-10">{{ record.profile.user_id }}</div>
        <div class="app-td app-w-20">
          {{ record.profile.nickname }}
          <span class="ml-3" v-if="record.role != 'user'">
            <a-badge
              count="教練"
              :number-style="{ backgroundColor: '#52c41a' }"
            />
          </span>
        </div>
        <div class="app-td app-w-10">{{ record.username }}</div>
        <div class="app-td app-w-10 justify-center">
          {{ !record.coach_user_id ? "-" : record.coach_user_id }}
        </div>
        <div
          class="app-td text-blue-500 cursor-pointer justify-center"
          @click="gotoDetailPage('visitors', record)"
        >
          {{ record.guests_count == 0 ? "0" : record.guests_count }}
        </div>
        <div
          class="app-td text-blue-500 cursor-pointer justify-center"
          @click="gotoDetailPage('students', record)"
        >
          {{ record.students_count == 0 ? "0" : record.students_count }}
        </div>
        <div
          class="app-td text-blue-500 cursor-pointer justify-center"
          @click="gotoDetailPage('activities', record)"
        >
          {{ record.activities_count == 0 ? "0" : record.activities_count }}
        </div>
        <div class="app-td app-w-10 justify-center">
          <a-button type="link" @click="gotoPage(record)"> 查看 </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length == 0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getOne } from "../service/api.js";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";
import { UserBasicParams } from "../models/userBasicParams";

@Component
export default class UserTable extends Vue {
  @Prop() records;
  @Prop() userType;
  recordList = [];
  userParams = null;

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  gotoPage(record) {
    record["visitor"] = false;
    record["editable"] = false;
    record["userType"] = this.userType;
    this.$router.push({
      name: "UpdateUser",
      params: record,
    });
  }

  async gotoDetailPage(type, record) {
    await this.getUserParams(record);
    let target = "";
    if (type == "visitors") {
      target = "UserVisitors";
    } else if (type == "students") {
      target = "UserStudents";
    } else {
      target = "UserActivities";
    }

    this.$router.push({
      name: target,
      params: { userParams: this.userParams },
    });
  }

  async getUserParams(record) {
    try {
      const res = await getOne("users", record.id);
      const basicParams = new UserBasicParams(res);
      this.userParams = basicParams.convert();
      this.userParams.id = record.id;
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
  }
}
</script>

<style>
</style>
