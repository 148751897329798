<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div class="app-default-page-title mt-2 flex justify-between">
      {{ !isCreated ? "新增活動" : activityId }}
      <div
        v-if="
          isCreated && currentType == 'official' && activity.status != 'draft'
        "
      >
        <qr-code id="qrCode" style="display: none" :text="qrcode" />
        <input style="display: none" v-model="qrcode" />
        <a-button @click="downloadQrCode()"> 下載簽到 QR code </a-button>
        <a-button type="primary" class="ml-3" @click="gotoSignUpPage()">
          簽到狀態
        </a-button>
      </div>
    </div>
    <div class="app-default-wrap">
      <div class="user-title-wrap">
        <div>活動內容</div>
        <a-button
          type="link"
          @click="editing = true"
          v-if="
            currentType == 'official' &&
            ((isCreated && !editing && activity.status == 'draft') || !expired)
          "
        >
          編輯
        </a-button>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-row justify-between mt-10" v-if="activity != null">
        <div class="w-1/2 mr-5">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="活動名稱">
              <a-input
                v-model="activity.name"
                placeholder="請輸入活動名稱"
                :disabled="!editing"
              />
            </a-form-item>
            <a-form-item label="經銷商編號" v-if="currentType != 'official'">
              <a-input
                v-model="activity.retail"
                placeholder="請輸入經銷商編號"
                :disabled="!editing || currentType == 'unofficial'"
              />
            </a-form-item>
            <a-form-item label="開始時間">
              <a-date-picker
                :disabled-date="disabledDate"
                format="YYYY/MM/DD HH:mm:ss"
                v-model="activity.start_at"
                style="width: 100%"
                placeholder="請選擇開始時間"
                :disabled="!editing"
                @change="startDateChange"
              />
            </a-form-item>
            <a-form-item label="結束時間" v-if="currentType == 'official'">
              <a-date-picker
                :disabled-date="disabledEndDate"
                format="YYYY/MM/DD HH:mm:ss"
                v-model="activity.end_at"
                style="width: 100%"
                placeholder="請選擇結束時間"
                :disabled="!editing"
                @change="endDateChange"
              />
            </a-form-item>
            <a-form-item label="活動形式">
              <a-radio-group
                v-decorator="['radio-group']"
                v-model="activity.type"
                :disabled="!editing"
              >
                <a-radio value="offline" checked> 實體活動 </a-radio>
                <a-radio value="online"> 線上課程 </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-form>
        </div>
        <div class="w-1/2 ml-5">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="宣傳圖" v-if="currentType == 'official'">
              <div class="flex flex-row">
                <div
                  class="flex flex-row text-center"
                  style="
                    width: 150px;
                    height: 150px;
                    border: 1px dotted #ddd;
                    background-repeat: no-repeat;
                    background-size: cover;
                  "
                  :style="`background-image:url('${activity.cover_image_url}')`"
                >
                  <div
                    v-if="activity.cover_image_url == ''"
                    class="w-full text-8xl"
                  >
                    <a-icon type="user" style="color: #ddd" />
                  </div>
                </div>
                <a-button
                  class="ml-2"
                  :disabled="!editing"
                  @click="uploadImg()"
                >
                  <a-icon type="upload" /> 上傳圖片
                </a-button>
                <input
                  ref="referenceUpload"
                  type="file"
                  style="display: none"
                  id="getFile"
                  @change="updateImage"
                  @click="clearVar"
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>
            </a-form-item>
            <div v-else>
              <a-form-item label="舉辦人">
                <a-input
                  :disabled="!editing"
                  class="app-w-90"
                  v-model="activity.nickname"
                  placeholder="請輸入舉辦人"
                />
              </a-form-item>
              <a-form-item label="年分紅級別">
                <a-select
                  style="width: 70px"
                  v-model="activity.level"
                  :disabled="!editing || currentType == 'unofficial'"
                >
                  <a-select-option value="0"> Lv.1 </a-select-option>
                  <a-select-option value="1"> Lv.2 </a-select-option>
                  <a-select-option value="2"> Lv.3 </a-select-option>
                  <a-select-option value="3"> VP </a-select-option>
                  <a-select-option value="4"> P </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="結束時間">
                <a-date-picker
                  :disabled-date="disabledEndDate"
                  format="YYYY/MM/DD HH:mm:ss"
                  v-model="activity.end_at"
                  style="width: 100%"
                  placeholder="請選擇結束時間"
                  :disabled="!editing"
                  @change="endDateChange"
                />
              </a-form-item>
              <a-form-item label="直播連結">
                <a-input
                  type="type"
                  v-model="activity.address"
                  placeholder="請輸入直播連結"
                  :disabled="!editing"
                />
              </a-form-item>
            </div>
          </a-form>
        </div>
      </div>
      <div class="ml-5 mr-5">
        <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
          <a-form-item label="活動地點" v-if="activity.type == 'offline'">
            <a-input
              v-model="activity.address"
              placeholder="請輸入活動地點"
              :disabled="!editing"
            />
          </a-form-item>
          <a-form-item label="直播連結" v-else>
            <a-input
              v-model="activity.address"
              placeholder="請輸入直播連結"
              :disabled="!editing"
            />
          </a-form-item>
          <a-form-item label="活動介紹">
            <a-textarea
              :disabled="!editing"
              :rows="6"
              class="app-w-90"
              v-model="activity.description"
              placeholder="請輸入活動介紹"
            />
          </a-form-item>
          <a-form-item label="注意事項">
            <a-textarea
              :disabled="!editing"
              :rows="6"
              class="app-w-90"
              v-model="activity.remark"
              placeholder="請輸入注意事項"
            />
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="app-default-wrap" style="min-height: 0">
      <div class="user-title-wrap">
        <div>報名設定</div>
      </div>
      <div class="h-line"></div>
      <div class="flex flex-col mt-5">
        <div class="flex flex-row justify-between items-center">
          <div
            class="flex flex-row w-1/2 items-center justify-center"
            v-if="currentType == 'official'"
          >
            <div class="m-3 app-w-20">修改截止日期</div>
            <a-date-picker
              :disabled-date="disabledLastModifyBeforeDate"
              format="YYYY/MM/DD HH:mm:ss"
              v-model="activity.last_modify_before"
              style="width: 100%"
              placeholder="請選擇修改截止日期"
              :disabled="!editing"
              @change="checkRegisterDate"
            />
          </div>
        </div>
        <div>
          <div
            class="
              app-tr
              rule-tr
              app-bb app-table-header
              flex flex-row
              justify-between
            "
          >
            <div class="app-th app-w-30">票種名稱</div>
            <div class="app-th">會員級別</div>
            <div class="app-th">票價</div>
            <div class="app-th">數量</div>
            <div class="app-th app-w-30">開放購票時間</div>
            <div class="app-th justify-center">編輯</div>
            <div class="app-th justify-center">刪除</div>
          </div>
          <div v-if="tickets.length != 0">
            <div
              class="app-tr rule-tr app-bb flex flex-row justify-between"
              v-for="(ticket, index) in tickets"
              :key="index"
            >
              <div class="app-td app-w-30">
                {{ ticket.name }}
                <a-icon class="m-1" type="pie-chart" v-if="ticket.has_food" />
                <a-icon class="m-1" type="home" v-if="ticket.has_bed" />
              </div>
              <div class="app-td">{{ getLevel(ticket.level) }}</div>
              <div class="app-td">
                {{ ticket.priceType == "free" ? "免費" : ticket.price }}
              </div>
              <div class="app-td">
                {{ ticket.amountType == "unlimit" ? "不限" : ticket.threshold }}
              </div>
              <div class="app-td app-w-30">
                {{ ticket.start_at | moment("YYYY/MM/DD HH:mm:ss") }} ~
                {{ ticket.end_at | moment("YYYY/MM/DD HH:mm:ss") }}
              </div>
              <div class="app-td justify-center">
                <a-button type="link" @click="editTicket(ticket, index)">
                  編輯
                </a-button>
              </div>
              <div class="app-td justify-center">
                <a-button
                  type="link"
                  @click="deleteTicket(index)"
                  :disabled="!editing"
                >
                  刪除
                </a-button>
              </div>
            </div>
          </div>
          <div class="app-tr rule-tr app-bb flex flex-row justify-center">
            <div class="app-td justify-center">
              <a-button
                type="link"
                @click="openTicketModel()"
                :disabled="!editing || expired"
              >
                + 新增票種
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OfficialOrders
      :tickets.sync="tickets"
      v-if="
        currentType == 'official' &&
        activity.status != 'draft' &&
        isCreated == true
      "
    />
    <UnofficialOrders
      v-if="
        currentType == 'unofficial' &&
        activity.status != 'draft' &&
        isCreated == true
      "
    />
    <div class="flex flex-row justify-between">
      <div class="flex flex-row" v-if="!expired">
        <a-button class="m-3 w-40 h-10" @click="$router.go(-1)">取消</a-button>
        <a-button
          class="m-3 w-40 h-10"
          @click="submit('draft')"
          :disabled="
            !editing ||
            activity.start_at == '' ||
            activity.end_at == '' ||
            activity.remark == '' ||
            activity.description == '' ||
            activity.cover_image_name == '' ||
            activity.address == '' ||
            activity.last_modify_before == ''
          "
        >
          存為草稿
        </a-button>
        <a-button
          class="m-3 w-40 h-10"
          @click="submit('publishing')"
          type="primary"
          :disabled="
            tickets.length == 0 ||
            !editing ||
            activity.start_at == '' ||
            activity.end_at == '' ||
            activity.remark == '' ||
            activity.description == '' ||
            activity.cover_image_name == '' ||
            activity.address == '' ||
            activity.last_modify_before == ''
          "
        >
          發布
        </a-button>
      </div>
      <a-button
        class="m-3 w-40 h-10"
        v-if="isCreated"
        @click="deleteModel = true"
        :disabled="expired"
      >
        刪除活動
      </a-button>
    </div>
    <a-modal
      v-if="currentTicket != null"
      v-model="ticketModel"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>票種設定</h4>
      <div class="h-line"></div>
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="票種名稱">
          <a-input
            v-model="currentTicket.name"
            placeholder="請輸入票種名稱"
            :disabled="!editing"
          />
        </a-form-item>
        <a-form-item label="開放售票時間(起)">
          <a-date-picker
            :disabled-date="disabledTicketStartDate"
            format="YYYY/MM/DD HH:mm:ss"
            v-model="currentTicket.start_at"
            style="width: 100%"
            placeholder="請選擇開始時間"
            :disabled="!editing"
            @change="ticketStartDateChange"
          />
        </a-form-item>
        <a-form-item label="開放售票時間(訖)">
          <a-date-picker
            :disabled-date="disabledTicketEndDate"
            format="YYYY/MM/DD HH:mm:ss"
            v-model="currentTicket.end_at"
            style="width: 100%"
            placeholder="請選擇結束時間"
            :disabled="!editing"
            @change="ticketEndDateChange"
          />
        </a-form-item>
        <a-form-item label="活動天數" v-if="currentType == 'official'">
          <a-input
            type="number"
            min="1"
            v-model="currentTicket.checkin_threshold"
            placeholder="請輸入活動天數"
            @change="checkTicketPeriod()"
            :disabled="!editing"
          />
        </a-form-item>
        <a-form-item label="數量">
          <a-radio-group
            v-decorator="['radio-group']"
            v-model="currentTicket.amountType"
            :disabled="!editing"
          >
            <a-radio value="unlimit"> 不限 </a-radio>
            <a-radio value="limit"> 限 </a-radio>
          </a-radio-group>
          <a-input
            class="app-w-50 justify-left"
            type="number"
            min="1"
            :disabled="currentTicket.amountType == 'unlimit' || !editing"
            placeholder="請輸入販售票數"
            v-model="currentTicket.threshold"
          />
        </a-form-item>
        <a-form-item label="票價" v-if="currentType == 'official'">
          <a-radio-group
            v-decorator="['radio-group']"
            v-model="currentTicket.priceType"
            :disabled="!editing"
          >
            <a-radio value="free"> 免費 </a-radio>
            <a-radio value="price"> 售 </a-radio>
          </a-radio-group>
          <a-input
            class="app-w-40 justify-left"
            type="number"
            min="1"
            :disabled="currentTicket.priceType == 'free' || !editing"
            placeholder="請輸入售價"
            v-model="currentTicket.price"
          />
          <span class="ml-5">元</span>
        </a-form-item>
        <a-form-item label="購票限制" v-if="currentType == 'official'">
          <a-select
            style="width: 100%"
            v-model="currentTicket.level"
            :disabled="!editing"
          >
            <a-select-option value="-1"> 不限 </a-select-option>
            <a-select-option value="0"> 一般會員或以上 </a-select-option>
            <a-select-option value="1"> 初级或以上 </a-select-option>
            <a-select-option value="2"> 中级或以上 </a-select-option>
            <a-select-option value="3"> 高级或以上 </a-select-option>
            <a-select-option value="4"> 經銷商 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="包含服務" v-if="currentType == 'official'">
          <div class="ml-2 mt-2 flex flex-row justify-start flex-wrap">
            <a-checkbox
              :value="true"
              class="justify-left"
              style="justify-content: left !important"
              v-model="currentTicket.has_food"
              :disabled="!editing"
            >
              此票種提供餐點
            </a-checkbox>
            <a-checkbox
              :value="true"
              class="justify-left"
              style="justify-content: left !important"
              v-model="currentTicket.has_bed"
              :disabled="!editing"
            >
              此票種提供住宿
            </a-checkbox>
          </div>
        </a-form-item>
      </a-form>
      <div class="text-center mt-6">
        <a-button
          class="w-40 ml-4"
          @click="ticketModel = false"
          :disabled="!editing"
          >取消</a-button
        >
        <a-button
          class="w-40 ml-4"
          @click="updateTicket()"
          :disabled="
            !editing ||
            (currentTicket.amountType == 'limit' &&
              currentTicket.threshold == '') ||
            (currentTicket.priceType == 'price' && currentTicket.price == '') ||
            currentTicket.name == '' ||
            currentTicket.datetime == ''
          "
          type="primary"
        >
          儲存
        </a-button>
      </div>
    </a-modal>

    <a-modal
      v-model="deleteModel"
      class="costom-model"
      :centered="true"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :footer="null"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <h4>確定要刪除活動？</h4>
      <div class="mt-5">刪除後資料將從系統中移除</div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="deleteModel = false">取消</a-button>
        <a-button class="w-40 ml-4" @click="deleteActivity()" type="primary">
          刪除
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import {
  fileUpload,
  createOne,
  updateOne,
  getOne,
  deleteOne,
} from "../../service/api.js";
import OfficialOrders from "./officialOrders/index";
import UnofficialOrders from "./unofficialOrders/index";
import App from "../../App";
import moment from "moment";
import { Activity } from "../../models/activity";
import { Ticket } from "../../models/ticket";
import { Order } from "../../models/order";
import Editor from "../../components/editor.vue";
import Authentication from "../../store/authentication.js";

@Component({
  name: "UpdateActivity",
  components: {
    Editor,
    OfficialOrders,
    UnofficialOrders,
  },
})
export default class UpdateActivity extends App {
  deleteModel = false;
  ticketModel = false;
  editing = false;
  expired = false;
  editable = true;
  tickets = [];
  qrcode = "";
  currentTicket = null;
  originalCurrentTicket = null;
  currentTicketIndex = -1;
  currentCategory = "";
  cover = "";
  currentType = "official";
  content = "";
  activityId = "";
  activity = new Activity();
  registeredOfflineTicket = {};
  action = "";
  isCreated = false;
  activityType = "";
  record = {};

  async mounted() {
    try {
      this.initAction();
    } catch (error) {
      console.log(error);
    }
  }

  async initAction() {
    await CommonModule.setLoading(true);
    try {
      const queryObj = this.$route.params;
      this.record = queryObj;
      const vuxActivityParams = await CommonModule.getActivityParams();
      if (Object.keys(queryObj).length != 0 || vuxActivityParams != undefined) {
        this.isCreated = true;
        this.editing = false;
        const params =
          Object.keys(queryObj).length != 0 ? queryObj : vuxActivityParams;
        this.currentCategory = params.currentCategory;
        this.activityType = params.activityType;
        let res = await getOne("activities", params.id);
        res["activityType"] = params.activityType;
        if (res.organizer.role == "user") {
          this.currentType = "unofficial";
        } else {
          this.currentType = "official";
        }
        await CommonModule.setActivityParams(res);
        // 活動已經開始狀態
        if (moment() >= moment(res.start_at)) {
          this.expired = true;
        }
        this.activity.init(res);
        this.activityId = res.id;
        this.qrcode = res.id + "";
        for (let i = 0; i < res.tickets.length; i++) {
          const ticket = new Ticket();
          ticket.init(res.tickets[i]);
          this.tickets.push(ticket);
        }
      } else {
        this.editing = true;
      }
    } catch (error) {
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  downloadQrCode() {
    let el = document.getElementById("qrCode");
    let imgSrc = el.lastChild.src;
    var a = document.createElement("a");
    a.href = imgSrc;
    a.download = "QR Code.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  cancel() {
    if (!this.isCreated) {
      this.$router.go(-1);
    }
    this.editing = false;
    //originalObj
  }

  checkTicketPeriod() {
    if (this.currentTicket == null) return;
    const start = moment(this.activity.start_at);
    const end = moment(this.activity.end_at);
    const duration = moment.duration(end.diff(start));
    const days = duration.asDays() + 1;
    if (this.currentTicket.checkin_threshold >= days) {
      this.currentTicket.checkin_threshold = parseInt(days);
    }
  }

  async submit(status) {
    await CommonModule.setLoading(true);
    try {
      const params = Object.assign(this.activity);
      params.tickets = JSON.parse(JSON.stringify(this.tickets));
      params.status = status;
      if (params.organizer == "") {
        params.organizer = await AuthenticationModule.getUsername();
      }
      if (this.isCreated) {
        // update activity
        await updateOne("activities", this.activityId, JSON.stringify(params));
        // update tickets
        for (let i = 0; i < this.tickets.length; i++) {
          const ticket = this.tickets[i];
          if (ticket.id == "") {
            delete ticket.id;
            ticket.activity_id = this.activityId;
            await createOne("activity-tickets", ticket);
          } else {
            await updateOne("activity-tickets", ticket.id, ticket);
          }
        }
      } else {
        await createOne("activities", JSON.stringify(params));
      }
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
      this.$message.info(error.message);
    }
    await CommonModule.setLoading(false);
  }

  disabledDate(current) {
    return moment().isAfter(current, 'date');
  }

  disabledTicketStartDate(current) {
    return (
      moment().isAfter(current, 'date') ||
      moment(this.activity.start_at).isBefore(current, 'date')
    );
  }

  disabledTicketEndDate(current) {
    return (
      moment().isAfter(current, 'date') ||
      moment(this.activity.end_at).isBefore(current, 'date') ||
      moment(this.currentTicket.start_at).isAfter(current, 'date')
    );
  }

  disabledEndDate(current) {
    return moment(current).isBefore(this.activity.start_at, 'date');
  }

  disabledLastModifyBeforeDate(current) {
    return moment(this.activity.start_at).isBefore(current, 'date');
  }

  checkRegisterDate(date, dateString) {
    if (this.activity.start_at == "") {
      this.$message.info("請先輸入活動起迄日再填寫日期");
      this.activity.last_modify_before = "";
      return;
    }

    if (moment(this.activity.start_at).isBefore(dateString, 'second')) {
      this.activity.last_modify_before = this.activity.start_at;
    } else {
      this.activity.last_modify_before = dateString;
    }
  }

  startDateChange(date, dateString) {
    this.activity.start_at = dateString;
    this.activity.end_at = "";
    this.activity.last_modify_before = "";
  }

  endDateChange(date, dateString) {
    if (moment(this.activity.start_at).isBefore(dateString, 'second')) {
      this.activity.end_at = dateString;
    } else {
      this.activity.end_at = this.activity.start_at;
    }
  }

  ticketStartDateChange(date, dateString) {
    if (moment(this.activity.start_at).isBefore(dateString, 'second')) {
      this.currentTicket.start_at = this.activity.start_at;
    } else {
      this.currentTicket.start_at = dateString;
    }
  }

  ticketEndDateChange(date, dateString) {
    if (moment(this.activity.end_at).isBefore(dateString, 'second')) {
      this.currentTicket.end_at = this.activity.end_at;
    } else {
      this.currentTicket.end_at = dateString;
    }
  }

  async deleteActivity() {
    await CommonModule.setLoading(true);
    try {
      this.deleteModel = false;
      await deleteOne("activities", this.activityId);
      this.$router.go(-1);
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  getLevel(level) {
    if (level == "0") {
      return "一般會員";
    } else if (level == "1") {
      return "初級";
    } else if (level == "2") {
      return "中級";
    } else if (level == "3") {
      return "高級";
    } else if (level == "4") {
      return "經銷商";
    } else {
      return "不限";
    }
  }

  async recall() {
    await CommonModule.setLoading(true);
    try {
      this.recallModel = false;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  clearVar(event) {
    const el = this.$refs.referenceUpload;
    el.value = null;
  }

  deleteTicket(index) {
    this.tickets.splice(index, 1);
  }

  updateTicket() {
    if (this.currentTicketIndex != -1) {
      this.tickets.splice(
        this.currentTicketIndex,
        1,
        JSON.parse(JSON.stringify(this.currentTicket))
      );
    } else {
      this.tickets.push(JSON.parse(JSON.stringify(this.currentTicket)));
    }
    this.currentTicketIndex = -1;
    this.currentTicket = null;
    this.originalCurrentTicket = null;
    this.ticketModel = false;
  }

  openTicketModel(index = null) {
    if (this.activity.start_at == "" || this.activity.end_at == "") {
      this.$message.info("請先輸入活動起迄日再新增票種");
      return;
    }

    this.ticketModel = true;
    if (!index) {
      this.currentTicket = new Ticket();
    }
  }

  editTicket(ticket, index) {
    this.originalCurrentTicket = JSON.parse(JSON.stringify(ticket));
    this.currentTicket = JSON.parse(JSON.stringify(ticket));
    this.currentTicketIndex = index;
    this.ticketModel = true;
  }

  uploadImg() {
    document.getElementById("getFile").click();
  }

  async updateImage(event) {
    try {
      let res = await fileUpload(event.target.files);
      this.activity.cover_image_url = res.images[0].url;
      this.activity.cover_image_name = res.images[0].name;
    } catch (error) {
      console.log(error);
    }
  }

  gotoSignUpPage() {
    this.record.activityType = this.activityType;
    this.$router.push({
      name: "ActivitySignUp",
      params: this.record,
    });
  }
}
</script>

<style lang="scss">
</style>
