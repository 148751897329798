<template>
  <div class="app-table">
    <div
      class="
        app-tr
        rule-tr
        app-bb app-table-header
        flex flex-row
        justify-between
      "
    >
      <div class="app-th app-w-20">上秤時間</div>
      <div class="app-th app-w-20">暱稱</div>
      <div class="app-th app-w-10">所屬會員</div>
      <div class="app-th">年紀</div>
      <div class="app-th">性別</div>
      <div class="app-th">身高</div>
      <div class="app-th">體重</div>
      <div class="app-th">體指率</div>
      <div class="app-th justify-center">查看</div>
    </div>
    <div v-if="recordList.length != 0">
      <div
        class="app-tr rule-tr app-bb flex flex-row justify-between"
        v-for="(record, index) in recordList"
        :key="index"
      >
        <div class="app-td app-w-20">
          {{ record.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
        </div>
        <div class="app-td app-w-20">{{ record.nickname }}</div>
        <div class="app-td app-w-10">{{ record.user_id }}</div>
        <div class="app-td">{{ record.age }}</div>
        <div class="app-td">{{ record.gender }}</div>
        <div class="app-td">{{ record.height }}</div>
        <div class="app-td">{{ record.weight }}</div>
        <div class="app-td">{{ record.fat_rate }}%</div>
        <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(record)"> 查看 </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length == 0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";
import moment from "moment";

@Component
export default class DietTable extends Vue {
  @Prop() records;
  recordList = [];

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  gotoPage(record) {
    record["from"] = "visitor";
    this.$router.push({
      name: "UserReport",
      params: record,
    });
  }
}
</script>

<style>
</style>
