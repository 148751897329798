<template>
  <div id="app">
    <BasciLayout v-if="toggleLayout == 'basic'" />
    <Login v-if="toggleLayout == 'login'" />
    <Reset v-if="toggleLayout == 'reset'" />
    <PageNotFound v-if="toggleLayout == '404'" />
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Login from "./views/auth/login";
import Reset from "./views/auth/reset";
import BasciLayout from "./layouts/basicLayout";
import PageNotFound from "./views/exception/pageNotFound";
import { CommonModule, Store } from "./store/index.js";
import { Config } from "./service/config.js";
@Component({
  name: "App",
  components: {
    Login,
    Reset,
    PageNotFound,
    BasciLayout
  }
})
export default class App extends Vue {
  toggleLayout = "basic";
  receivedMsg = null;

  @Watch("receivedMsg")
  onReceiveMsg(val, old) {
    console.log(val);
  }

  @Watch("$route")
  onRouterChanged(val, old) {
    this.switchLayout();
  }

  async mounted() {
    this.switchLayout();
  }
  switchLayout() {
    if (this.$route.fullPath == "/") {
      this.toggleLayout = "login";
    } else if (this.$route.fullPath == "/404") {
      this.toggleLayout = "404";
    } else if (this.$route.fullPath.indexOf("reset") != -1) {
      this.toggleLayout = "reset";
    } else {
      this.toggleLayout = "basic";
    }
  }

  displayNotification(type, msg) {
    let icon =
      type == false ? (
        <a-icon type="exclamation-circle" style="color: #108ee9" />
      ) : (
        <a-icon type="check-circle" style="color: #108ee9" />
      );
    this.$notification.open({
      icon: icon,
      message: "提示",
      description: msg
    });
  }

  fillZero(val) {
    return val < 10 ? "0" + val : val;
  }

  confirmDialog(msg, okAction) {
    this.$confirm({
      title: "提示",
      okText: "確認",
      cancelText: "取消",
      content: msg,
      onOk: okAction,
      onCancel() {}
    });
  }
}
</script>

<style>
#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
.h-line {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  border: 1px solid #dbdbdf;
}
.app-bb {
  border-bottom: 1px solid #dbdbdf;
}
.app-mt {
  margin-top: 40px !important;
}
.app-content-title {
  font-size: 25px;
  color: #888;
}
.app-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.app-table-header {
  background: #fafafa;
}
.app-tr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.app-tr:hover {
  background: #fafafa;
}
.app-th {
  font-weight: bold;
  font-size: 15px;
}
.app-td,
.app-th {
  display: flex;
  padding: 5px;
  width: 20%;
  height: 50px;
  align-items: center;
}
.pagination-wrap {
  padding: 5px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-default-wrap {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.app-default-page-title {
  margin: 24px;
  color: #000;
  font-size: 25px;
  font-weight: bold;
}

.app-default-page-back {
  margin: 24px 24px 0 24px;
  height: 21px;
  cursor: pointer;
  color: #005eb8;
  align-items: center;
  display: flex;
  font-weight: bold;
}

.app-default-button {
  min-height: 30px;
}

.none-data {
  text-align: center;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #ddd;
}
.ant-tabs-bar {
  margin-bottom: 0 !important;
}

.app-w-5 {
  width: 5%;
  min-width: 5%;
  max-width: 5%;
}
.app-w-10 {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
}
.app-w-15 {
  width: 15%;
  min-width: 15%;
  max-width: 15%;
}
.app-w-20 {
  width: 20%;
  min-width: 20%;
  max-width: 20%;
}
.app-w-30 {
  width: 30%;
  min-width: 30%;
  max-width: 30%;
}
.app-w-40 {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
}
.app-w-50 {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}
.app-w-60 {
  width: 60%;
  min-width: 60%;
  max-width: 60%;
}
.app-w-70 {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}
.app-w-80 {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
}
.app-tab-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.app-tab-item {
  padding-left: 1.25rem;
  padding-bottom: 0.5rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  border-color: rgba(59, 130, 246);
  cursor: pointer;
  text-align: center;
}
.app-tab-item-default {
  font-weight: normal;
  border: none;
  color: #888;
}
.app-tab-item-active {
  font-weight: bold;
  border-bottom: 2px solid;
  color: rgba(59, 130, 246);
  border-color: rgba(59, 130, 246);
}

.pointer {
  cursor: pointer;
}
</style>
