<template>
  <div>
    <div class="flex flex-row justify-between mt-10">
      <div class="w-1/2 mr-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="經銷商編號">
            <a-input
              v-model="params.merchant_id"
              placeholder=""
              :disabled="true"
            />
          </a-form-item>
          <a-form-item label="會員級別">
            <a-input :value="level" placeholder="" :disabled="true" />
          </a-form-item>
          <a-form-item label="Email">
            <a-input
              type="text"
              v-model="params.email"
              placeholder=""
              :disabled="true"
            />
          </a-form-item>
          <a-form-item label="上階邀請人">
            <a-input
              type="text"
              v-model="params.parent_customer_name"
              placeholder=""
              :disabled="true"
            />
          </a-form-item>
        </a-form>
      </div>
      <div class="w-1/2 ml-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="姓名">
            <a-input v-model="params.name" placeholder="" :disabled="true" />
          </a-form-item>
          <a-form-item label="年分紅級別">
            <a-input
              v-model="params.year_bonus_level"
              placeholder=""
              :disabled="true"
            />
          </a-form-item>
          <a-form-item label="地址">
            <a-input value="-" placeholder="" :disabled="true" />
          </a-form-item>
          <a-form-item label="上階經理">
            <a-input
              type="text"
              v-model="params.director_customer_name"
              placeholder=""
              :disabled="true"
            />
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import App from "../../App";
import moment from "moment";
import { UserCloudShopParams } from "../../models/userCloudShopParams";

@Component({
  name: "CloudShop",
  components: {},
})
export default class CloudShop extends App {
  @Prop() editable;
  @Prop() shopParams;
  level = "-";

  // @Watch("editable")
  // onReceiveMsg(editable) {
  //   this.editable = editable;
  // }

  @Watch("shopParams")
  watchShopParams(shopParams) {
    this.params = shopParams;
    this.level = this.getUserLevel(this.params.reseller_level);
  }

  params = new UserCloudShopParams();

  getUserLevel(val) {
    if (val == 0) {
      return "一般會員";
    } else if (val == 1) {
      return "初級";
    } else if (val == 2) {
      return "中級";
    } else if (val == 3) {
      return "高級";
    } else if (val == 4) {
      return "經銷商";
    } else {
      return "-";
    }
  }
}
</script>
