<template>
  <div class="app-table ">
    <div class="app-tr rule-tr app-bb app-table-header flex flex-row justify-between">
      <div class="app-th">完成紀錄時間</div>
      <div class="app-th">紀錄日期</div>
      <div class="app-th justify-center">查看</div>
    </div>
    <div v-if="recordList.length != 0">
      <div class="app-tr rule-tr app-bb flex flex-row justify-between" v-for="(record,index) in recordList" :key="index">
        <div class="app-td">{{record.updated_at | moment("YYYY-MM-DD HH:mm:ss")}}</div>
        <div class="app-td">{{record.user_date}}</div>
        <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(record)">
            查看
          </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";
import moment from "moment";

@Component
export default class DietTable extends Vue {
  @Prop() records;
  recordList = [];

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  gotoPage(record) {
    this.$router.push({
      name: "DietRecord",
      params: record
    });
  }
}
</script>

<style>
</style>
