<template>
  <div>
    <div class="app-default-page-back" @click="$router.go(-1)">
      <a-icon type="arrow-left" class="mr-3" />返回
    </div>
    <div v-if="userParams != null">
      <div class="app-default-page-title">
        {{
          !userParams.user_profile
            ? "上秤報告"
            : userParams.user_profile.nickname + " 的上秤報告"
        }}
      </div>
      <div class="app-default-wrap">
        <div>
          <span
            >上秤時間
            {{ userParams.created_at | moment("YYYY-MM-DD HH:mm:ss") }}</span
          >
        </div>
        <div class="h-line"></div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-between mt-10">
            <div class="w-1/2 mr-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="身高">
                  <a-input
                    :value="
                      visitorHeight != ''
                        ? visitorHeight
                        : !userParams.user_profile
                        ? '-'
                        : userParams.height
                    "
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="性別">
                  <a-input
                    :value="userParams.gender"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="體重">
                  <a-input
                    :value="userParams.weight"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="脂肪">
                  <a-input
                    :value="userParams.fat"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="蛋白質">
                  <a-input
                    :value="userParams.protein"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="肌肉">
                  <a-input
                    :value="userParams.muscle"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="肌肉率">
                  <a-input
                    :value="userParams.muscle_rate"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="骨質">
                  <a-input
                    :value="userParams.bone"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="基礎代謝">
                  <a-input
                    :value="userParams.basal_metabolic"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
            <div class="w-1/2 ml-5">
              <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="年齡">
                  <a-input
                    :value="visitorAge != '' ? visitorAge : userParams.age"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="體脂率">
                  <a-input
                    :value="userParams.fat_rate"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="BMI">
                  <a-input
                    :value="userParams.BMI"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="內臟脂肪">
                  <a-input
                    :value="userParams.visceral_fat"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="水分">
                  <a-input
                    :value="userParams.water"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="水分率">
                  <a-input
                    :value="userParams.water_rate"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="骨骼肌">
                  <a-input
                    :value="userParams.muscle_mass"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item label="骨骼肌率">
                  <a-input
                    :value="userParams.muscle_mass_rate"
                    placeholder=""
                    :disabled="true"
                  />
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
        <div class="h-line"></div>
        <div
          class="flex flex-row justify-between mt-10"
        >
          <div class="w-1/2 mr-5">
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
              <a-form-item label="手機型號">
                <a-input
                  :value="userParams.mobile"
                  placeholder=""
                  :disabled="true"
                />
              </a-form-item>
              <a-form-item label="App版本">
                <a-input
                  :value="userParams.app_version"
                  placeholder=""
                  :disabled="true"
                />
              </a-form-item>
            </a-form>
          </div>
          <div class="w-1/2 ml-5">
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
              <a-form-item label="手機系統">
                <a-input
                  :value="userParams.mobile_version"
                  placeholder=""
                  :disabled="true"
                />
              </a-form-item>
              <a-form-item label="秤型號">
                <a-input
                  :value="userParams.scale_device"
                  placeholder=""
                  :disabled="true"
                />
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store,
} from "../../store/index.js";
import { getOne } from "../../service/api.js";
import App from "../../App";
import moment from "moment";

@Component({
  name: "UserReport",
  components: {},
})
export default class UserReport extends App {
  userParams = null;
  visitorHeight = "";
  visitorAge = "";
  async mounted() {
    try {
      const queryObj = this.$route.params;
      if (Object.keys(queryObj).length != 0) {
        this.userParams = queryObj;
        const params = await CommonModule.getRecordUserParams();
        this.nickname = !params.profile ? "-" : params.profile.nickname;
        if (this.userParams.from == "visitor") {
          this.visitorHeight = this.userParams.height;
          this.visitorAge = this.userParams.age;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  getAge(params) {
    if (!params.user_profile) return "-";
    let a = moment(params.user_profile.birthday);
    let b = moment();
    return b.diff(a, "years");
  }
}
</script>
