<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title mt-2">
  {{actionType=='indexName'?'編輯指標說明':'等級'}}
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>{{actionType=='indexName'?'指標說明':'等級說明'}}</div>
    </div>
    <div class="h-line"></div>
    <div class="app-tab-wrap">
      <div class="app-tab-item" @click="changeTab('1')" :class="activeKey == '1'?'app-tab-item-active':'app-tab-item-default'" >繁體中文</div>
      <div class="app-tab-item" @click="changeTab('2')" :class="activeKey == '2'?'app-tab-item-active':'app-tab-item-default'">簡體中文</div>
      <div class="app-tab-item" @click="changeTab('3')" :class="activeKey == '3'?'app-tab-item-active':'app-tab-item-default'">英文</div>
      <div class="app-tab-item" @click="changeTab('4')" :class="activeKey == '4'?'app-tab-item-active':'app-tab-item-default'">日文</div>
    </div>
    <div v-if="actionType=='indexName'">
      <div class="flex flex-row justify-start items-center mt-5 mb-5">
        <div class="m-3 app-w-10">指標名稱</div>
        <a-input v-if="activeKey == '1'" class="app-w-90" v-model="bodyLevelTw.key_title" placeholder="請輸入名稱"/>
        <a-input v-if="activeKey == '2'" class="app-w-90" v-model="bodyLevelCn.key_title" placeholder="請輸入名稱"/>
        <a-input v-if="activeKey == '3'" class="app-w-90" v-model="bodyLevelEn.key_title" placeholder="請輸入名稱"/>
        <a-input v-if="activeKey == '4'" class="app-w-90" v-model="bodyLevelJp.key_title" placeholder="請輸入名稱"/>
      </div>
      <a-textarea v-if="activeKey == '1'" v-model="bodyLevelTw.key_description" placeholder="請輸入描述" :rows="4"/>
      <a-textarea v-if="activeKey == '2'" v-model="bodyLevelCn.key_description" placeholder="請輸入描述" :rows="4"/>
      <a-textarea v-if="activeKey == '3'" v-model="bodyLevelEn.key_description" placeholder="請輸入描述" :rows="4"/>
      <a-textarea v-if="activeKey == '4'" v-model="bodyLevelJp.key_description" placeholder="請輸入描述" :rows="4"/>
    </div>
    <div v-else>
      <div class="flex flex-row justify-start items-center mt-5 mb-5">
        <div class="m-3 app-w-10">等級名稱</div>
        <a-input v-if="activeKey == '1'" class="app-w-90" v-model="bodyLevelTw.level_title" placeholder="請輸入名稱"/>
        <a-input v-if="activeKey == '2'" class="app-w-90" v-model="bodyLevelCn.level_title" placeholder="請輸入名稱"/>
        <a-input v-if="activeKey == '3'" class="app-w-90" v-model="bodyLevelEn.level_title" placeholder="請輸入名稱"/>
        <a-input v-if="activeKey == '4'" class="app-w-90" v-model="bodyLevelJp.level_title" placeholder="請輸入名稱"/>
      </div>
      <a-textarea v-if="activeKey == '1'" v-model="bodyLevelTw.level_description" placeholder="請輸入描述" :rows="4"/>
      <a-textarea v-if="activeKey == '2'" v-model="bodyLevelCn.level_description" placeholder="請輸入描述" :rows="4"/>
      <a-textarea v-if="activeKey == '3'" v-model="bodyLevelEn.level_description" placeholder="請輸入描述" :rows="4"/>
      <a-textarea v-if="activeKey == '4'" v-model="bodyLevelJp.level_description" placeholder="請輸入描述" :rows="4"/>
    </div>
  </div>
  <div class="flex flex-row">
    <a-button class="m-3 w-40 h-10" @click="$router.go(-1)">取消</a-button>
    <a-button class="m-3 w-40 h-10" @click="submit()" type="primary">
      儲存
    </a-button>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../../store/index.js";
import { getBodyLevel, getBodyIndex, updateAll } from "../../../service/api.js";
import App from "../../../App";
import Editor from "../../../components/editor.vue";
import { BodyLevel } from "../../../models/bodyLevel";

@Component({
  name: "ScalesUpdate",
  components: {}
})
export default class ScalesUpdate extends App {
  displayModel = false;
  editing = false;
  currentLevel = "";
  currentKey = "";
  bodyLevelTw = new BodyLevel("zh_tw");
  bodyLevelCn = new BodyLevel("zh_cn");
  bodyLevelEn = new BodyLevel("en");
  bodyLevelJp = new BodyLevel("jp");
  actionType = "indexName";
  activeKey = "1";

  async mounted() {
    await CommonModule.setLoading(true);

    try {
      const queryObj = this.$route.params;
      this.actionType = queryObj.actionType;
      if (Object.keys(queryObj).length != 0) {
        let res = null;
        this.currentKey = queryObj.key;
        this.currentLevel = queryObj.level;
        if (this.actionType == "indexName") {
          res = await getBodyIndex(this.currentKey);
          for (let i = 0; i < res.body_indices.length; i++) {
            const indice = res.body_indices[i];
            if (indice.language == "zh_tw") {
              this.bodyLevelTw.key_title = indice.key_title;
              this.bodyLevelTw.key_description = indice.key_description;
            } else if (indice.language == "zh_cn") {
              this.bodyLevelCn.key_title = indice.key_title;
              this.bodyLevelCn.key_description = indice.key_description;
            } else if (indice.language == "jp") {
              this.bodyLevelJp.key_title = indice.key_title;
              this.bodyLevelJp.key_description = indice.key_description;
            } else {
              this.bodyLevelEn.key_title = indice.key_title;
              this.bodyLevelEn.key_description = indice.key_description;
            }
          }
        } else {
          res = await getBodyLevel(this.currentKey, this.currentLevel);
          for (let i = 0; i < res.level_details.length; i++) {
            const indice = res.level_details[i];
            if (indice.language == "zh_tw") {
              this.bodyLevelTw.level_title = indice.level_title;
              this.bodyLevelTw.level_description = indice.level_description;
            } else if (indice.language == "zh_cn") {
              this.bodyLevelCn.level_title = indice.level_title;
              this.bodyLevelCn.level_description = indice.level_description;
            } else if (indice.language == "jp") {
              this.bodyLevelJp.level_title = indice.level_title;
              this.bodyLevelJp.level_description = indice.level_description;
            } else {
              this.bodyLevelEn.level_title = indice.level_title;
              this.bodyLevelEn.level_description = indice.level_description;
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changeTab(key = "1") {
    this.activeKey = key;
  }

  async submit() {
    await CommonModule.setLoading(true);
    try {
      if (this.actionType == "indexName") {
        const params = {
          key: this.currentKey,
          body_index_details: [
            this.bodyLevelTw,
            this.bodyLevelCn,
            this.bodyLevelJp,
            this.bodyLevelEn
          ]
        };
        await updateAll("body-index-details", params);
      } else {
        const params = {
          key: this.currentKey,
          level: this.currentLevel,
          level_details: [
            this.bodyLevelTw,
            this.bodyLevelCn,
            this.bodyLevelJp,
            this.bodyLevelEn
          ]
        };
        await updateAll("body-index-level-details", params);
        this.$router.go(-1);
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }
}
</script>