<template>
  <div class="app-table ">
    <div class="app-tr rule-tr app-bb app-table-header">
      <div class="app-th">意見單號</div>
      <div class="app-th">暱稱</div>
      <div class="app-th">會員ID</div>
      <div class="app-th justify-center">最後回覆人</div>
      <div class="app-th justify-center">最後回覆時間</div>
      <div class="app-th justify-center">回覆</div>
    </div>
    <div v-if="recordList.length != 0">
      <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
        <div class="app-td" >
          <span v-if="record.user_profile != undefined">
            <a-badge color="#f50" text="" v-if="checkUserProfile(record)"/>
          </span>
          {{record.id}}
        </div>
        <div class="app-td">
          {{record.user_profile != undefined ? record.user_profile.nickname : "-"}}
        </div>
        <div class="app-td">
          {{record.user_id }}
        </div>
        <div class="app-td justify-center">{{record.last_message != undefined ? record.last_message.user_profile.nickname : "-"}}</div>
        <div class="app-td justify-center">{{record.last_message != undefined ? getTime(record):"-"}}</div>
        <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(record)">
            回覆
          </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";
import moment from "moment";
import { getAll } from "../service/api.js";

@Component
export default class FeedbackTable extends Vue {
  @Prop() records;
  @Prop() feedbackType;
  recordList = [];

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  gotoPage(record) {
    record["feedbackType"] = this.feedbackType;
    this.$router.push({
      name: "UpdateFeedback",
      params: record
    });
  }

  checkUserProfile(record) {
    if (record.last_message == undefined || record.user_profile == undefined)
      return;

    return (
      record.user_profile.nickname == record.last_message.user_profile.nickname
    );
  }

  getTime(record) {
    if (record.last_message == undefined) return;
    return moment(record.last_message.updated_at).format("YYYY-MM-DD HH:mm:ss");
  }
}
</script>

<style>
</style>
