<template>
<div>

    <div class="flex flex-row justify-between mt-10" v-if="params != null">
      <div class="w-1/2 mr-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="會員類型" v-if="!visitor">
            <a-radio-group v-decorator="['radio-group']"  v-model="params.role" :disabled="!editable" @change="paramsChanges()" >
              <a-radio value="user" >
                一般會員
              </a-radio>
              <a-radio value="coach">
                教練
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="暱稱">
            <a-input v-model="params.nickname" placeholder="請輸入暱稱" :disabled="!editable" @change="paramsChanges()"/>
          </a-form-item>
          <a-form-item label="國家" v-if="!visitor">
            <a-select :key="countryName" placeholder="請選擇國家" class="mr-3 w-60" :disabled="!editable" :default-value="countryName" @change="changeCountry">
              <a-select-option :value="country.name" v-for="country in countries" :key="country.id">
                {{country.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="手機">
            <a-input type="text" @input="handleMobile" :value="params.username" placeholder="請輸入手機" :disabled="!editable"  @change="handleMobile"/>
          </a-form-item>
          <a-form-item label="性別">
            <a-radio-group v-decorator="['radio-group']" v-model="params.gender" :disabled="!editable"  @change="paramsChanges()">
              <a-radio value="male" checked>
                男
              </a-radio>
              <a-radio value="female">
                女
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <div v-if="!visitor">
            <a-form-item label="密碼" v-if="isCreated && editing">
              <a-button type="primary" @click="displayPasswordDialog = true">
                修改密碼
              </a-button>
            </a-form-item>
            <a-form-item label="密碼" v-if="isCreated && !editing">
              <span>{{params.updated_at|moment('YYYY-MM-DD HH:mm:ss')}} 最後更新</span>
            </a-form-item>
            <a-form-item label="密碼" v-if="!isCreated">
              <a-input  type="password"  v-model="params.password" placeholder="請輸入密碼" @change="paramsChanges()" :disabled="!editable"/>
            </a-form-item>
            <a-form-item label="  " v-if="!isCreated">
              <a-input type="password" placeholder="請再次確認密碼" @change="paramsChanges()" v-model="params.confirm_password" :disabled="!editable" />
            </a-form-item>
          </div>
        </a-form>
      </div>
      <div class="w-1/2 ml-5">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="頭像" v-if="!visitor">
            <div class="flex flex-row">
              <div class="flex flex-row text-center	" style="width:150px;height:150px;border: 1px dotted #ddd; background-repeat: no-repeat; background-size: contain;" :style="`background-image:url('${avatar}')`">
                <div v-if="params.avatar_url == ''" class="w-full text-8xl">
                  <a-icon type="user" style="color: #ddd" />
                </div>
              </div>
              <a-button  class="ml-2" :disabled="!editable" @click="uploadImg()">
                  <a-icon type="upload" /> 上傳圖片
              </a-button>
              <input ref="referenceUpload" type="file" style="display: none;" id="getFile" @change="updateImage" @click="clearVar" accept="image/gif,image/jpeg,image/jpg,image/png" />
            </div>
          </a-form-item>

          <a-form-item label="生日">
            <a-date-picker
              :disabled-date="disabledDate"
              format="YYYY/MM/DD"
              v-model="params.birthday"
              style="width: 100%"
              placeholder="請選擇生日" :disabled="!editable" @change="dateChange" />
          </a-form-item>

          <a-form-item label="身高">
            <a-input type="number" min="0" v-model="params.height" @change="paramsChanges()"  placeholder="請輸入身高" :disabled="!editable" >
              <a-select
                slot="addonAfter"
                style="width: 70px"
                v-model="params.height_type"
                :disabled="!editable"
                @change="paramsChanges()"
              >
                <a-select-option value="cm">
                  公分
                </a-select-option>
                <a-select-option value="ft">
                  公寸
                </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>
          <a-form-item label="所屬教練">
            <a-input  type="type"  v-model="params.coach_user_id"  placeholder="請輸入所屬教練ID" @change="paramsChanges()" :disabled="!editable"/>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-modal v-if="params != null" v-model="displayPasswordDialog" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
      <h3>修改密碼</h3>
      <div class="horizontal-line"></div>
      <small>請設置至少6位英文+數字組合的密碼</small>
      <a-input class="mt-2" type="password"  v-model="params.password"  placeholder="請輸入密碼"/>
      <a-input class="mt-2" type="password"  v-model="params.confirm_password"  placeholder="請再次輸入密碼" />
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="displayPasswordDialog=false;params.password='';params.confirm_password=''">取消</a-button>
        <a-button class="w-40 ml-4" @click="updatePassword();" type="primary">
          儲存
        </a-button>
      </div>
    </a-modal>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getCountryCode, createOne, fileUpload } from "../../service/api.js";
import App from "../../App";
import moment from "moment";
import { UserBasicParams } from "../../models/userBasicParams";
@Component({
  name: "User",
  components: {}
})
export default class User extends App {
  @Prop() editable;
  @Prop() editing;
  @Prop() visitor;
  @Prop() isCreated;
  @Prop() userParams;

  @Watch("visitor")
  watchVisitor(visitor) {
    this.visitor = visitor;
  }

  @Watch("editing")
  watchEditing(editing) {
    this.editing = editing;
  }

  @Watch("editable")
  watchEditable(editable) {
    this.editable = editable;
  }

  @Watch("isCreated")
  watchIsCreated(isCreated) {
    this.isCreated = isCreated;
  }

  @Watch("userParams")
  watchUserParams(userParams) {
    this.params = userParams;
    this.avatar = this.params.avatar_url;
    this.findCountry();
  }

  displayPasswordDialog = false;
  disableUserDialog = false;
  toggleDisable = true;
  displayModel = false;
  countries = [];
  countriesMap = {};
  errorMsg = null;
  params = new UserBasicParams();
  countryName = "";
  avatar = "";

  isNumber(evt) {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (evt.key == ".") {
      evt.preventDefault();
    }
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode == 46 || charCode == 190 || charCode == 110)
    ) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  async mounted() {
    await CommonModule.setLoading(true);

    try {
      // console.log("visitor:" + this.visitor);
      const res = await getCountryCode();
      this.countries = res.codes;
      for (let i = 0; i < this.countries.length; i++) {
        const country = this.countries[i];
        this.countriesMap[country.name] = country.code;
      }
      this.findCountry();
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async submit() {
    await CommonModule.setLoading(true);
    try {
      this.countries.country_code = undefined;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }
  async updatePassword() {
    const engReg = /[a-z]/i;
    if (
      this.params.password.length < 6 ||
      this.params.confirm_password.length < 6
    ) {
      this.$message.info("密碼小於六位數字");
      return;
    }
    if (this.params.password != this.params.confirm_password) {
      this.$message.info("密碼不一致");
      return;
    }
    if (!engReg.test(this.params.password)) {
      this.$message.info("密碼未包含英文字");
      return;
    }
    try {
      this.displayPasswordDialog = false;
      this.params.password_change = true;
    } catch (error) {
      console.log(error);
    }
  }

  async updateImage(event) {
    try {
      let res = await fileUpload(event.target.files);
      this.params.avatar_url = res.images[0].url;
      this.params.avatar_name = res.images[0].name;
      this.avatar = res.images[0].url;
      this.paramsChanges();
    } catch (error) {
      console.log(error);
    }
  }

  changeCountry(name) {
    this.params.country_code = this.countriesMap[name];
    this.paramsChanges();
  }

  handleMobile(event) {
    let val = event.target.value.trim();
    if (/^[0-9]\d*$|^$/.test(val)) {
      this.params.username = val;
    } else {
      event.target.value = this.params.username;
    }
    this.params.username_change = true;
    this.paramsChanges();
  }

  dateChange(date, dateString) {
    this.birthday = dateString;
    this.paramsChanges();
  }

  findCountry() {
    let c = this.countries.find(el => {
      if (el.id == 1) {
        console.log(el);
      }
      return el.code == this.params.country_code;
    });
    if (c == undefined) {
      this.countryName = undefined;
      return;
    }
    this.countryName = c.name;
  }

  paramsChanges() {
    try {
      if (this.params.height_type == "ft") {
        if (this.params.height != "") {
          // convert to cm
          this.params.height = parseFloat(this.height) / 0.032808;
        }
      }
    } catch (error) {
      console.log(error);
    }

    this.$emit("changeUserParams", this.params);
  }

  uploadImg() {
    document.getElementById("getFile").click();
  }

  clearVar(event) {
    const el = this.$refs.referenceUpload;
    el.value = null;
  }

  chooseFiles() {
    document.getElementById("fileUpload").click();
  }

  disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  addFile(e) {}
}
</script>
