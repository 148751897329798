<template>
  <div class="app-table">
    <div
      class="
        app-tr
        rule-tr
        app-bb app-table-header
        flex flex-row
        justify-between
      "
    >
      <div class="app-th app-w-10">活動編號</div>
      <div class="app-th">活動名稱</div>
      <div class="app-th">舉辦人</div>
      <div class="app-th">活動開始時間</div>
      <div class="app-th">票種</div>
      <div class="app-th justify-center">訂單狀態</div>
      <div class="app-th justify-center">查看</div>
    </div>
    <div v-if="recordList.length != 0">
      <div
        class="app-tr rule-tr app-bb flex flex-row justify-between"
        v-for="(record, index) in recordList"
        :key="index"
      >
        <div class="app-td app-w-10" v-if="activityType != 'draft'">
          {{ record.activity.id }}
        </div>
        <div class="app-td">
          {{ record.activity.name }}
        </div>
        <div class="app-td">
          {{ getHost(record) }}
        </div>
        <div class="app-td">
          {{ record.activity.start_at | moment("YYYY/MM/DD HH:mm:ss") }}
        </div>
        <div class="app-td">
          {{ record.ticket.name }}
        </div>
        <div class="app-td justify-center">
          {{ getStatus(record.status) }}
        </div>
        <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(record)"> 查看 </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length == 0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";

@Component
export default class UserActivityTable extends Vue {
  @Prop() records;
  @Prop() activityType;
  recordList = [];
  ticketMap = {};

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  getPayNumber(record) {
    if (record.orders != undefined) {
      let total = 0;
      for (let i = 0; i < record.orders.length; i++) {
        const order = record.orders[i];
        if (order.status != undefined && order.status == "registered") {
          total += 1;
        }
      }
      return total;
    } else {
      return 0;
    }
  }

  getStatus(status) {
    if (status == "registered") {
      return "已付款";
    } else if (status == "pending") {
      return "未付款";
    } else if (status == "refund") {
      return "已退票";
    } else if (status == "registered-offline") {
      return "現場報名";
    } else if (status == "expired") {
      return "已過期";
    } else {
      return "-";
    }
  }

  getHost(record) {
    return !record.activity.organizer.profile
      ? "-"
      : record.activity.organizer.profile.nickname;
  }

  gotoPage(record) {
    record["activityType"] = this.activityType;
    this.$router.push({
      name: "UpdateActivity",
      params: record,
    });
  }
}
</script>

<style>
</style>
