export class UserCloudShopParams {
  constructor(res) {
    if (res == undefined) return;
    if (res.cloud_mall_record == undefined) return;
    this.id = res.cloud_mall_record.user_id;
    this.user_id = res.cloud_mall_record.user_id
    this.merchant_id = res.cloud_mall_record.merchant_id
    this.name = res.cloud_mall_record.name
    this.reseller_level = res.cloud_mall_record.reseller_level
    this.mobile = res.cloud_mall_record.mobile
    this.country_code = res.cloud_mall_record.country_code
    this.parent_customer_name = res.cloud_mall_record.parent_customer_name
    this.director_customer_name = res.cloud_mall_record.director_customer_name
    this.year_bonus_level = res.cloud_mall_record.year_bonus_level
    this.email = res.cloud_mall_record.email
    this.created_at = res.cloud_mall_record.created_at
    this.updated_at = res.cloud_mall_record.updated_at
  }
  id = "-";
  user_id = "-";
  merchant_id = "-";
  name = "-";
  reseller_level = "-";
  mobile = "-";
  country_code = "-";
  parent_customer_name = "-";
  director_customer_name = "-";
  year_bonus_level = "-";
  email = "-";
  created_at = "-";
  updated_at = "-";

  convert() {
    return JSON.parse(JSON.stringify(this));
  }
}