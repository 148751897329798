<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title mt-2">
    新增會員
  </div>
  <div class="app-default-wrap">
    <div>會員資料</div>
    <div class="h-line"></div>
   <User :isCreated="false" :editable="true" :visitor="false"  @changeUserParams="getUserParams"/>
  </div>
  <div class="flex flex-row">
    <a-button class="m-3 w-40 h-10" @click="$router.go(-1)">取消</a-button>
    <a-button class="m-3 w-40 h-10" @click="submit()" type="primary">
      儲存
    </a-button>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { createOne } from "../../service/api.js";
import App from "../../App";
import User from "./user";
import moment from "moment";

@Component({
  name: "CreateUser",
  components: {
    User
  }
})
export default class CreateUser extends App {
  userParams = {
    role: "",
    country_code: "886",
    username: "",
    nickname: "",
    password: "",
    confirm_password: "",
    gender: "",
    birthday: "",
    height_type: "cm",
    height: "",
    avatar_name: "",
    avatar_url: "",
    coach_user_id: ""
  };

  @Watch("userParams")
  watchUserParams(userParams) {
    this.userParams = userParams;
  }

  async submit() {
    if (
      this.userParams.password.length < 6 ||
      this.userParams.confirm_password.length < 6
    ) {
      this.$message.info("密碼小於六位數字");
      return;
    }
    if (this.userParams.password != this.userParams.confirm_password) {
      this.$message.info("密碼不一致");
      return;
    }
    if (this.userParams.country_code == "") {
      this.$message.info("請選擇國家");
      return;
    }
    if (this.userParams.birthday == "") {
      this.$message.info("請輸入生日");
      return;
    }
    if (this.userParams.height == "") {
      this.$message.info("請輸入身高");
      return;
    }
    await CommonModule.setLoading(true);
    try {
      await createOne("users", this.userParams);
      this.$router.go(-1);
    } catch (error) {
      this.$message.info(error.message);
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  getUserParams(params) {
    this.userParams = params;
  }
}
</script>

<style lang="scss">
</style>
