<template>
<a-layout id="components-layout-demo-top-side-2" >
  <Side />
  <a-layout>
    <Header />
    <a-layout>
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
      <Footer />
    </a-layout>
    <div class="spin-content"   v-if="isLoading">
       <a-spin class="spinner"/>
    </div>
  </a-layout>
</a-layout>
</template>

<script>
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Header from "../components/header";
import Side from "../components/side";
import Footer from "../components/footer";
import { CommonModule, Store } from "../store/index.js";

@Component({
  name: "App",
  components: {
    Header,
    Footer,
    Side
  }
})
export default class App extends Vue {
  isLoading = false;
  mounted() {
    const store = Store();
    store.watch(
      state => state.common.loading,
      val => {
        this.isLoading = val;
      }
    );
  }
}
</script>
<style>
.spin-content {
  background: rgba(0, 0, 0, 0.1) !important;
  position: fixed !important;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}
.spinner {
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
}
</style>

