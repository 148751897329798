<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2">
    更換教練審核
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
      <div>申請名單</div>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row mb-2">
      <div class="flex flex-row justify-start mt-3 mb-3">
        <a-input placeholder="會員ID" class="mr-3" v-model="userID" />
      </div>
      <div class="flex flex-row justify-start mb-3 mt-3">
        <a-button type="primary" class="mr-3 w-28" @click="getList()">
          搜尋
        </a-button>
        <a-button type="link" class="mr-3 w-28" @click="userID = '';getList()">清空條件</a-button>
      </div>
    </div>
    <div class="app-tab-wrap">
      <div class="app-tab-item" @click="changeTab('1')" :class="activeKey == '1'?'app-tab-item-active':'app-tab-item-default'">待審核</div>
      <div class="app-tab-item" @click="changeTab('2')" :class="activeKey == '2'?'app-tab-item-active':'app-tab-item-default'">已審核</div>
    </div>
    <div v-if="activeKey == '1'">
      <div class="app-table">
        <div class="app-tr rule-tr app-bb app-table-header">
          <div class="app-th app-w-10">會員編號</div>
          <div class="app-th">暱稱</div>
          <div class="app-th">當前教練</div>
          <div class="app-th">新教練</div>
          <div class="app-th">申請時間</div>
          <div class="app-th justify-center">查看</div>
        </div>
        <div v-if="pendingList.length != 0">
          <div class="app-tr rule-tr app-bb" v-for="(record,index) in pendingList" :key="index">
            <div class="app-td text-blue-500 cursor-pointer  app-w-10" @click="gotoUserInfoPage(record)">{{record.user_profile.user_id}}</div>
            <div class="app-td">
              {{record.user_profile.nickname}}
            </div>
            <div class="app-td text-blue-500 cursor-pointer" @click="gotoUserInfoPage(record.from_coach_profile)">{{record.from_coach_profile.nickname}}</div>
            <div class="app-td text-blue-500 cursor-pointer" @click="gotoUserInfoPage(record.to_coach_profile)">{{record.to_coach_profile.nickname}}</div>
            <div class="app-td">{{record.created_at | moment("YYYY-MM-DD HH:mm:ss")}}</div>
            <div class="app-td justify-center">
              <a-button type="link" @click="openModal(record)">
                查看
              </a-button>
            </div>
          </div>
          <div>
            <div class="none-data" v-if="pendingList.length==0">目前無任何資訊</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeKey == '2'">
      <div class="app-table">
        <div class="app-tr rule-tr app-bb app-table-header">
          <div class="app-th  app-w-10">會員編號</div>
          <div class="app-th">暱稱</div>
          <div class="app-th">當前教練</div>
          <div class="app-th">新教練</div>
          <div class="app-th">審核時間</div>
          <div class="app-th justify-center">審核結果</div>
          <div class="app-th justify-center">查看</div>
        </div>
        <div v-if="reviewedList.length != 0">
          <div class="app-tr rule-tr app-bb" v-for="(record,index) in reviewedList" :key="index">
            <div class="app-td text-blue-500 cursor-pointer app-w-10" @click="gotoUserInfoPage(record)">{{record.user_profile.user_id}}</div>
            <div class="app-td">
              {{record.user_profile.nickname}}
            </div>
            <div class="app-td text-blue-500 cursor-pointer" @click="gotoUserInfoPage(record)">{{record.from_coach_profile.nickname}}</div>
            <div class="app-td text-blue-500 cursor-pointer" @click="gotoUserInfoPage(record)">{{record.to_coach_profile.nickname}}</div>
            <div class="app-td">{{record.reviewed_at ? record.reviewed_at : record.created_at | moment("YYYY-MM-DD HH:mm:ss")}}</div>
            <div class="app-td justify-center" :class="record.status == 'accept'?'text-green-500':'text-red-500'">
              {{record.status == 'accept'?"通過":"未通過"}}
            </div>
            <div class="app-td justify-center">
              <a-button type="link" @click="openModal(record)">
                查看
              </a-button>
            </div>
          </div>
          <div>
            <div class="none-data" v-if="reviewedList.length==0">目前無任何資訊</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-wrap">
      <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
  </div>
  <a-modal v-if="currentRecord!=null" v-model="displayModel" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
    <div style="padding:10px">
      <h4>{{!currentRecord.applyTime?'審核申請':'查看申請'}}</h4>
      <div class="h-line"></div>
      <h4 class="mt-4">申請人資料</h4>
      <div class="flex flex-col mt-2 bg-gray-100 rounded">
        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              會員ID
            </div>
            <div class="m-2 font-bold">{{currentRecord.user_profile.user_id}}</div>
          </div>
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              經銷商編號
            </div>
            <div class="m-2 font-bold">{{currentRecord.user_profile.merchant_id}}</div>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              暱稱
            </div>
            <div class="m-2 font-bold">{{currentRecord.user_profile.nickname}}</div>
          </div>
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              手機
            </div>
            <div class="m-2 font-bold">{{currentRecord.user_profile.mobile}}</div>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start">
            <div class="m-2 min-70">
              Email
            </div>
            <div class="m-2 font-bold">{{currentRecord.user_profile.email}}</div>
          </div>
        </div>
      </div>
      <h4 class="mt-4">當前教練</h4>
      <div class="flex flex-col mt-2 bg-gray-100 rounded">
        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              會員ID
            </div>
            <div class="m-2 font-bold">{{currentRecord.from_coach_profile.user_id}}</div>
          </div>
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              經銷商編號
            </div>
            <div class="m-2 font-bold">{{currentRecord.from_coach_profile.merchant_id}}</div>
          </div>
        </div>
      </div>
      <h4 class="mt-4">新教練</h4>
      <div class="flex flex-col mt-2 bg-gray-100 rounded">
        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              會員ID
            </div>
            <div class="m-2 font-bold">{{currentRecord.to_coach_profile.user_id}}</div>
          </div>
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 min-70">
              經銷商編號
            </div>
            <div class="m-2 font-bold">{{currentRecord.to_coach_profile.merchant_id}}</div>
          </div>
        </div>
      </div>
      <h4 class="mt-4">申請原因</h4>
      <div class="flex flex-col mt-2 bg-gray-100 rounded">
        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start w-1/2 ">
            <div class="m-2 font-bold">{{currentRecord.apply_message}}</div>
          </div>
        </div>
      </div>
      <div v-if="currentRecord.status == 'pending'">
        <div class="felx felx-col mt-10">
          <div class="flex flex-row justify-start">
            <div class="m-2 min-70">
              回覆申請人
            </div>
            <a-input type="text" v-model="replyMsg" placeholder="留言給申請人.."/>
          </div>
        </div>
        <div class="felx felx-col mt-10">
            <div class="flex flex-row justify-center mb-3 mt-3">
              <a-button type="default" class="mr-3 w-28" :disabled="replyMsg == ''" @click="submit('deny')">不通過</a-button>
               <a-button type="primary" class="mr-3 w-28"  :disabled="replyMsg == ''" @click="submit('accept')">
                通過
              </a-button>
            </div>
        </div>
      </div>
      <div v-else>
        <div class="flex justify-between mt-4">
          <span :class="currentRecord.status == 'accept'?'text-green-500':'text-red-500'">{{currentRecord.status== 'accept'?'審核通過':'審核不通過'}}</span>
          <span class="text-gray-500">{{currentRecord.reviewer_profile.nickname}} / {{currentRecord.reviewed_at| moment("YYYY-MM-DD HH:mm:ss")}}</span>
        </div>
        <div class="mt-4 ">{{currentRecord.review_message}}</div>
        <div class="flex justify-center mt-4">
          <a-button type="default" class="w-28" @click="displayModel=false">關閉</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getChangeCoachApplications, createOne } from "../../service/api.js";
import App from "../../App";
import { isError } from "util";
import moment from "moment";

@Component({
  name: "Coaches",
  components: {}
})
export default class Coaches extends App {
  pendingList = [];
  reviewedList = [];
  displayModel = false;
  activeKey = "1";
  userID = "";
  currentStatus = "pending";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  currentRecord = null;
  replyMsg = "";

  async mounted() {
    this.getList();
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  openModal(record) {
    this.displayModel = true;
    this.currentRecord = record;
    this.replyMsg = "";
  }

  async submit(type) {
    await CommonModule.setLoading(true);
    try {
      const params = {
        status: type,
        review_message: this.replyMsg
      };
      await createOne(
        `coaches/change-applications/${this.currentRecord.id}/review`,
        params
      );
      await this.getList(this.currentPage);
      this.displayModel = false;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.reviewedList = [];
      this.pendingList = [];
      this.condition = null;
      const res = await getChangeCoachApplications(
        this.currentStatus,
        this.userID,
        currentPage
      );
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      if (this.currentStatus == "pending") {
        this.pendingList = res.data;
      } else {
        this.reviewedList = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changeTab(key = "1") {
    this.activeKey = key;
    this.currentStatus = key == 1 ? "pending" : "reviewed";
    this.getList();
  }

  gotoUserInfoPage(record) {
    record["visitor"] = false;
    record["editable"] = false;
    record["id"] = record.user_id;
    if (this.userType == "enable") {
      record["editable"] = true;
    }
    this.$router.push({
      name: "UpdateUser",
      params: record
    });
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.min-70 {
  min-width: 70px;
}
</style>
