export class Activity {
  name = "";
  start_at = "";
  end_at = "";
  last_modify_before = "";
  type = "offline";
  organizer = "";
  status = "draft";
  address = "";
  description = "";
  remark = "";
  ticket_type = "multiple";
  cover_image_name = "";
  cover_image_url = "";
  form = "";
  tickets = [];
  level = undefined;
  retail = "-";
  nickname = "-";

  init(res) {
    this.name = res.name;
    this.start_at = res.start_at;
    this.end_at = res.end_at;
    this.last_modify_before = res.last_modify_before;
    this.status = res.status;
    this.type = res.type;
    this.address = res.address;
    this.description = res.description;
    this.cover_image_name = res.cover_image_name;
    this.cover_image_url = res.cover_image_url;
    this.organizer = res.organizer;
    this.remark = res.remark;
    this.ticket_type = res.ticket_type;
    if (res.organizer.cloud_mall_record != null) {
      this.retail = res.organizer.cloud_mall_record.merchant_id ? res.organizer.cloud_mall_record.merchant_id : "-";
      this.level = res.organizer.cloud_mall_record.reseller_level;
    }
    if (res.organizer.profile != null) {
      this.nickname = res.organizer.profile.nickname ? res.organizer.profile.nickname : "-";
    }
  }

  convert() {
    return JSON.parse(JSON.stringify(this));
  }
}