<template>
  <div class="app-table">
    <div
      class="
        app-tr
        rule-tr
        app-bb app-table-header
        flex flex-row
        justify-between
      "
    >
      <div class="app-th app-w-10" v-if="activityType != 'draft'">活動ID</div>
      <div class="app-th">活動名稱</div>
      <div class="app-th" v-if="activityType != 'draft'">舉辦人</div>
      <div class="app-th">活動開始時間</div>
      <div class="app-th" v-if="activityType == 'draft'">更新時間</div>
      <div class="app-th justify-center" v-if="activityType != 'draft'">
        已付款
      </div>
      <div class="app-th justify-center">查看</div>
    </div>
    <div v-if="recordList.length != 0">
      <div
        class="app-tr rule-tr app-bb flex flex-row justify-between"
        v-for="(record, index) in recordList"
        :key="index"
      >
        <div class="app-td app-w-10" v-if="activityType != 'draft'">
          {{ record.id }}
        </div>
        <div class="app-td truncate block" style="line-height: 40px">
          {{ record.name }}
        </div>
        <div class="app-td" v-if="activityType != 'draft'">
          {{
            !record.organizer.profile ? "-" : record.organizer.profile.nickname
          }}
        </div>
        <div class="app-td">
          {{ record.start_at | moment("YYYY/MM/DD HH:mm:ss") }}
        </div>
        <div class="app-td" v-if="activityType == 'draft'">
          {{ record.updated_at | moment("YYYY/MM/DD HH:mm:ss") }}
        </div>
        <div class="app-td justify-center" v-if="activityType != 'draft'">
          {{ getPayNumber(record) }}
        </div>
        <div class="app-td justify-center">
          <a-button type="link" @click="gotoPage(record)"> 查看 </a-button>
        </div>
      </div>
    </div>
    <div>
      <div class="none-data" v-if="recordList.length == 0">目前無任何資訊</div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";

@Component
export default class Activityable extends Vue {
  @Prop() records;
  @Prop() activityType;
  recordList = [];

  @Watch("records")
  updateUsers() {
    this.recordList = this.records;
  }

  getPayNumber(record) {
    if (record.orders != undefined) {
      let total = 0;
      for (let i = 0; i < record.orders.length; i++) {
        const order = record.orders[i];
        if (order.status != undefined && order.status == "registered") {
          total += 1;
        }
      }
      return total;
    } else {
      return 0;
    }
  }

  gotoPage(record) {
    record["activityType"] = this.activityType;
    this.$router.push({
      name: "UpdateActivity",
      params: record,
    });
  }
}
</script>

<style>
</style>
