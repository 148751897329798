export class HealthParams {

  NS_PERSONAL_MEDICAL_HISTORY = "PERSONAL_MEDICAL_HISTORY";
  NS_FAMILY_MEDICAL_HISTORY = "FAMILY_MEDICAL_HISTORY";
  NS_PRESCRIPTION_MEDICINE = "PRESCRIPTION_MEDICINE";

  constructor(res) {
    if (res == undefined) return;
    if (res.plan == undefined) return;
    this.id = res.plan.id;
    this.user_id = res.plan.user_id;
    this.coach_user_id = res.plan.coach_user_id;
    this.status = res.plan.status;
    this.plan = res.plan.plan;
    this.init_weight = res.plan.init_weight;
    this.init_fat = res.plan.init_fat;
    this.init_fat_rate = res.plan.init_fat_rate;
    this.target_weight = res.plan.target_weight;
    this.start_date = res.plan.start_date;
    this.plan_days = res.plan.plan_days;
    this.created_at = res.plan.created_at;
    this.updated_at = res.plan.updated_at;
    this.init_body_daily_record_id = res.plan.init_body_daily_record_id;
    this.absolute_contraindications = res.absolute_contraindications;
    this.relative_contraindications = res.relative_contraindications;
    this.user_selected_contraindications = res.user_selected_contraindications;
    this.personal_medical_history = this.getSurvey(this.NS_PERSONAL_MEDICAL_HISTORY, res);
    this.family_medical_history = this.getSurvey(this.NS_FAMILY_MEDICAL_HISTORY, res);
    this.prescription_medicine = this.getSurvey(this.NS_PRESCRIPTION_MEDICINE, res);
  }

  id = "";
  user_id = "";
  coach_user_id = "";
  status = "";
  plan = "";
  init_weight = "";
  init_fat = "";
  init_fat_rate = "";
  target_weight = "";
  start_date = "";
  plan_days = "";
  created_at = "";
  updated_at = "";
  init_body_daily_record_id = "";
  personal_medical_history = false;
  prescription_medicine = false;
  family_medical_history = false;
  user_selected_contraindications = [];
  absolute_contraindications = [];
  relative_contraindications = [];
  ns_map = { PERSONAL_MEDICAL_HISTORY: "", FAMILY_MEDICAL_HISTORY: "", PRESCRIPTION_MEDICINE: "" }

  getSurvey(type, res) {
    let flag = false;
    for (let i = 0; i < res.survey.length; i++) {
      const s = res.survey[i];
      this.ns_map[s.ns] = s.value;
      if (s.ns == type && s.value != "") {
        flag = true;
      }
      if (flag) {
        break;
      }
    }
    return flag;
  }

  convert() {
    return JSON.parse(JSON.stringify(this));
  }
}