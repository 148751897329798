<template>
<div>
  <div class="app-default-page-back">
  </div>
  <div class="app-default-page-title mt-2 flex justify-between">
    <div>活動管理</div>
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
        <div>活動列表</div>
        <a-button type="link" @click="gotoCreateActivity()">
          + 新增活動
        </a-button>
    </div>
    <div class="h-line"></div>
     <div class="flex flex-row mb-2">
      <div class="flex flex-row justify-start mt-3 mb-3">
        <a-input placeholder="活動名稱" class="mr-3" v-model="currentActivityName" />
        <a-input placeholder="舉辦人" class="mr-3" v-model="currentOrganizer" />
      </div>
      <div class="flex flex-row justify-start mb-3 mt-3">
        <a-button type="primary" class="mr-3 w-28" @click="getList()">
          搜尋
        </a-button>
        <a-button type="link" class="mr-3 w-28" @click="clearSearchCondition()">清空條件</a-button>
      </div>
    </div>
    <div class="app-tab-wrap">
      <div class="app-tab-item" @click="changeTab('1')" :class="activeKey == '1'?'app-tab-item-active':'app-tab-item-default'">已發佈</div>
      <div class="app-tab-item" @click="changeTab('2')" :class="activeKey == '2'?'app-tab-item-active':'app-tab-item-default'">草稿</div>
      <div class="app-tab-item" @click="changeTab('3')" :class="activeKey == '3'?'app-tab-item-active':'app-tab-item-default'">結束下架</div>
    </div>
    <ActivityTable :records.sync="publishing"  activityType='publishing' v-show="activeKey=='1'"/>
    <ActivityTable :records.sync="draft"  activityType='draft' v-show="activeKey=='2'"/>
    <ActivityTable :records.sync="finished"  activityType='finished' v-show="activeKey=='3'"/>
    <div class="pagination-wrap">
      <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getActivities } from "../../service/api.js";
import App from "../../App";
import ActivityTable from "../../components/activityTable";

@Component({
  name: "Activities",
  components: { ActivityTable }
})
export default class Activities extends App {
  activeKey = "1";
  recordList = [];
  draft = [];
  publishing = [];
  finished = [];
  currentActivityName = "";
  currentOrganizer = "";
  currentCategory = "publishing";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;

  async mounted() {
    this.getList();
  }

  changeTab(key = "1") {
    this.activeKey = key;
    if (key == "1") {
      this.currentCategory = "publishing";
    } else if (key == "2") {
      this.currentCategory = "draft";
    } else {
      this.currentCategory = "finished";
    }
    this.getList(this.currentPage);
  }

  clearSearchCondition() {
    this.currentActivityName = "";
    this.currentOrganizer = "";
    this.currentCategory = "publishing";
    this.getList();
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.recordList = [];
      this.scheduled = [];
      this.draft = [];
      this.publishing = [];
      this.finished = [];
      const res = await getActivities(
        this.currentActivityName,
        this.currentOrganizer,
        this.currentCategory,
        currentPage
      );
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      if (this.currentCategory == "draft") {
        this.draft = res.data;
      } else if (this.currentCategory == "publishing") {
        this.publishing = res.data;
      } else if (this.currentCategory == "finished") {
        this.finished = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  async gotoCreateActivity() {
    await CommonModule.setActivityParams(undefined);
    this.$router.push("/activities/update");
  }
}
</script>

<style lang="scss">
.user-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.min-70 {
  min-width: 70px;
}
</style>
