<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title mt-2 flex justify-between">
    <div>W2021061001 已讀紀錄</div>
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
      <div>已讀會員名單</div>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row mb-2">
      <div class="flex flex-row justify-start mt-3 mb-3">
        <a-input placeholder="會員ID" class="mr-3" v-model="searchUserID" />
      </div>
      <div class="flex flex-row justify-start mb-3 mt-3">
        <a-button type="primary" class="mr-3 w-28" @click="searchUser()">
          搜尋
        </a-button>
        <a-button type="link" class="mr-3 w-28" @click="userID = ''">清空條件</a-button>
      </div>
    </div>
    <div class="app-table ">
      <div class="app-tr rule-tr app-bb app-table-header">
        <div class="app-th">會員 ID</div>
        <div class="app-th app-w-60">暱稱</div>
        <div class="app-th justify-center" >點閱時間</div>
      </div>
      <div v-if="recordList.length != 0">
        <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
          <div class="app-td">
            {{record.userID}}
          </div>
          <div class="app-td app-w-60">
            {{record.nickname}}
          </div>
          <div class="app-td">{{record.datetime}}</div>
        </div>
      </div>
      <div>
        <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
      </div>
      <div class="pagination-wrap">
        <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import { getUsers } from "../../service/api.js";
import App from "../../App";

@Component({
  name: "ArticleHistory",
  components: {}
})
export default class ArticleHistory extends App {
  category = "";
  title = "";
  searchUserID = "";
  recordList = [];

  currentPage = 1;
  perPage = 15;
  totalPage = 0;

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    this.currentPage = currentPage;
    // this.users = [];
    this.condition = null;
    // const res = await getUsers(this.currentStoreId, currentPage);
    // this.currentPage = res.current_page;
    // this.perPage = res.per_page;
    // this.totalPage = res.total;
    // this.users = [];
    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    try {
      const queryObj = this.$route.params;
      this.recordList = [
        {
          userID: "user99",
          nickname: "user99",
          datetime: "0000-00-00 00:00:00"
        }
      ];
    } catch (error) {
      console.log(error);
    }
  }

  async searchUser() {
    await CommonModule.setLoading(true);
    try {
      this.title;
      this.category;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }
}
</script>
