<template>
    <a-layout-header style="background: #fff; padding:0 20px ">
      <a-icon
        class="trigger"
        :type="collapsed ? 'menu-unfold' : 'menu-fold'"
        @click="traggleSide()"
      />
      <div>
      <a-button type="link" @click="logout()">
        登出
      </a-button>
    </div>
  </a-layout-header>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AuthenticationModule, CommonModule, Store } from "../store/index.js";

@Component
export default class Header extends Vue {
  userName = "";
  isError = false;
  collapsed = true;
  userId = "";
  async mounted() {}

  async traggleSide() {
    this.collapsed = await CommonModule.getCollapsed();
    CommonModule.setCollapsed(this.collapsed ? false : true);
  }

  logout() {
    AuthenticationModule.removeAuth();
    this.$router.push("/");
  }
}
</script>

<style>
.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
