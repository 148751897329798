<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title mt-2">
    分類設定
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap">
      <div>分類列表</div>
      <a-button type="link" @click="displayModel=true; langEN={};langCN={};langTW={};langJP={}">
        + 新增分類
      </a-button>
    </div>
    <div class="h-line"></div>
    <div class="app-table ">
      <div class="app-tr rule-tr app-bb app-table-header">
        <div class="app-th app-w-70">分類名稱</div>
        <div class="app-th justify-center">刪除</div>
        <div class="app-th justify-center">編輯</div>
      </div>
      <div v-if="recordList.length != 0">
        <div class="app-tr rule-tr app-bb" v-for="(record,index) in recordList" :key="index">
          <div class="app-td app-w-70">
            {{record.name}}
          </div>
          <div class="app-td justify-center">
            <a-button type="link" @click="deleteCategoryDialog=true;currentCategory = record;">
              刪除
            </a-button>
          </div>
          <div class="app-td justify-center">
            <a-button type="link" @click="openCategoryDialog(record)">
              編輯
            </a-button>
          </div>
        </div>
      </div>
      <div>
        <div class="none-data" v-if="recordList.length==0">目前無任何資訊</div>
      </div>
      <div class="pagination-wrap">
        <a-pagination v-model="currentPage" :defaultCurrent="1" :pageSize="perPage" :total="totalPage" @change="changePage" show-less-items />
      </div>
    </div>
  </div>
  <a-modal v-model="displayModel" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
    <div style="padding:10px">
      <h4>新增分類</h4>
      <div class="h-line"></div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">繁體中文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langTW.name" />
      </div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">簡體中文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langCN.name" />
      </div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">英文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langEN.name" />
      </div>
      <div class="flex flex-row justify-start items-center">
        <div class="app-w-20">日文</div>
        <a-input type="text" class="m-3 app-w-70" v-model="langJP.name" />
      </div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="displayModel=false">取消</a-button>
        <a-button class="w-40 ml-4" @click="save();" type="primary">
          儲存
        </a-button>
      </div>
    </div>
  </a-modal>
  <a-modal v-model="deleteCategoryDialog" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
    <h4>確定要刪除此分類？</h4>
    <div class="mt-5">
      刪除後此分類的資料將從系統中移除
    </div>
    <div class="text-center mt-6">
      <a-button class="w-40 ml-4" @click="deleteCategoryDialog=false">取消</a-button>
      <a-button class="w-40 ml-4" @click="deleteCategory();" type="primary">
        刪除
      </a-button>
    </div>
  </a-modal>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../../store/index.js";
import {
  getAll,
  getOne,
  deleteOne,
  createOne,
  updateAll
} from "../../../service/api.js";
import App from "../../../App";

@Component({
  name: "Categories",
  components: {}
})
export default class Categories extends App {
  displayModel = false;
  deleteCategoryDialog = false;
  recordList = [];
  currentCategory = {};
  langTW = {};
  langCN = {};
  langEN = {};
  langJP = {};
  groupID = "";
  currentPage = 1;
  perPage = 15;
  totalPage = 0;
  async mounted() {
    try {
      const queryObj = this.$route.params;
      this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  async getList(currentPage = 1) {
    await CommonModule.setLoading(true);
    try {
      this.currentPage = currentPage;
      this.recordList = [];
      this.condition = null;
      const res = await getAll("article-types", currentPage);
      this.currentPage = res.current_page;
      this.perPage = res.per_page;
      this.totalPage = res.total;
      this.recordList = res.data;
    } catch (error) {
      console.log(error);
    }

    await CommonModule.setLoading(false);
  }

  changePage(current) {
    try {
      this.currentPage = current;
      this.getList(this.currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  async openCategoryDialog(record) {
    await CommonModule.setLoading(true);

    this.currentCategory = record;
    this.displayModel = true;
    this.langTW = {};
    this.langCN = {};
    this.langEN = {};
    this.langJP = {};
    try {
      const res = await getOne(
        "article-types/group_id",
        this.currentCategory.group_id
      );
      for (let i = 0; i < res.article_types.length; i++) {
        const type = res.article_types[i];
        if (type.language == "zh_tw") {
          this.langTW = type;
        } else if (type.language == "zh_cn") {
          this.langCN = type;
        } else if (type.language == "en") {
          this.langEN = type;
        } else if (type.language == "jp") {
          this.langJP = type;
        }
      }

      this.groupID = res.group_id;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async deleteCategory() {
    try {
      await deleteOne("article-types/group_id", this.currentCategory.group_id);
      this.deleteCategoryDialog = false;
      this.getList();
    } catch (error) {
      console.log(error);
    }
  }

  async save() {
    try {
      if (this.currentCategory.group_id != undefined) {
        let params = {
          article_types: [this.langTW, this.langCN, this.langJP, this.langEN]
        };
        await updateAll("article-types", params);
      } else {
        let params = {
          article_types: [
            {
              language: "zh_tw",
              name: this.langTW.name
            },
            {
              language: "zh_cn",
              name: this.langCN.name
            },
            {
              language: "jp",
              name: this.langJP.name
            },
            {
              language: "en",
              name: this.langEN.name
            }
          ]
        };
        await createOne("article-types", params);
      }
      this.displayModel = false;
      this.getList();
    } catch (error) {
      console.log(error);
    }
  }
}
</script>
