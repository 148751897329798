import service from './config.js'

const prefix = 'api';
const version = 'v1';

export const fileUpload = (files) => {
  var formData = new FormData();
  formData.append("images[]", files[0]);
  return service.post(`${prefix}/${version}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const usersConvert = (files) => {
  var formData = new FormData();
  formData.append("file", files[0]);
  return service.post(`${prefix}/admin/users-convert`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const changeGroups = (activityId, files) => {
  var formData = new FormData();
  formData.append("file", files[0]);
  return service.post(`${prefix}/admin/activities/${activityId}/change-groups`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const login = (username, password) => {
  const params = {
    username: username,
    password: password,
  }
  return service.post(`${prefix}/admin/auth/login`, params);
}

export const getCountryCode = () => {
  return service.get(`${prefix}/${version}/country-codes?language=zh_tw`);
}

export const getSurvey = () => {
  return service.get(`${prefix}/${version}/survey?language=zh_tw`);
}

export const getActivityOrder = (orderId, activityId, ticketId, userId, nickname, mobile, group, status, currentPage = 1) => {
  let url = `${prefix}/admin/activity-orders?page=${currentPage}`;
  if (orderId != "") {
    url += "&id=" + orderId;
  }
  if (activityId != "") {
    url += "&activity_id=" + activityId;
  }
  if (ticketId != "") {
    url += "&ticket_id=" + ticketId;
  }
  if (userId != "") {
    url += "&user_id=" + userId;
  }
  if (nickname != "") {
    url += "&nickname=" + nickname;
  }
  if (mobile != "") {
    url += `&mobile=${mobile}`
  }
  if (group != "") {
    url += `&group=${group}`
  }
  if (status != "") {
    url += `&status=${status}`
  }
  return service.get(url);
}

export const getUserDetail = (id) => {
  return service.get(`${prefix}/admin/users?id=${id}`);
}

export const getUsers = (type, userID, countryCode, mobile, nickname, coachUserID, currentPage = 1) => {
  let target = "";
  if (type == 'active') {
    target = 'users'
  } else if (type == 'inactive') {
    target = 'users/deleted'
  } else {
    target = 'guests'
  }
  let url = `${prefix}/admin/${target}?page=${currentPage}`;
  if (userID != "") {
    url += "&id=" + userID;
  }
  if (countryCode != undefined) {
    url += "&country_code=" + countryCode;
  }
  if (mobile != "") {
    url += "&mobile=" + mobile;
  }
  if (nickname != "") {
    url += "&nickname=" + nickname;
  }
  if (coachUserID != "") {
    url += "&coach_user_id=" + coachUserID;
  }
  return service.get(url);
}

export const getAdministrators = (currentPage = 1) => {
  return service.get(`${prefix}/admin/admins?page=${currentPage}`);
}

export const searchArticles = (status, typeID, title, currentPage = 1) => {
  let url = `${prefix}/admin/articles?page=${currentPage}`;
  if (status != null) {
    url += `&status=${status}`
  }
  if (typeID != null) {
    url += `&type_id=${typeID}`
  }
  if (title != null) {
    url += `&title=${title}`
  }
  return service.get(url);
}

export const getFeedback = (status, userId = "", nickname = "", currentPage = 1) => {
  let url = `${prefix}/admin/feedback?status=${status}&page=${currentPage}`;
  if (userId != "") {
    url += `&user_id=${userId}`
  }
  if (nickname != "") {
    url += `&nickname=${nickname}`
  }
  return service.get(url);
}

export const getChangeCoachApplications = (status, userID = "", currentPage = 1) => {
  let url = `${prefix}/admin/coaches/change-applications?page=${currentPage}`;
  if (status != "") {
    url += `&status=${status}`
  }
  if (userID != "") {
    url += `&user_id=${userID}`
  }
  return service.get(url);
}

export const getArticles = (status, typeId, title, currentPage = 1) => {
  let url = `${prefix}/admin/articles?page=${currentPage}`;
  if (status != "") {
    url += `&status=${status}`
  }
  if (typeId != undefined) {
    url += `&type_id=${typeId}`
  }
  if (title != "") {
    url += `&title=${title}`
  }
  return service.get(url);
}

export const getNotifications = (type, title, status, currentPage = 1) => {
  let url = `${prefix}/admin/notification-schedules?page=${currentPage}`;
  if (type != undefined) {
    url += `&notification_type=${type}`
  }
  if (title != "") {
    url += `&title=${title}`
  }
  if (status != "") {
    url += `&status=${status}`
  }
  return service.get(url);
}

export const getActivities = (name, nickname, status, currentPage = 1) => {
  let url = `${prefix}/admin/activities?page=${currentPage}`;
  if (name != "") {
    url += `&name=${name}`
  }
  if (nickname != "") {
    url += `&nickname=${nickname}`
  }
  if (status != "") {
    url += `&status=${status}`
  }
  return service.get(url);
}

export const getActivitiesAttendees = (activityId, date, checkinStatus, userId, nickname, mobile, group, currentPage = 1) => {
  let url = `${prefix}/admin/activities/${activityId}/attendees?page=${currentPage}`;
  if (date != "") {
    url += `&date=${date}`
  }
  if (checkinStatus != undefined) {
    url += `&checkin_status=${checkinStatus}`
  }
  if (userId != "") {
    url += `&user_id=${userId}`
  }
  if (nickname != "") {
    url += `&nickname=${nickname}`
  }
  if (mobile != "") {
    url += `&mobile=${mobile}`
  }
  if (group != "") {
    url += `&group=${group}`
  }
  return service.get(url);
}

export const getCountsOfActivityAttendees = (activityId) => {
  let url = `${prefix}/admin/activities/${activityId}/attendees-counts`;
  return service.get(url);
}

export const downloadActivityCheckins = (activityId, date) => {
  var formData = new FormData();
  let url = `${prefix}/admin/activities/${activityId}/export-checkins?date=${date}`;
  return service.get(url, {
    'responseType': 'blob', headers: { 'Content-Type': 'application/octet-stream' }
  });
}

export const getAllByDate = (type, startDate, endDate, currentPage = 1) => {
  let url = `${prefix}/admin/${type}?page=${currentPage}`;
  if (startDate != null) {
    url += `&from_date=${startDate}`
  }
  if (endDate != null) {
    url += `&to_date=${endDate}`
  }
  return service.get(url);
}

export const getAllContraindications = (type, currentPage = 1) => {
  let url = `${prefix}/admin/contraindications?page=${currentPage}`;
  if (type != "") {
    url += `&type=${type}`
  }
  return service.get(url);
}

export const getSinglePage = (type) => {
  return service.get(`${prefix}/admin/single-page?type=${type}`);
}

export const recoverUser = (userID) => {
  return service.post(`${prefix}/admin/users/${userID}/recover`);
}

export const getBodyLevel = (key, level) => {
  return service.get(`${prefix}/admin/body-index-level-details?key=${key}&level=${level}`);
}

export const getBodyIndex = (key) => {
  return service.get(`${prefix}/admin/body-index-details?key=${key}`);
}

export const getAll = (type, currentPage = 1) => {
  return service.get(`${prefix}/admin/${type}?page=${currentPage}`);
}

export const getOne = (type, id = "") => {
  return service.get(`${prefix}/admin/${type}/${id}`);
}

export const createOne = (type, params) => {
  return service.post(`${prefix}/admin/${type}/`, params);
}

export const updateOne = (type, id, params) => {
  return service.put(`${prefix}/admin/${type}/${id}`, params);
}

export const updateAll = (type, params) => {
  return service.put(`${prefix}/admin/${type}/`, params);
}

export const deleteOne = (type, id) => {
  return service.delete(`${prefix}/admin/${type}/${id}`);
}

export const exportData = (type, id) => {
  let url = `${prefix}/admin/${type}/${id}/export`;
  return service.get(url, {
    'responseType': 'blob', headers: { 'Content-Type': 'application/octet-stream' }
  });
}