<template>
<div>
  <div style="position: relative;" :class="editing?'':'hide-ql-bar'">
    <div :class="editing?'':'cover'">&nbsp;</div>
    <quill-editor ref="myQuillEditor" v-model="editorContent" :options="editorOption" @change="onEditorChange($event)" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)" :disabled="!editing"/>
    <input ref="referenceUpload" type="file" style="display: none;" id="getFile" @change="updateImage" @click="clearVar" accept="image/gif,image/jpeg,image/jpg,image/png" />
  </div>
</div>
</template>

<script>
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { fileUpload } from "../service/api";
import { Config } from "../service/config.js";

@Component
export default class Editor extends Vue {
  @Prop() content;
  @Prop() actionType;
  @Prop() editing;
  editorContent = "";
  editorOption = {
    modules: {
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction

          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ["link", "image"]
        ],
        handlers: {
          image: function() {
            document.getElementById("getFile").click();
          }
        }
      }
    }
  };
  @Watch("content")
  updateContent() {
    this.editorContent = this.content;
  }

  constructor() {
    super();
  }

  onEditorBlur(quill) {
    // console.log("editor blur!", quill);
  }

  onEditorFocus(quill) {
    // console.log("editor focus!", quill);
  }

  onEditorReady(quill) {
    // console.log("editor ready!", quill);
  }

  onEditorChange(event) {
    // const quill = event.quill;
    const html = event.html;
    // const text = event.text;
    this.editorContent = html;
    const params = {
      editorContent: this.editorContent,
      actionType: this.actionType
    };
    this.$emit("paramsChanges", params);
  }

  clearVar(event) {
    const el = this.$refs.referenceUpload;
    el.value = null;
  }

  async updateImage(event) {
    try {
      let res = await fileUpload(event.target.files);
      const coverImageUrl = res.images[0].url;
      const coverImageName = res.images[0].name;
      this.editorContent += `<img src="${coverImageUrl}" alt="${coverImageName}">`;
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style>
.publish-label {
  margin: 0 10px 0 0;
  font-size: 20px;
}

.quill-editor {
  width: 100%;
}

.ql-container {
  min-height: 300px;
}
.cover {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.01);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  right: 0;
}
.hide-ql-bar .quill-editor {
  border: 1px solid #ddd;
}
.hide-ql-bar .quill-editor .ql-toolbar {
  display: none !important;
}
</style>
