<template>
<div>
  <div class="app-default-page-back" @click="$router.go(-1)">
    <a-icon type="arrow-left" class="mr-3" />返回
  </div>
  <div class="app-default-page-title flex flex-row justify-between items-center">
    {{feedbackId}}
    <a-button v-if="feedbackStatus == 'new'" @click="displayModel=true">已解決</a-button>
    <a-button v-else @click="displayModel=true">再次回覆</a-button>
  </div>
  <div class="app-default-wrap">
    <div class="user-title-wrap" >
      <span>
        <span>會員 ID:</span>
        <a-button type="link" @click="openPage()"> {{userId}}</a-button>
        </span>
    </div>
    <div class="h-line"></div>
    <div class="flex flex-row justify-between mt-10 mb-4">
      <a-input type="text" v-model="replyMsg" placeholder="請輸入..." class="app-w-90 mr-2" :disabled="feedbackStatus == 'closed'"/>
      <a-button type="primary" @click="reply()"  class="app-w-10" :disabled="replyMsg==''||ffeedbackStatus == 'closed'">回覆</a-button>
    </div>
    <div class="flex flex-col" v-for="(message, index) in messages" v-bind:key="index">
      <div class="flex flex-row justify-between items-center text-gary-500 mt-4">
        <div class="flex flex-row justify-start items-center p-2">
          <img v-if="message.user_profile != null" class="rounded-full h-14 w-14 flex items-center justify-center" :src="message.user_profile.avatar" alt="">
          <span class="ml-4">{{message.user_profile != null?message.user_profile.nickname:""}}</span>
        </div>
        <span class="mr-2">{{message.created_at | moment("YYYY-MM-DD HH:mm:ss")}}</span>
      </div>
      <div class="mt-4 p-2 border-2 border-gray-200 break-all"  :class="index%2==0?'bg-gray-100':''">
        <img :src="message.message" v-if="getContext(message.message)"/>
        <div v-else>{{message.message}}</div>
      </div>
    </div>
  </div>
  <a-modal v-model="displayModel" class="costom-model" :centered="true" :cancelButtonProps="{ style: { display: 'none' } }" :footer="null" :destroyOnClose="true" :maskClosable="false">
      <h4>{{feedbackStatus == 'new'?"確定將此意見列為已解決？":"確定將此意見列為未解決？"}}</h4>
      <div class="mt-5">
        {{feedbackStatus == 'new'?"列為已解決意見後將無法繼續回覆":"列為未解決意見後將能夠繼續回覆"}}
      </div>
      <div class="text-center mt-6">
        <a-button class="w-40 ml-4" @click="displayModel=false">取消</a-button>
        <a-button class="w-40 ml-4" @click="changeStatus();" type="primary">
          確定
        </a-button>
      </div>
  </a-modal>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AuthenticationModule,
  CommonModule,
  Store
} from "../../store/index.js";
import {
  getAll,
  getOne,
  updateOne,
  deleteOne,
  createOne
} from "../../service/api.js";
import App from "../../App";
import moment from "moment";

@Component({
  name: "UpdateFeedback",
  components: {}
})
export default class UpdateFeedback extends App {
  toggleDisable = true;
  displayModel = false;
  feedbackId = 0;
  feedbackStatus = 0;
  messages = [];
  userId = 0;
  errorMsg = null;
  replyMsg = "";

  async mounted() {
    await CommonModule.setLoading(true);
    try {
      const queryObj = this.$route.params;
      if (Object.keys(queryObj).length != 0) {
        this.feedbackId = queryObj.id;
        this.feedbackStatus = queryObj.status;
        this.userId = queryObj.user_id;
        this.getMessages();
      }
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async getMessages() {
    await CommonModule.setLoading(true);
    try {
      this.messages = [];
      const queryObj = this.$route.params;
      const res = await getAll(`feedback/${this.feedbackId}/messages/`);
      this.messages = res.messages;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  async reply() {
    await CommonModule.setLoading(true);
    try {
      const params = {
        message_type: "text",
        message: this.replyMsg
      };
      const res = await createOne(
        `feedback/${this.feedbackId}/messages`,
        params
      );
      this.getMessages();
      this.replyMsg = "";
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  getContext(message) {
    if (message.indexOf("http") != -1) {
      return true;
    } else false;
  }

  async changeStatus() {
    await CommonModule.setLoading(true);
    try {
      if (this.feedbackStatus == "closed") {
        this.feedbackStatus = "new";
      } else {
        this.feedbackStatus = "closed";
      }
      const params = {
        status: this.feedbackStatus
      };
      await updateOne(`feedback`, this.feedbackId, params);
      this.displayModel = false;
    } catch (error) {
      console.log(error);
    }
    await CommonModule.setLoading(false);
  }

  openPage() {
    localStorage.setItem("userId", this.userId);
    this.$router.open("/user/update");
  }
}
</script>

<style lang="scss">
</style>
